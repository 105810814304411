import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Services } from "../../../Services";
import S3FileUpload from "react-s3";
import { FileUploader } from "react-drag-drop-files";
import config from "../supportconfig";
import HomeClasses from "../../Home/home.module.css";
import { GoArrowLeft } from "react-icons/go";
import { MdDescription } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { LuUpload } from "react-icons/lu";
import {
  ToastError,
  ToastSuccess,
} from "../../../components/utils/ToastMessages";
import Moment from "react-moment";
import Home from "../home";

window.Buffer = window.Buffer || require("buffer").Buffer;

const Support = () => {
  const [imageUrl, setImageUrl] = useState([]);
  const [step, setStep] = useState(1);
  const [images, setImages] = useState([]);
  // const [, setUploadedImages] = useState([]);
  const [ticketStatus, setTicketStatus] = useState(
    JSON.parse(localStorage.getItem("ticketStatus")) || 1
  );
  const [imgs, setImgs] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticketdetails, setTicketdetails] = useState({});
  const Navigate = useNavigate();
  const [id, setId] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    getTickets(ticketStatus);
  }, [ticketStatus]);

  const handleTicketStatus = (ticketStatus) => {
    getTickets(ticketStatus);
    setTicketStatus(ticketStatus);
    localStorage.setItem("ticketStatus", ticketStatus);
  };

  const getTickets = async (ticketStatus) => {
    try {
      const res = await Services.Tickets("GET", null, token, ticketStatus);
      if (res.Status === 1) {
        setTickets(res.tickets);
        // setId(res.tickets[0].supportId);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };

  const isActiveButton = (buttonStatus) => {
    return ticketStatus === buttonStatus ? HomeClasses.active : "";
  };

  const ticketDetails = async (id) => {
    setId(id);
    setStep(3);
    try {
      const res = await Services.getTicket("GET", null, token, id);
      if (res.Status === 1) {
        setTicketdetails(res.ticket);
      }
    } catch (err) {
      // alert("Somthing went wrong please try again");
      console.error(err);
    }
  };

  const createNewTicket = async (data) => {
    console.log(data);

    if (imageUrl.length === 0) {
      ToastError("Image is required");
      return; // Don't proceed with form submission
    }

    Services.CreateTickect(
      "POST",
      JSON.stringify({ ...data, images: imageUrl }),
      token
    ).then((res) => {
      if (res.Status === 1) {
        reset({
          title: "",
          description: "",
        });
        setImageUrl([]);
        ToastSuccess(res.Message);
        setImages([]);
        getTickets(ticketStatus);

        setStep(1);
      }
    }).catch((err) => {
      // alert("Something went wrong please try again");
      console.error(err);
    })
  };

  const fileUploadHandler = (files) => {
    let uploadedImages = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const type = file.type;
      // return;

      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
        console.log(newFileName);
        // return;
        const newFile = new File([file], newFileName);
        console.log(file);
        S3FileUpload.uploadFile(newFile, config)
          .then((data) => {
            console.log(data.location);
            uploadedImages.push(data.location);
            setImages([...images, data.location]);
            setImageUrl([...imageUrl, newFileName]);
            // setNewImageFiles()
          })
          .catch((err) => console.error(err));
      }
    }
  };
  const fileUploader = (
    <div className={HomeClasses["add_image_uploader"]}>
      <LuUpload className={HomeClasses["upload_icon"]} />
      <h3 id={HomeClasses["add_uploader_h3"]}>Add Image Here</h3>
    </div>
  );

  return (
    <div className={HomeClasses["Support_container"]}>
      {step === 1 ? (
        <>
          <div className={HomeClasses["Support_statuses"]}>
            <button
              className={`${HomeClasses["Status_all"]} ${isActiveButton(1)}`}
              onClick={() => handleTicketStatus(1)}
              type="button"
            >
              All
            </button>
            <button
              className={`${HomeClasses["Status_all"]} ${isActiveButton(2)}`}
              onClick={() => handleTicketStatus(2)}
              type="button"
            >
              Pending
            </button>
            <button
              className={`${HomeClasses["Status_all"]} ${isActiveButton(3)}`}
              onClick={() => handleTicketStatus(3)}
              type="button"
            >
              Resolved
            </button>
          </div>
          <div className={HomeClasses["tickects_container"]}>
            {tickets.length === 0 ? (<div className={HomeClasses["Nodata"]}><p id={HomeClasses["Nodata2"]}>No Tickets  avialable  at This  Time</p></div>) : (
              tickets.map((ticket, index) => {
                return (
                  <div
                    className={HomeClasses["tickect_box"]}
                    key={index}
                    onClick={() => ticketDetails(ticket.supportId)}
                  >
                    <div className={HomeClasses["tickets"]}>
                      <p className={HomeClasses["ticket_header"]}>
                        <p className={HomeClasses["ticket_header_title"]}>
                          {ticket.title}
                        </p>
                        <p className={HomeClasses["tickect_desc"]}>
                          {ticket.description}
                        </p>
                      </p>
                      <span className={HomeClasses["tickect_timezone"]}>
                        <Moment
                          format="DD MMM, YYYY, hh:mm A"
                          subtract={{ hours: 5, minutes: 30 }}
                        >
                          {new Date(ticket.createdAt)}
                        </Moment>
                      </span>
                    </div>
                    <div className={HomeClasses["tickect_status"]}>
                      <p className={HomeClasses["ticket_status_show"]}>
                        {ticket.resolved === 1 ? (
                          <p className={HomeClasses["Resolved"]}>Resolved</p>
                        ) : (
                          <p className={HomeClasses["Open"]}>Open</p>
                        )}
                      </p>
                    </div>
                  </div>
                );
              }))}
            <div className={HomeClasses["create_ticket"]}>
              <button
                className={HomeClasses["create_new_ticket"]}
                onClick={() => setStep(2)}
              >
                Raise Ticket
              </button>
            </div>
          </div>
        </>
      ) : step === 2 ? (
        <div className={HomeClasses["Ticket_form_container"]}>
          <div className={HomeClasses["Add_header"]}>
            <button
              className={HomeClasses["add_back_btn"]}
              type="button"
              onClick={() => {
                setStep(1);
              }}
            >
              <GoArrowLeft />
            </button>
            <h3 id={HomeClasses["add_header_h2"]}>Create Ticket</h3>
          </div>
          <form
            onSubmit={handleSubmit(createNewTicket)}
            className={HomeClasses["edit_profile"]}
          >
            <div>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: "Title is required",
                  pattern: {
                    value: /^[a-zA-Z0-9\s]+$/,
                    message: "Special charectors not allowed",
                  },
                }}
                render={({ field }) => (
                  <div className={HomeClasses["add_icon"]}>
                    <input {...field} placeholder="Title" />
                    <MdDescription />
                  </div>
                )}
              />
              {errors.title && (
                <p className={HomeClasses["errors"]}>{errors.title.message}</p>
              )}
            </div>
            <div>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: "Description is required",
                  pattern: {
                    value: /^[a-zA-Z0-9\s.,()]+$/,
                    message: "Special charectors not allowed",
                  },
                }}
                render={({ field }) => (
                  <div className={HomeClasses["add_icon"]}>
                    <input {...field} placeholder="description" />
                    <MdDescription />
                  </div>
                )}
              />
              {errors.description && (
                <p className={HomeClasses["errors"]}>
                  {errors.description.message}
                </p>
              )}
            </div>

            <div>
              <Controller
                name="images"
                control={control}
                // rules={{ required: "Image is required" }}
                render={({ field }) => (
                  <div className={HomeClasses["image_preview"]}>
                    <FileUploader
                      handleChange={fileUploadHandler}
                      children={fileUploader}
                      multiple={true}
                      className={HomeClasses["add_image_uploader"]}
                    />
                    <div className={HomeClasses.image_array}>
                      {images.map((item, index) => (
                        <img
                          key={index}
                          src={item}
                          alt="Product Preview"
                          className={HomeClasses["image_uploader"]}
                        />
                      ))}
                    </div>
                  </div>
                )}
              />
              {errors.images && (
                <p className={HomeClasses["errors"]}>{errors.images.message}</p>
              )}
            </div>

            <button type="submit" className={HomeClasses["add_submit"]}>
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className={HomeClasses["Add_header"]}>
            <button
              className={HomeClasses["add_back_btn"]}
              type="button"
              onClick={() => {
                setStep(1);
              }}
            >
              <GoArrowLeft />
            </button>
            <h3 id={HomeClasses["add_header_h2"]}>Request Details</h3>
          </div>

          <div className={HomeClasses["g_ticket_container"]}>
            <p className={HomeClasses["g_ticket_status"]}>
              <p className={HomeClasses["g_Ticket_header"]}>
                <p className={HomeClasses["g_ticket_header_text"]}>
                  {
                    ticketdetails.companyName
                  }
                </p>
                <p className={HomeClasses["g_ticket_time"]}>
                  <Moment
                    format="DD MMM, YYYY, hh:mm A"
                    subtract={{ hours: 5, minutes: 30 }}
                  >
                    {new Date(ticketdetails.createdAt)}
                  </Moment>
                </p>
              </p>
              <p className={HomeClasses["g_Ticket_s"]}>
                {ticketdetails.resolved === 1 ? (
                  <p className={HomeClasses["Resolved"]}>Resolved</p>
                ) : (
                  <p className={HomeClasses["Open"]}>Open</p>
                )}
              </p>
            </p>
            <div className={HomeClasses["g_ticket_desc"]}>
              <p className={HomeClasses["g_desc_header"]}>{ticketdetails.title}</p>
              <p className={HomeClasses["g_dec_text"]}>
                {ticketdetails.description}
              </p>
            </div>
            <div className={HomeClasses["g_ticket_img_container"]}>
              <p className={HomeClasses["g_images_header"]}>Images</p>
              <p className={HomeClasses["g_img_container"]}>
                {ticketdetails.images &&
                  ticketdetails.images.map((image, index) => (
                    <img
                      src={image}
                      key={index}
                      alt="image"
                      className={HomeClasses["g_image"]}
                    />
                  ))}
              </p>
            </div>
            <div className={HomeClasses["g_report_details"]}>
              <p className={HomeClasses["g_report_text_header"]}></p>
              <p className={HomeClasses["g_t_user_d"]}>
                <p className={HomeClasses["g_t_c_name"]}>
                  Report Details
                </p>
              </p>
              <p className={HomeClasses["g_t_update_time"]}>
                {ticketdetails.resolved === 1 && (
                  <>
                    <p className={HomeClasses["g_t_r_header"]}>Resolved on</p>
                    <p className={HomeClasses["g_t_resoved_time"]}>
                      <Moment
                        format="DD MMM, YYYY, hh:mm A"
                        subtract={{ hours: 5, minutes: 30 }}
                      >
                        {new Date(ticketdetails.updatedAt)}
                      </Moment>
                    </p>
                    <p className={HomeClasses["message-remark"]}>
                      {ticketdetails.remark}
                    </p>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Support;
