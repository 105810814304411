import React, { useState } from 'react'
import Styles from './faq.module.css';
import { useForm, Controller } from 'react-hook-form';
import { Services } from '../../../Services';
import { ToastSuccess } from '../../../components/utils/ToastMessages';
import { GrView } from 'react-icons/gr';
import { BiHide } from 'react-icons/bi';
import { RiLockPasswordFill } from 'react-icons/ri';
const PosswordReset = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [hidePassword, setHidePassword] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        reset
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const ResetPassword = async (data) => {
        try {
            const res = await Services.ResetPSW("POST", JSON.stringify(data), token);
            if (res.Status === 1) {
                ToastSuccess(res.Message);
                reset({
                    newPassword: "",
                    currentPassword: "",
                    confirmPassword: ""
                });
            }
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <div className={Styles['Password_container']}>
            <div className={Styles["Add_header"]}>
                <h1 id={Styles["add_header_h2"]}>Change Password</h1>
            </div>
            <div className={Styles['form_container']}>
                <form
                    onSubmit={handleSubmit(ResetPassword)}
                    className={Styles["add_register"]}
                >
                    <div className={Styles["password-input"]}>
                        <label>Current Password </label>
                        <Controller
                            name="currentPassword"
                            control={control}
                            rules={{
                                required: "Current Password is required",
                            }}
                            render={({ field }) => (
                                <div className={Styles["add_icon"]}>
                                    <input
                                        {...field}
                                        type="text"
                                        placeholder="currentPassword"
                                        minLength={8}
                                        maxLength={15}
                                    />
                                    <RiLockPasswordFill />
                                </div>
                            )}
                        />
                        {errors.currentPassword && <p>{errors.currentPassword.message}</p>}
                    </div>
                    <div className={Styles["password-input"]}>
                        <label>New Password </label>
                        <Controller
                            name="newPassword"
                            control={control}
                            rules={{
                                required: "New Password is required",
                            }}
                            render={({ field }) => (
                                <div className={Styles["add_icon"]}>
                                    <input
                                        {...field}
                                        type={showPassword ? "text" : "password"}
                                        placeholder="New password"
                                        minLength={8}
                                        maxLength={15}
                                    />
                                    <RiLockPasswordFill />
                                    <p className={Styles['view_psw']}>
                                        {showPassword ? (
                                            <GrView onClick={() => setShowPassword(false)} />
                                        ) : (
                                            <BiHide onClick={() => setShowPassword(true)} />
                                        )}
                                    </p>
                                </div>
                            )}
                        />
                        {errors.newPassword && <p>{errors.newPassword.message}</p>}
                    </div>

                    <div className={Styles["password-input"]}>
                        <label>Confirm Password </label>
                        <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{
                                required: "Confirm Password is required",
                                validate: (value) =>
                                    value === watch("newPassword") ||
                                    "Passwords do not match",
                            }}
                            render={({ field }) => (
                                <div className={Styles["add_icon"]}>
                                    <input
                                        {...field}
                                        type={hidePassword ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        minLength={8}
                                        maxLength={15}
                                    />
                                    <RiLockPasswordFill />
                                    <p className={Styles['view_psw']}>
                                        {hidePassword ? (
                                            <GrView onClick={() => setHidePassword(false)} />
                                        ) : (
                                            <BiHide onClick={() => setHidePassword(true)} />
                                        )}</p>
                                </div>
                            )}
                        />
                        {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
                    </div>
                    <div className={Styles["moq_btn"]}>
                        <button type="submit" className={Styles["add_submit"]}>
                            Change Password
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PosswordReset