import React from "react";
import Navbar from "../../components/LandingPage/Navbar";
import Footer from "../../components/LandingPage/Footer";

function Services() {
  return (
    <div>
      <Navbar />
      <center>
        <h1>Services</h1>
      </center>
      <Footer />
    </div>
  );
}

export default Services;
