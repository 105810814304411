import React from "react";
import Navbar from "../../components/LandingPage/Navbar";
import Footer from "../../components/LandingPage/Footer";
import Content_5  from '../../components/LandingPage/Content_5'
function Contact() {
  return (
    <div>
      <Navbar />
      <center>
        <Content_5/>
      </center>
      <Footer />
    </div>
  );
}

export default Contact;
