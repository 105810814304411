import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeClasses from "../../pages/Home/home.module.css";
import { override } from "./helper";
import emptygroups from "../../assets/emptygroups.png";
import emptyproducts from "../../assets/emptyproducts.png";
import { RotatingLines } from "react-loader-spinner";
import { AiFillEdit } from "react-icons/ai";
import { useForm } from "react-hook-form";
import config from "./Banner/config";
import S3FileUpload from "react-s3";
import Image from '../../assets/noplot.png'
window.Buffer = window.Buffer || require("buffer").Buffer;

const Home = () => {
  const [dashboardImages, setDashboardImages] = useState([]);
  const [allGroupsProducts, setAllGroupsProducts] = useState([]);
  const [groupID, setGroupID] = useState();
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [bussinessName, setBussinessName] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [isNew, setIsNew] = useState();
  const [cancelSubscription, setCancelSubscription] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");


  useEffect(() => {
    if (accountType == 1 || accountType == 2) {
      fetchSubscriptionStatus()
    }
  }, [accountType, token]);

  const fetchSubscriptionStatus = async () => {
    Services.SubscriptionStatus("GET", null, token).then((res) => {
      if (res.Status === 1) {
        setIsNew(res.isNew);
        setPaymentStatus(res?.paymentStatus);
        setIsSubscribed(res?.isSubscribed);
        setCancelSubscription(res?.cancelSubscription);
        localStorage.setItem("paymentStatus", res?.paymentStatus);
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const FreeTrailActivate = () => {
    Services.SubcriptionPlan("POST", null, token)
      .then((res) => {
        if (res.Status === 1) {
          ToastSuccess(res.Message);
          fetchSubscriptionStatus();
        }
      }).catch((err) => {
        console.log(err);
        ToastError(err.Message);
      })
  }

  useEffect(() => {
    getcategories();
    if (accountType == 6) {
      getPermissions();
    }
  }, []);

  const getPermissions = async () => {
    try {
      const res = await Services.OfficePermissions("GET", null, token);
      if (res.Status === 1) {
        setPermissions(res.permissions);
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
        localStorage.setItem("permissionAccount", res?.accountType);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getcategories = async () => {
    try {
      const res = await Services.getAllGroupsProducts("GET", null, token);
      setLoading(false);
      if (res.Status === 1) {
        setAllGroupsProducts(res.groupProducts);
        setDashboardImages(res.dashboardImages);
        setBussinessName(res.businessName);
        localStorage.setItem("officeBName", res.businessName);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editBanner = async (data) => {
    if (dashboardImages.imagesId) {
      if (!data.dashboardImg) {
        alert("Please select Image");
        return;
      }
      try {
        const res = await Services.BannerEdit(
          "PATCH",
          JSON.stringify(data),
          token,
          dashboardImages.imagesId
        );
        if (res.Status === 1) {
          ToastSuccess(res.Message);
          setShowModal(false);
          getcategories();
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      if (!data.dashboardImg) {
        alert("Please select Image");
        return;
      }
      try {
        const res = await Services.AddBanner(
          "POST",
          JSON.stringify(data),
          token
        );
        if (res.Status === 1) {
          ToastSuccess(res.Message);
          setShowModal(false);
          getcategories();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const fileUploadHandler = (event) => {
    const file = event.target.files[0];
    // return;
    const type = file.type;

    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
      console.log(newFileName);
      // return;
      const Modifiedfile = new File([file], newFileName);
      console.log(file);
      S3FileUpload.uploadFile(Modifiedfile, config)
        .then((data) => {
          setImageUrl(data.location);
          console.log(data);

          setValue("dashboardImg", newFileName);
        })
        .catch((err) => console.error(err));
    }
  };

  const viewAllpage = (groupID) => {
    Navigate(`/viewall/${groupID}`);
  };

  const getProduct = (grouId, productId) => {
    Navigate(`/groupproductdetails/${grouId}/${productId}`);
  };

  return (
    <Layout Active={"Home"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["home_container"]}>
            {
              (isNew == 1 && (accountType == 1 || accountType == 2)) && (
                <div className={HomeClasses["subscription_container"]}>
                  <p id={HomeClasses["content_text"]}>Activate Free Trail to Access The All  Features</p>
                  <button className={HomeClasses["subs_btn"]} onClick={() => FreeTrailActivate()}>
                    Activate Now
                  </button>
                </div>
              )
            }
            {
              (accountType == 1 || accountType == 2) && (
                <>
                  {cancelSubscription === 1 ? (
                    paymentStatus === 0 ? (
                      <div className={HomeClasses["subscription_container"]}>
                        <p id={HomeClasses["content_text"]}>Subscription cancelled </p>
                        <p id={HomeClasses["subs-time"]}>Please repay the outstanding bill</p>
                        <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/paynow")}>
                          Pay Now
                        </button>
                      </div>
                    ) : (
                      // sbscriptionStatusdata.isSubscribed === 0 && <button onClick={() => Navigate("/subscription")}>Subscribe Now</button>
                      ""
                    )
                  ) : cancelSubscription === 0 ? (
                    paymentStatus === 1 ? (
                      // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                      ""
                    ) : (
                      <div className={HomeClasses["subscription_container"]}>
                        {/* <p id={HomeClasses["content_text"]}>Subscription cancelled </p> */}
                        <p id={HomeClasses["subs-time"]}>Please repay the outstanding bill</p>
                        <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/paynow")}>
                          Pay Now
                        </button>
                      </div>
                    )
                  ) : cancelSubscription === 2 ? (
                    isSubscribed === 1 ? (
                      // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                      ""
                    ) : (
                      <div className={HomeClasses["subscription_container"]}>
                        <p id={HomeClasses["content_text"]}>Subscribe A Plan To Access All The Features</p>
                        <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/subscription")}>
                          Subscribe
                        </button>
                      </div>
                    )
                  )
                    : cancelSubscription === 3 ? (
                      isSubscribed === 1 ? (
                        // <button onClick={() => Navigate("/Manage")}>Manage Subscription</button>
                        ""
                      ) : (
                        <div className={HomeClasses["subscription_container"]}>
                          <p id={HomeClasses["content_text"]}>Subscribe A Plan To Access All The Features</p>
                          <button className={HomeClasses["subs_btn"]} onClick={() => Navigate("/subscription")}>
                            Subscribe
                          </button>
                        </div>
                      )
                    ) : null}
                </>
              )
            }
            <div className={HomeClasses["home_products_container"]}>
              {allGroupsProducts.length === 0 ? (
                <div className={HomeClasses["empty_products"]}>
                  <div className={HomeClasses["empty_image_container"]}>
                    <img
                      className={HomeClasses["empty_image"]}
                      src={emptygroups}
                      alt="image"
                    />
                  </div>
                  <p className={HomeClasses["empty_portal_1"]}>
                    No groups to show, Create your first Group and add products
                    to define Price's
                  </p>
                </div>
              ) : (
                allGroupsProducts.map((productGroup, index) => {
                  return (
                    <div
                      className={HomeClasses["home_groups_container"]}
                      key={index}
                    >
                      <div className={HomeClasses["home_group_container"]}>
                        <div className={HomeClasses["home_group_div"]}>
                          <h2 id={HomeClasses["home_group_h2"]}>
                            {productGroup.groupName}
                          </h2>
                          <button
                            className={HomeClasses["home_group_btn"]}
                            type="button"
                            onClick={() => viewAllpage(productGroup.groupId)}
                          >
                            View All
                          </button>
                        </div>
                        <div className={HomeClasses["home_product_container"]}>
                          {productGroup.products.length === 0 ? (
                            <div className={HomeClasses["empty_products"]}>
                              <div
                                className={HomeClasses["empty_image_container"]}
                              >
                                <img
                                  className={HomeClasses["empty_image"]}
                                  src={emptyproducts}
                                  alt="image"
                                />
                              </div>
                              <p className={HomeClasses["empty_portal_1"]}>
                                No Products to show, Create your first Group and
                                add products to define Price's
                              </p>
                            </div>
                          ) : (
                            productGroup.products
                              ?.slice(0, 6)
                              .map((product, index) => {
                                // let discountPrice =
                                //   product.price * (product.discount / 100);
                                // console.log(discountPrice);
                                // let finalPrice = product.price - discountPrice;
                                return (
                                  <NavLink
                                    className={HomeClasses["view_manage_link"]}
                                    to={`/groupproductdetails/${productGroup.groupId}/${product.productId}`}
                                    key={index}
                                  >
                                    <div
                                      className={
                                        HomeClasses["home_product_box"]
                                      }
                                    // style={{
                                    //   backgroundImage: `linear-gradient(to top, rgba(243, 230, 239, 0), ${index === 0
                                    //     ? "rgba(238, 225, 233, 1)"
                                    //     : "rgba(246, 240, 228, 1)"
                                    //     })`,
                                    //   // height: "38vh",
                                    // }}
                                    // key={index}
                                    >
                                      <div
                                        className={
                                          HomeClasses[
                                          "home_product_img_container"
                                          ]}>

                                        {
                                          product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                            <img
                                              className={HomeClasses["manage_prod_imge"]}
                                              src={product.productImageList[0]}
                                              alt="image"
                                              onError={(e) => {
                                                e.currentTarget.onerror = null;
                                                e.currentTarget.src = Image;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className={HomeClasses["manage_prod_imge"]}
                                              src={Image}
                                              alt="default image"
                                            />
                                          )
                                        }
                                      </div>
                                      <div
                                        className={
                                          HomeClasses["product_info_c"]
                                        }>
                                        <p
                                          id={HomeClasses["home_product_text1"]}
                                        >
                                          {product.productName}
                                        </p>
                                        {
                                          product.stockQty === 0 && product.status === 2 ? <p className={HomeClasses.outtext}>Out of Stock</p> : <>

                                            {product.productBagWeightInKgs !== 0
                                              && (
                                                <p
                                                  id={
                                                    HomeClasses[
                                                    "home_product_text3"
                                                    ]}>
                                                  {product.productBagWeightInKgs}
                                                  KG'S/
                                                  {product.productMeasuringUnit}
                                                </p>
                                              )}
                                            <p className={HomeClasses["p_price"]}>
                                              ₹{product.price}/{" "}
                                              {product.productPriceUnit}
                                            </p>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  </NavLink>
                                );
                              })
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>

      {showModal && (
        <div className={HomeClasses["reject_modal"]}>
          <div
            onClick={() => setShowModal(false)}
            className={HomeClasses["overlaye"]}
          ></div>
          <div className={HomeClasses["update_modal_content"]}>
            <h2 id={HomeClasses["resct_header"]}>Add Banner</h2>
            <div className={HomeClasses["update_input_model_container"]}>
              <form
                onSubmit={handleSubmit(editBanner)}
                className={HomeClasses["banner_form"]}
              >
                <div className={HomeClasses["banner_input"]}>
                  <input type="file" onChange={fileUploadHandler} />
                  {errors.dashboardImg && (
                    <p className={HomeClasses["errors"]}>
                      {errors.dashboardImg.message}
                    </p>
                  )}
                </div>
                <div className={HomeClasses["banner_form_btns"]}>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className={HomeClasses["banner_d_btn"]}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={HomeClasses["banner_e_btn"]}>
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default Home;
