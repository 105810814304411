import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/layout";
import ShopClass from "../../pages/Shop/shop.module.css";
import { GoArrowLeft } from "react-icons/go";
import { BsPatchCheckFill } from "react-icons/bs";

function OrderSuccessful() {
    const Navigate = useNavigate();

    const cardContainerStyle = {
        textAlign: 'center',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#F8FAF5',
        margin: '0 auto',
    };

    const checkmarkStyle = {
        fontSize: '3rem',
        color: '#34a853',
    };

    return (
        <Layout Active={"Shop"}>
            <div>
                <div className={ShopClass["home_wrapper"]}>
                    <div className={ShopClass["Add_main_container"]}>
                        <div className={ShopClass["Add_header"]}>
                            <button
                                className={ShopClass["add_back_btn"]}
                                type="button"
                                onClick={() => {
                                    Navigate(-1);
                                }}>
                                <GoArrowLeft />
                            </button>
                            <h1 id={ShopClass["add_header_h2"]}>
                                Order Successful
                            </h1>
                        </div>

                        <div className={ShopClass["Success_card_container"]}>
                            <div className={ShopClass["Success_card"]}>
                                <div className={ShopClass["Success-circle-container"]}>
                                    <BsPatchCheckFill />
                                </div>
                                <h3 className={ShopClass["Success-Header"]}>
                                    Thanks For Your Order
                                </h3>
                                <p>
                                    you can view the delivery in the "Orders"
                                    <br />
                                    Section.
                                </p>
                                <div className={ShopClass["Success_card_buttons"]}>
                                    <button
                                        className={ShopClass["Success_card_button_one"]}
                                        onClick={() =>
                                            Navigate("/shop", { replace: true })
                                        }>
                                        Back to home
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
}

export default OrderSuccessful;
