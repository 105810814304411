import React from 'react'

const Privacypolicy = () => {
  return (
    <div clssName={["privacy_conatiner"]}>
        <h1 className="privacy_header">Privacy Policy</h1>
        <div className="privacy_content">
          <ol className="privacy_content_ol">
            <li className="privacy_content_li">Information Collection:</li>
            <p className="privacy_content_p">
              {" "}
              We collect user information during registration and using our
              services. This includes but is not limited to names, email
              addresses, and account preferences.
            </p>
            <li className="privacy_content_li">Use of Information:</li>
            <p className="privacy_content_p">
              The information collected is used to provide and improve our
              services. We do not share user information with third parties
              except as required by law.
            </p>
            <li className="privacy_content_li"> Security:</li>
            <p className="privacy_content_p">
              We employ security measures to protect user information. However,
              no online data transmission is completely secure, and we cannot
              guarantee the security of your information.
            </p>
            <li className="privacy_content_li">Cookies:</li>
            <p className="privacy_content_p">
              We may use cookies to enhance user experience. Users can control
              cookie preferences through their browser settings.
            </p>
            <li className="privacy_content_li">Changes to Privacy Policy:</li>
            <p className="privacy_content_p">
              Tanyya Tech Solutions LLP reserves the right to update the Privacy
              Policy. Users will be notified of any significant changes.
            </p>

            <li className="privacy_content_li">Contact Information:</li>
            <p className="privacy_content_p">
              For questions or concerns about our Privacy Policy, please contact
              us at Tanyya Tech Solutions
            </p>

            <div className="privacy_content_contact_details">
              <h4>Mail: Support@Tanyya.com</h4>
              <h4>Contact: 9963126962</h4>
              <address>
                <h4>Address:</h4>H-No: 7-1/463/MIG, Flat 302, Nallagandla
                ,Hyderabad,Telangana,500019
              </address>
            </div>
          </ol>
        </div>
    </div>
  )
}

export default Privacypolicy