import React from "react";
import modalClasses from "./utilities.module.css";
import Playstore from '../../assets/landingpage/Android_1.png'
import AppleStore from '../../assets/landingpage/Android_2.png'
import googlePlay from "../../assets/landingpage/google-play.png";
import { FaApple } from "react-icons/fa";
const Modal = () => {
    const CancelHandler = (e) => {
        e.stopPropagation();
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    const appele = () => {
        window.open("https://apps.apple.com/in/app/traders-ground/id6479200330", "_blank");
    };
    const playstore = () => {
        window.open("https://play.google.com/store/apps/details?id=com.tanyya.tradersground", "_blank");
    };
    return (
        <>
            <div
                className={`${modalClasses["modal"]} ${modalClasses["warning"]}`}>
                <div
                    className={modalClasses["modal-content"]}
                    onClick={openhandler}>
                    <div className={modalClasses["header"]}>

                    </div>
                    <p className={modalClasses.warntext}>The login page is not available for mobile devices, please use the app to access the services.</p>
                    <div className={modalClasses["content_btns"]}>

                        <button className={modalClasses["google-play-button"]} type="button" onClick={() => playstore()}>
                            <span className={modalClasses.image_container}>
                                <img src={googlePlay} alt="googlePlay" width={40} height={40} className={modalClasses["google-play-icon"]} style={{ paddingLeft: "8px" }}></img>
                            </span>
                            <span className={modalClasses.text_container}>
                                <p className={modalClasses.text1}>GET IT ON</p>
                                <p className={modalClasses.text2}>Play Store</p>
                            </span>
                        </button>

                        <button className={modalClasses["google-play-button"]} type="button" onClick={() => appele()}>
                            <span className={modalClasses.image_container}>
                                <FaApple className={modalClasses["google-play-icon2"]} />
                            </span>
                            <span className={modalClasses.text_container}>
                                <p className={modalClasses.text1}>GET IT ON</p>
                                <p className={modalClasses.text2}>App Store</p>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;
