import Modal from "react-modal";
import "./PackageSelectionModal.css";
import { useEffect, useRef } from "react";
import React, { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { ShopServices } from "../../pages/Shop/ShopServicess";

const PackageSelectionModal = ({
  isOpen,
  onRequestClose,
  selectedProduct,
  getCartItems,
  cartDetails,
  manufacturerId,
  CartManufactureId,
}) => {
  const input = useRef();
  const [packageType, setPackageType] = useState("");
  const [errormsg, setErrorMsg] = useState(null);
  const [quantity, setQuantity] = useState(1);
  console.log(manufacturerId, CartManufactureId, cartDetails);
  useEffect(() => {
    let cartItem = cartDetails?.filter(
      (item) =>
        item.productId == selectedProduct.productId &&
        item.groupId == selectedProduct.groupId
    );
    // console.log(selectedProduct,cartDetails,cartItem)
    if (cartItem?.length > 0) {
      setQuantity(cartItem[0].qty);
    }
  }, []);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const addToCart = async () => {

    let calculatedQuantity = quantity;
    if (
      String(selectedProduct.productPriceUnit).toLowerCase() !==
      String(selectedProduct.productMeasuringUnit).toLowerCase()
    ) {
      // let maxAllowedQuantity =
      //   selectedProduct.stockQty / selectedProduct.productBagWeightInKgs;
      let maxAllowedQuantity =
        selectedProduct.stockQty;

      maxAllowedQuantity =
        selectedProduct.productPriceUnit == "Quintal" && maxAllowedQuantity;

      if (calculatedQuantity > maxAllowedQuantity) {
        setErrorMsg(
          `Max allowed quantity should be less than ${Math.floor(
            maxAllowedQuantity
          )} ${selectedProduct.productMeasuringUnit}`
        );
        return;
      }
    } else {
      if (calculatedQuantity > selectedProduct.stockQty) {
        setErrorMsg(
          `Max allowed quantity ${selectedProduct.stockQty} ${packageType}`
        );
        return;
      }
    }
    setErrorMsg(null);
    // if (packageType === "") {
    //   alert("Please select a package type");
    //   return;
    // }

    const body = {
      cartItem: {
        productId: selectedProduct.productId,
        qty: calculatedQuantity,
        groupId: selectedProduct.groupId,
        price: selectedProduct.price,
        manufacturerId: manufacturerId,
        packageType: input.current.value,
      },
    };

    ShopServices.AddToCart(
      "POST",
      JSON.stringify(body),
      token
    ).then((res) => {

      if (res.Status === 1) {
        ToastSuccess(res.Message);
        onRequestClose();
        getCartItems();
      } else {
        ToastError(res.Message);
      }
    }).catch((err) => {
      ToastError("Something went wrong, please try again later");
      console.error(err);
    })
  };

  const handleQuantityChange = (e) => {
    const inputValue = e.target.value;
    setQuantity(parseInt(inputValue));
  };


  console.log(
    selectedProduct?.productPriceUnit === "Quintal" &&
    selectedProduct?.productMeasuringUnit === "Bag");
  console.log("product", selectedProduct);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="custom-overlay"
      className="custom-modal"
      style={{ inset: "0px", position: "none" }}
    >
      <h3>Add To Cart</h3>
      <form style={{ width: "100%", display: "grid" }}>

        <select
          className="model_quantity_select"
          onChange={(event) => setPackageType(event.target.value)}
          // value={packageType}
          ref={input}
        >
          {/* <option value={""} disabled> Select Type</option> */}
          {selectedProduct.productPriceUnit === "Quintal" &&
            selectedProduct?.productMeasuringUnit === "Bag"
            ? (
              <option value="Bag">Bag</option>
            ) : (
              <option value={selectedProduct.productPriceUnit}>
                {selectedProduct.productPriceUnit}
              </option>
            )}
        </select>


        <input
          type="number"

          className="model_quantity_input"
          placeholder="Enter Quantity"
          min={1}
          onChange={handleQuantityChange}
          value={quantity}
        />
        {errormsg && <p className="errorText">{errormsg}</p>}
        {CartManufactureId != manufacturerId && cartDetails.length > 0 && (
          <span
            style={{
              color: "red",
              textAlign: "center",
              margin: "5px",
            }}
          >
            Cart Consists of Items from Other Manufacturer,Clear Cart and Try
            again
          </span>
        )}
        <div className="Model_buttons">
          <button
            className="model-right-button"
            type="button"
            onClick={onRequestClose}
          >
            Cancel
          </button>
          {CartManufactureId != manufacturerId && cartDetails.length > 0 ? (
            " "
          ) : (
            <button
              className="model-left-button"
              type="button"
              onClick={addToCart}
            // disabled={
            //   CartManufactureId != manufacturerId && cartDetails.length > 0
            // }
            >
              Add
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default PackageSelectionModal;
