import React from "react";
import Business from "../../assets/landingpage/slidebusiness.jpeg";

const Content_2 = () => {
  return (
    <div className="content_2_container">
      <img className="content_2_image" src={Business} alt="Business_image" />
      <div className="content_2_text">
        <h1 id="content_2_h1">
          Our<span id="extra">Mission</span>{" "}
        </h1>
        <div style={{ padding: '10px' }}>
          <p className="content_2_p" style={{ paddingBottom: '10px' }} >
            Our goal is to offer a strong CRM solution that helps businesses connect better with customers,
            streamline operations, and increase profits. Our software is user-friendly and respects your privacy,
            suitable for all types of businesses. It's the perfect choice for the food industry, bringing together
            <b className="content_2_extra_bold">{" "}Manufacturers, Distributors, Retailers, Marketers,</b> and <b className="content_2_extra_bold">Agents</b> to enhance growth and efficiency.
          </p>
 
          <p className="content_2_p">
            Our CRM solution stands out with unique features. It allows registration of different profiles like
            <b className="content_2_extra_bold">{" "}Manufacturers, Distributors, Retailers, Marketers,</b> and <b className="content_2_extra_bold">Agents</b>. Sellers can offer customized pricing
            to specific buyers, and the platform enables tracking of all orders with updates on partial payments.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content_2;
