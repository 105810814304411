import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import HomeClasses from "../../pages/Home/home.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import dropBox from "../../assets/orderbox.png";
import { IoIosCall } from "react-icons/io";
import { MdBusinessCenter, MdLocationPin } from "react-icons/md";
import { HiMiniUser } from "react-icons/hi2";
import { BiRupee, BiSolidCreditCardFront } from "react-icons/bi";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";
import { PurchaseService } from "./PurchaseService";
import { LuDownload } from "react-icons/lu";
import Moment from "react-moment";
import Image from '../../assets/noplot.png'

const PurchaseDetails = () => {
  const { orderId } = useParams();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orderdetails, setOrderdetails] = useState([]);
  const [orderedfrom, setOrderedfrom] = useState({});
  const [rejectReason, setRejectReason] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [gst, setGst] = useState(0);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  useEffect(() => {
    OrderDetails(orderId);
  }, [orderId]);

  const OrderDetails = async (orderId) => {
    try {
      setLoading(false);
      const res = await Services.getOrderdetails("GET", null, token, orderId);
      if (res.Status == 1) {
        setOrderdetails(res.orderDetails);
        setProducts(res.orderDetails.products);
        setOrderedfrom(res.orderedFrom);
      }
    } catch (err) {
      // alert("Something went wrong please try again later");
      console.error(err);
    }
  };

  const Reorder = async (orderId) => {
    // e.stopPropagation();
    try {
      const res = await PurchaseService.ReOrder(
        "POST",
        JSON.stringify({}),
        token,
        orderId
      );
      if (res.Status === 1) {
        ToastSuccess("Products Added to Cart successfully");
        Navigate("/cart");
        // setpurchaseOrders(res.orderDetails);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };

  const cancelOrder = async (reason) => {
    let body = {
      userOrdersStatus: 6,
      orderId: orderdetails.orderId,
      rejectReason: reason,
      products: orderdetails.products,
    };
    try {
      const res = await PurchaseService.CancelOrder(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        setCancelModal(false);
        setRejectReason(" ");
        OrderDetails(orderId);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };

  // const totalGst = products.reduce(
  //   (acc, curr) => curr.productDiscountPrice * (curr.gst / 100) + acc,
  //   0
  // );

  // console.log(totalDiscount);
  const InvoiceBill = () => {
    window.open(orderdetails.invoice, "_blank");
  };

  return (
    <Layout Active={"Purchase Orders"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["Add_main_container"]}>
            <div className={HomeClasses["Add_header"]}>
              <button
                className={HomeClasses["add_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={HomeClasses["add_header_h2"]}>OrderID#{orderId}</h1>
            </div>
            <div className={HomeClasses["order_d_container"]}>
              <div className={HomeClasses["order_box_container"]}>
                <div className={HomeClasses["order_s_box"]}>
                  <div className={HomeClasses["order_image_container"]}>
                    <img
                      src={dropBox}
                      alt="image"
                      className={HomeClasses["order_image"]}
                    />
                  </div>
                  <p id={HomeClasses["orders_status"]}>
                    <h5 id={HomeClasses["order_d_header"]}>
                      {orderdetails.userOrdersStatus === 1
                        ? "Order Pending "
                        : orderdetails.userOrdersStatus === 2
                          ? "Order Processing"
                          : orderdetails.userOrdersStatus === 3
                            ? "Order Invoice printed"
                            : orderdetails.userOrdersStatus === 4
                              ? "Order Transport"
                              : orderdetails.userOrdersStatus === 5
                                ? "Order Delivered"
                                : orderdetails.userOrdersStatus === 6
                                  ? "Order Rejected"
                                  : orderdetails.userOrdersStatus === 7
                                    ? "Order Completed"
                                    : " "}
                    </h5>
                    <span id={HomeClasses["order_d_date"]}>
                      <Moment
                        format="DD MMM, YYYY, hh:mm A"
                        subtract={{ hours: 5, minutes: 30 }}
                      >
                        {new Date(orderdetails.createdAt)}
                      </Moment>
                    </span>
                  </p>
                </div>
              </div>
              <div className={HomeClasses["order_address"]}>
                <h3 className={HomeClasses["order_from_addres"]}>
                  Ordered From
                </h3>

                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdBusinessCenter className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.businessName}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.name}{" "}({orderedfrom.accountType === 1
                      ? "Manufacturer"
                      : orderedfrom.accountType === 2
                        ? "Distributor"
                        : orderedfrom.accountType === 3
                          ? "Retailer"
                          : orderedfrom.accountType === 4
                            ? "Marketing Specialist"
                            : orderedfrom.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.mobileNumber}
                  </p>
                </p>
                {/* <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdLocationPin className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.deliveryAddress}
                  </p>
                </p> */}
                {/* <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <BiSolidCreditCardFront
                      className={HomeClasses["order_d_icon"]}
                    />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderedfrom.GSTNumber}
                  </p>
                </p> */}
              </div>
              <div className={HomeClasses["order_address"]}>
                <h2 id={HomeClasses["order_d_h2"]}>Delivery To</h2>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverName}{" "}({orderdetails.accountType === 1
                      ? "Manufacturer"
                      : orderdetails.accountType === 2
                        ? "Distributor"
                        : orderdetails.accountType === 3
                          ? "Retailer"
                          : orderdetails.accountType === 4
                            ? "Marketing Specialist"
                            : orderdetails.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdBusinessCenter className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverbusinessName}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdLocationPin className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.deliveryAddress}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverMobileNumber}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <BiSolidCreditCardFront
                      className={HomeClasses["order_d_icon"]}
                    />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.GSTNumber}
                  </p>
                </p>
              </div>
              <div className={HomeClasses["order_products"]}>
                <h2 id={HomeClasses["order_prod_header"]}>Package Items</h2>
                {orderdetails.products &&
                  orderdetails.products.map((product, index) => {
                    // let discountPrice =
                    //   product.price * (product.discount / 100);
                    // let Gst =
                    //   product.productDiscountPrice * (product.gst / 100);

                    // let price =
                    //   product.packageType === "Bag"
                    //     ? product.bagPrice * product.qty
                    //     : product.price;
                    return (
                      <div
                        className={HomeClasses["order_prod_container"]}
                        key={index}
                      >
                        <div className={HomeClasses["order_prod_box"]}>
                          <div
                            className={HomeClasses["order_prog_img_container"]}
                          >

                            {
                              product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                <img
                                  className={HomeClasses["order_prod_image"]}
                                  src={product.productImageList[0]}
                                  alt="image"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = Image;
                                  }}
                                />
                              ) : (
                                <img
                                  className={HomeClasses["order_prod_image"]}
                                  src={Image}
                                  alt="image"
                                />
                              )
                            }
                          </div>
                          <div className={HomeClasses["order_prod_info"]}>
                            <h5 id={HomeClasses["order_prod_name"]}>
                              {product.productName}
                            </h5>
                            <p id={HomeClasses["order_prod_Qty"]}>
                              Qty: {product.qty}{" "}{product.productMeasuringUnit}'s
                            </p>
                            <p id={HomeClasses["order_prod_Qty"]}>
                              HSN: {product.HSNCode}
                            </p>
                          </div>
                        </div>
                        <div className={HomeClasses["order_prod_price"]}>
                          <p className={HomeClasses["order_d_price"]}>
                            <span id={HomeClasses["order_price_container"]}>
                              Price
                            </span>
                            <p id={HomeClasses["order_rupee"]}>
                              <BiRupee />
                              {product.price}
                            </p>

                            {/* <p id={HomeClasses["order_gst"]}>
                              GST({product.gst}%) = ₹{Math.round(Gst)}
                            </p>
                            <p id={HomeClasses["order_discount"]}>
                              DISCOUNT({product.discount}%) = ₹
                              {Math.round(
                                product.individualProductDiscount * product.qty
                              )}
                            </p> */}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                <div className={HomeClasses["order_total"]}>
                  <p id={HomeClasses["order_text_"]}>Order Price</p>
                  <p className={HomeClasses["order_discount"]}>
                    <p id={HomeClasses["order_price_rupee"]}>
                      <BiRupee />
                      {Number(orderdetails.totalPrice).toFixed(2)}

                    </p>
                    <p className={HomeClasses["discountprice"]}>
                      Invoice Price: ₹{orderdetails.finalAmount}
                    </p>
                    <p className={HomeClasses["discountprice"]}>
                      {
                        orderdetails.invoiceNumber !== "NA" && (<>Invoice Number:{" "} {orderdetails.invoiceNumber}</>)
                      }
                    </p>
                  </p>
                </div>
              </div>
              <div className={HomeClasses["order_d_payments"]}>
                <h3 id={HomeClasses["order_paments_header"]}>Payments</h3>
                <p className={HomeClasses["payment_status_ckeck"]}>
                  <p className={HomeClasses["payment_staus"]}>Payment Status</p>
                  <p className={HomeClasses["p_s"]}>
                    {orderdetails.paymentStatus === 1 ? "Paid" : "Un Paid"}
                  </p>
                </p>
                <div className={HomeClasses["payment_headers"]}>
                  <p id={HomeClasses["header_payments"]}>Payment Date</p>
                  <p id={HomeClasses["header_payments"]}>Recieved Payments</p>
                </div>

                {orderdetails.payments &&
                  orderdetails.payments.map((payment, index) => {
                    return (
                      <div
                        className={HomeClasses["order_payment_lists"]}
                        key={index}
                      >
                        <div className={HomeClasses["payment_data"]}>
                          <p id={HomeClasses["payment_item"]}>
                            <Moment
                              format="DD MMM, YYYY, hh:mm A"
                              subtract={{ hours: 5, minutes: 30 }}
                            >
                              {new Date(payment.createdAt)}
                            </Moment>
                          </p>
                          <p id={HomeClasses["payment_item"]}>
                            <BiRupee /> {payment.receivedPayement}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={HomeClasses["order_update_container"]}>
                {orderdetails.suplierOrdersStatus === 6 ? (
                  <>
                    <div className={HomeClasses["order_update_s"]}>
                      <h1 id={HomeClasses["order_Status"]}>Reason</h1>
                      <p id={HomeClasses["order_update_status"]}>
                        {orderdetails.rejectReason}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={HomeClasses["order_update_s"]}>
                      <h1 id={HomeClasses["order_Status"]}>Status</h1>
                      <p id={HomeClasses["order_update_status"]}>
                        {orderdetails.suplierOrdersStatus === 1
                          ? "New Order"
                          : orderdetails.suplierOrdersStatus === 2
                            ? "Processing"
                            : orderdetails.suplierOrdersStatus === 3
                              ? "Invoice printed"
                              : orderdetails.suplierOrdersStatus === 4
                                ? "Transport"
                                : orderdetails.suplierOrdersStatus === 5
                                  ? "Delivered"
                                  : orderdetails.suplierOrdersStatus === 6
                                    ? "Rejected"
                                    : "Completed"}
                      </p>
                    </div>

                    <div className={HomeClasses["order_remainder"]}>
                      {orderdetails.user === 5 ||
                        (orderdetails.suplierOrdersStatus === 7 && (
                          <button
                            type="button"
                            className={HomeClasses["reorder_button"]}
                            onClick={() => Reorder(orderdetails.orderId)}
                          >
                            Reorder
                          </button>
                        ))}
                      {((orderdetails.userOrdersStatus === 3 &&
                        orderdetails.invoice !== "NA") ||
                        (orderdetails.userOrdersStatus === 5 &&
                          orderdetails.invoice !== "NA") ||
                        (orderdetails.userOrdersStatus === 7 &&
                          orderdetails.invoice !== "NA")) && (
                          <button
                            type="button"
                            className={HomeClasses["order_remainder_btn"]}
                            onClick={() => InvoiceBill()}
                          >
                            <LuDownload /> Order Information
                          </button>
                        )}
                      {(orderdetails.userOrdersStatus === 1 ||
                        orderdetails.userOrdersStatus === 2) && (
                          <button
                            type="button"
                            className={HomeClasses["order_remainder_btn"]}
                            onClick={() => setCancelModal(true)}
                          >
                            Cancel Order
                          </button>
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Cancel  order Modal */}
        {cancelModal && (
          <div className={HomeClasses["reject_modal"]}>
            <div
              onClick={() => setCancelModal(false)}
              className={HomeClasses["overlaye"]}
            ></div>
            <div className={HomeClasses["update_modal_content"]}>
              <h2 id={HomeClasses["resct_header"]}>Cancel Order</h2>
              <div className={HomeClasses["update_input_model_container"]}>
                <div className={HomeClasses["banner_i_container"]}>
                  <input
                    type="textarea"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    placeholder="Description"
                    autoFocus
                  />
                </div>
                <div className={HomeClasses["cancel_form_btns"]}>
                  <button
                    type="button"
                    className={HomeClasses["cancel_c_btn"]}
                    onClick={() => setCancelModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={HomeClasses["cancel_s_btn"]}
                    disabled={!rejectReason}
                    onClick={() => {
                      cancelOrder(rejectReason);
                      setCancelModal(false);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PurchaseDetails;
