import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import HomeClasses from "../../pages/Home/home.module.css";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Cell } from 'recharts';
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { override } from "./helper";
import Permissionimg from "../../assets/NoPermissions.png";
import { format, parseISO } from 'date-fns';
import { PieChart, Pie, Sector, ResponsiveContainer, Legend } from 'recharts';


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.month}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
      />

    </g>
  );
};


const SalesStatusses = () => {
  const Navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [salesData, setSalesData] = useState([]);
  const [regionalData, setRegionalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [individualBuyerReport, setIndividualBuyerReport] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: { Type: 1 } });

  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 9
  );
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;

  const formData = watch();



  useEffect(() => {
    const getPermissions = async () => {
      try {
        const res = await Services.OfficePermissions("GET", null, token);
        if (res.Status === 1) {
          localStorage.setItem("permissions", JSON.stringify(res.permissions));
        }
      } catch (err) {
        console.error(err);
      }
    };
    getPermissions();
  }, []);

  useEffect(() => {
    getSales({ Type: 1 });
  }, []);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const getSales = async (data) => {
    try {
      setLoading(true);
      const res = await Services.SalesApi("POST", JSON.stringify(data), token);
      if (res.Status === 1) {
        setSalesData(res.sales);
        setRegionalData(res.regionalSales);
        setIndividualBuyerReport(res.individualBuyerReport);
        // setLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  // const Sales = async (data) => {
  //   if (data.Type == 1) {
  //     getSales(data)
  //   }
  //   else {
  //     getSales(data)
  //   }
  // };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const getPastSixMonths = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const pastSixMonths = [];

    for (let i = 0; i < 6; i++) {
      const monthIndex = currentMonth - i;
      let year = currentYear;

      if (monthIndex < 0) {
        year -= 1;
      }

      const month = ((monthIndex % 12) + 12) % 12;

      const firstDate = moment(new Date(year, month, 1)).format('YYYY-MM-DD');
      const lastDate = moment(new Date(year, month + 1, 0)).format("YYYY-MM-DD");

      const monthName = new Date(year, month, 1).toLocaleString('default', { month: 'long' });
      pastSixMonths.unshift({ month: monthName, firstDate, lastDate, id: i + 1 });
    }

    return pastSixMonths.reverse();
  };




  const handleMonthChange = (e) => {
    const [fromDate, toDate] = String(e.target.value).split(",");
    getSales({ Type: 2, fromDate, toDate });
  };

  return (
    <Layout Active={"Sales Reports"}>
      <div className={HomeClasses["home_wrapper"]}>
        {accountType == 6 && PermissionStatus == 0 ? (
          <div className={HomeClasses["permission_container"]}>
            <img src={Permissionimg} alt="image" />
            <h2>
              {" "}
              we're sorry, But you dont have required permissions to access
              this page. Please contact the site administrator.
            </h2>
          </div>
        ) : (
          <div className={HomeClasses["sales_main_container"]}>
            <div className={HomeClasses["sales_graph_container"]}>
              <form
                className={HomeClasses["sales_form"]}
              >
                <div>
                  <select
                    {...register("MonthRange", { required: "Select Month Range" })}
                    id="MonthRange"
                    className={HomeClasses["select_month"]}
                    onChange={handleMonthChange}
                  >
                    <option value="" >Select Month</option>
                    {getPastSixMonths().map((month, index) => (
                      <option key={month.id} value={`${month.firstDate},${month.lastDate}`}>
                        {month.month}
                      </option>
                    ))}
                  </select>
                  {errors.MonthRange && (
                    <p className={HomeClasses["errors"]}>
                      {errors.MonthRange.message}
                    </p>
                  )}
                </div>
              </form>

            </div>
            {loading ? (
              <div className={HomeClasses["loader"]}>
                <RotatingLines
                  color={"#4285f4"}
                  loading={true}
                  css={override}
                  size={100}
                  width={100}
                  height={100}
                  strokeWidth={4}
                  animationDuration={0.75}
                />
              </div>
            ) : (
              <div className={HomeClasses.sales_data_container}>
                {salesData.length > 0 && (
                  <div className={HomeClasses["salesdat1"]}>
                    <h5 id={HomeClasses["sale_header"]}> Monthly Sales</h5>
                    <PieChart width={400} height={400}>
                      <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={salesData}
                        cx="50%"
                        cy="50%"
                        innerRadius={80}
                        outerRadius={110}
                        fill="#8884d8"
                        dataKey="totalAmount"
                        onMouseEnter={onPieEnter}
                      />

                    </PieChart>
                    <p className={HomeClasses.salestBtn}><button className={HomeClasses.colorbtn} type="button"></button>{salesData.map((item) => item.totalAmount)}</p>
                  </div>
                )}

                {regionalData.length > 0 && (
                  <div className={HomeClasses["salesdat2"]}>
                    <h5 id={HomeClasses["sale_header"]}>Monthly Regional Sales</h5>
                    <BarChart
                      width={500}
                      height={400}
                      data={regionalData}
                      margin={{ top: 5, right: 10, left: 40, bottom: 20 }}
                      layout="vertical"
                    >
                      <XAxis type="number" />
                      <YAxis dataKey="groupName" type="category" stroke="#8884d8" />
                      <Tooltip />
                      <Bar dataKey="regionalAmount" fill="#8884d8" barSize={40}>
                        {regionalData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={[
                            "#A2CFDC",
                            "#FDAB97",
                            "#9CC16C",
                            "#7C97CB",
                            "#F3C4AC",
                            "#7DBDCC",
                            "#EF7979",
                            "#44B98E"
                          ][index % 8]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </div>
                )}

                <div className={HomeClasses.indiviualreport}>

                  <h4 className={HomeClasses["p_header"]}>
                    Individual Buyer reports
                  </h4>
                  {
                    individualBuyerReport.length === 0 ? <h5 className={HomeClasses.nodatatext}>No Buyer Records Found</h5> : <div className={HomeClasses["p_payments"]}>
                      <table className={HomeClasses["buyer_table"]}>
                        <thead className={HomeClasses["p_payment-header"]}>
                          <tr>
                            <th>Name</th>
                            <th>Purchased Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {individualBuyerReport.map((buyer, index) => {
                            return (
                              <tr key={index}>
                                <td>{buyer.name}</td>
                                <td>₹{buyer.purchasedAmount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </div>

            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SalesStatusses;
