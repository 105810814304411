import React from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";

function Terms() {
  return (
    <div>
      <Navbar />
      <div className="privacy_container">
        <h1 className="privacy_header">Terms and Conditions</h1>
        <div className="privacy_content">
          <ol className="privacy_content_ol">
            <li className="privacy_content_li"> Acceptance of Terms:</li>
            <p className="privacy_content_p">
              {" "}
              By accessing or using our website/Android/IOS (&quot;Traders Ground&quot;) and the services provided by
              Tanyya Tech Solutions LLP, you agree to comply with and be bound by these Terms and Conditions.
            </p>
            <li className="privacy_content_li">User Registration:</li>
            <p className="privacy_content_p">
              To use certain features of our website, you may be required to register an account. You are
              responsible for maintaining the confidentiality of your account information.
            </p>
            <li className="privacy_content_li">Subscription and Payment:</li>
            <p className="privacy_content_p">
              Manufacturers and distributors must subscribe to sell their products. Payments are to be made
              based on the subscription plan selected. Subscriptions can be cancelled at any time. Bills are
              generated at the end of each month and are calculated on a pro-rata basis, covering only the period
              from the date of subscription to the end of the month.</p>
            <p className="privacy_content_p">
              Refunds, no advance payment is required for subscriptions; subscribers utilize our services
              throughout the month and are invoiced at the end of the billing period, with no upfront fees. If a
              subscriber is unable to pay due to a valid reason mutually agreed upon, the company may waive off
              the payment. Cancellation of subscription plans is permissible, with no refund issued, as payment is
              made only at the end of the billing cycle.&quot;
            </p>
            <li className="privacy_content_li">User Responsibilities:</li>
            <p className="privacy_content_p">
              Buyers and sellers are responsible for their interactions and transactions on the platform. Tanyya
              Tech Solutions LLP is not involved in monetary transactions or delivery of products.
            </p>
            <li className="privacy_content_li">
              Group and Product Visibility:
            </li>
            <p className="privacy_content_p">
              Buyers can add sellers to their group to view the seller&#39;s products and prices. Tanyya Tech Solutions
              LLP does not control or guarantee the accuracy of product information.
            </p>

            <li className="privacy_content_li">Order Processing:</li>
            <p className="privacy_content_p">
              We facilitate order requests between buyers and sellers. Sellers are responsible for the fulfilment of
              orders, and buyers make payments directly to sellers.
            </p>

            <li className="privacy_content_li">Privacy and Security:</li>
            <p className="privacy_content_p">
              We respect user privacy and do not share buyers&#39; and sellers&#39; information with third parties. Refer to
              our Privacy Policy for more details.
            </p>

            <li className="privacy_content_li">Termination:</li>
            <p className="privacy_content_p">
              Tanyya Tech Solutions LLP reserves the right to terminate or suspend accounts for violation of these
              terms or any unlawful activities.
            </p>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
