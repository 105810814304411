import React from "react";
import { NavLink } from "react-router-dom";
import Tradersground from "../../assets/landingpage/Traders_footer.png";
import { MdKeyboardArrowRight } from "react-icons/md";

const Footer = () => {
  return (
    <div className="footer_container">
      <div className="footer_1">
        <div className="footer_box1">
          <img
            className="footer_image"
            src={Tradersground}
            alt="Traders Ground Logo"
          />
          <p className="footer_para">
            Traders Ground is a B2B platform owned by Tanyya Tech Solutions LLP.
            The platform is designed to simplify the entire process for
            manufacturers and distributors.
          </p>
        </div>

        <div className="footer_box2">
          <h2 className="footer_header">Quick Links</h2>

          <div className="footer_box2_inside_container">
            <div className="footer_box2_inside">
              <ul className="parent">
                <li className="child">
                  <MdKeyboardArrowRight className="footer_arrow_icon" />
                  <NavLink
                    to="/"
                    className="no-underline-footer"
                    id="footer_link"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="child">
                  <MdKeyboardArrowRight className="footer_arrow_icon" />
                  <NavLink
                    to="/pricing"
                    className="no-underline-footer"
                    id="footer_link"
                  >
                    Pricing
                  </NavLink>
                </li>
                <li className="child">
                  <MdKeyboardArrowRight className="footer_arrow_icon" />
                  <NavLink
                    to="/login"
                    className="no-underline-footer"
                    id="footer_link"
                  >
                    Login
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="footer_box2_inside">
              <ul className="parent">
                <li className="child">
                  <MdKeyboardArrowRight className="footer_arrow_icon" />
                  <NavLink
                    to="/contact"
                    className="no-underline-footer"
                    id="footer_link"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="child">
                  <MdKeyboardArrowRight className="footer_arrow_icon" />
                  <NavLink
                    to="/terms&conditions"
                    className="no-underline-footer"
                    id="footer_NavLink"
                  >
                    Terms & Conditions
                  </NavLink>
                </li>

                <li className="child">
                  <MdKeyboardArrowRight className="footer_arrow_icon" />
                  <NavLink
                    to="/privacy&policy"
                    className="no-underline-footer"
                    id="footer_link"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_2">
        <div className="footer2_inside">
          <span>Tanyya Tech Solutions LLP. All Rights Reserved ©2024</span>
        </div>
        <div className="footer2_inside">
          <span>Hyderabad ,Telangana - 500019 , India</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
