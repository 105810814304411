import React from "react";
import LocationImage from "../../assets/landingpage/At_location_support.jpeg";
import OnlineImage from "../../assets/landingpage/Online_Videos_Support.jpeg";
import TechnicalImage from "../../assets/landingpage/Technical_Support.jpeg";

const Content_7 = () => {
  return (
    <div className="content_7_main_container">
      <div className="Content_7_header">
      <h1 id="content_5_h1">
        Support @ <span id="extra">Traders Ground</span></h1>
        <h1 className="Content_5_header2">We take pride in our prompt service to help you maximize your benefits from Traders Ground</h1>
      </div>
      
      <div className="content_7_container">
        <div className="Content_7_item">
         <img src={TechnicalImage} alt="Image 1" /> 
          <h3>Technical Support</h3>
        </div>

        <div className="Content_7_item">
           <img src={OnlineImage} alt="Image 2" /> 
          <h3>Online Video Support</h3>
        </div>

        <div className="Content_7_item">
          <img src={LocationImage} alt="Image 3" />
          <h3>At Location Support</h3>
        </div>
      </div>
    </div>
  );
};

export default Content_7;
