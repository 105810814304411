import { v4 as uuidv4 } from "uuid";
import { FaAddressCard, FaUsers } from "react-icons/fa";
import { IoHomeSharp } from "react-icons/io5";
import { BsFillClipboard2CheckFill } from "react-icons/bs";
import { IoNotifications } from "react-icons/io5";
import { GiShop } from "react-icons/gi";
import { MdManageSearch } from "react-icons/md";
import { ImStatsBars } from "react-icons/im";
import { FaBoxesPacking } from "react-icons/fa6";
import { RiUserSettingsFill } from "react-icons/ri";

export const menuItems = [
  {
    id: uuidv4(),
    multiple: false,
    name: "Home",
    menuLink: "/Home",
    active: "Home",
    // icon: IoHomeSharp,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Shop",
    menuLink: "/Shop",
    active: "Shop",
    // icon: GiShop,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Users & Groups",
    menuLink: "/Users",
    active: "Users & Groups",
    // icon: FaUsers,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Customers",
    menuLink: "/Users",
    active: "Users & Groups",
    // icon: FaUsers,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Manage products",
    menuLink: "/Manage ",
    active: "Manage products",
    // icon: MdManageSearch,
  },

  {
    id: uuidv4(),
    multiple: false,
    name: "Sales Orders",
    menuLink: "/Orders",
    active: "Sales Orders",
    // icon: BsFillClipboard2CheckFill,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Purchase Orders",
    menuLink: "/Purchase",
    active: "Purchase Orders",
    // icon: FaBoxesPacking,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Sales Reports",
    menuLink: "/Sales",
    active: "Sales Reports",
    // icon: ImStatsBars,
  },

  {
    id: uuidv4(),
    multiple: false,
    name: "Notifications",
    menuLink: "/Notifications",
    active: "Notifications",
    // icon: IoNotifications,
  },

  {
    id: uuidv4(),
    multiple: false,
    name: "All Users",
    menuLink: "/allusers",
    active: "All Users",
    // icon: FaAddressCard,
  },
  {
    id: uuidv4(),
    multiple: false,
    name: "Account",
    menuLink: "/user",
    active: "Account",
    // icon: RiUserSettingsFill,
  },
];
