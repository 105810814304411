import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout/layout";
import HomeClasses from "../home.module.css";
import { GoArrowLeft } from "react-icons/go";
import { Services } from "../../../Services";
import {
  ToastError,
  ToastSuccess,
} from "../../../components/utils/ToastMessages";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import config from "./config";
window.Buffer = window.Buffer || require("buffer").Buffer;

const BannerEdit = () => {
  const [banners, setBanners] = useState({});
  const [iD, setID] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [banner, setBanner] = useState({});
  const [showModal, setShowModal] = useState(false);
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      const res = await Services.getAllGroupsProducts("GET", null, token);
      if (res.Status === 1) {
        setBanners(res.dashboardImages);
        setID(res.dashboardImages.imagesId);
      } else if (res.Status === 0) {
        ToastError(res.Message);
        if (res.Message === "Token expired") {
          Navigate("/");
          localStorage.clear();
        }
      }
    } catch (err) {
      alert("Something went wrong Please try again");
      console.error(err);
    }
  };

  const editBanner = async (data) => {
    if (!data.dashboardImg) {
      alert("Please select Image");
      return;
    }
    try {
      const res = await Services.BannerEdit(
        "PATCH",
        JSON.stringify(data),
        token,
        iD
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        setShowModal(false);
        getBanners();
      } else if (res.Status === 0) {
        ToastError(res.Message);
        if (res.Message === "Token expired") {
          Navigate("/");
          localStorage.clear();
        }
      }
    } catch (err) {
      alert("Something went wrong Please try again");
      console.error(err);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const fileUploadHandler = (event) => {
    const file = event.target.files[0];
    // return;
    const type = file.type;

    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
      console.log(newFileName);
      // return;
      const Modifiedfile = new File([file], newFileName);
      console.log(file);
      S3FileUpload.uploadFile(Modifiedfile, config)
        .then((data) => {
          setImageUrl(data.location);
          console.log(data);

          setValue("dashboardImg", newFileName);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Layout>
      <div className={HomeClasses["home_wrapper"]}>
        <div className={HomeClasses["Add_main_container"]}>
          <div className={HomeClasses["Add_header"]}>
            <button
              className={HomeClasses["add_back_btn"]}
              type="button"
              onClick={() => {
                Navigate(-1);
              }}
            >
              <GoArrowLeft />
            </button>
            <h1 id={HomeClasses["add_header_h2"]}>Banners</h1>
          </div>
          <div className={HomeClasses["banner_container"]}>
            <div className={HomeClasses["banners_container"]}>
              <div className={HomeClasses["banner_box"]}>
                <div className={HomeClasses["banner_img_container"]}>
                  <img
                    src={banners.dashboardImg}
                    className={HomeClasses["banner_img"]}
                  />
                </div>
                <div className={HomeClasses["banner_btns"]}>
                  <button
                    type="button"
                    className={HomeClasses["banner_e_btn"]}
                    onClick={() => openModal( banners.imagesId)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className={HomeClasses["reject_modal"]}>
          <div
            onClick={() => setShowModal(false)}
            className={HomeClasses["overlaye"]}
          ></div>
          <div className={HomeClasses["update_modal_content"]}>
            <h2 id={HomeClasses["resct_header"]}>Add Banner</h2>
            <div className={HomeClasses["update_input_model_container"]}>
              <form
                onSubmit={handleSubmit(editBanner)}
                className={HomeClasses["banner_form"]}
              >
                <div className={HomeClasses["banner_input"]}>
                  <input type="file" onChange={fileUploadHandler} />
                  {errors.dashboardImg && (
                    <p className={HomeClasses["errors"]}>
                      {errors.dashboardImg.message}
                    </p>
                  )}
                </div>
                <div className={HomeClasses["banner_form_btns"]}>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className={HomeClasses["banner_d_btn"]}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={HomeClasses["banner_e_btn"]}>
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default BannerEdit;
