import React from 'react'
import style from './utilities.module.css';
import Errorimg from '../../assets/404page.png';
const EmptyPage = () => {
    return (
        <div className={style['main_content']}>
            <div className={style['img_container']}>
                <img src={Errorimg} alt='image' />
            </div>
        </div>
    )
}

export default EmptyPage