import React, { useEffect, useState } from "react";
import LoginClasses from "../Login/login.module.css";
import Tanyya from "../../assets/login/tanyyalogin.png";
import Logo from "../../assets/login/Logotanyya.png";
import { useForm, Controller } from "react-hook-form";
import OtpInput from "react-otp-input";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useStore from "../../store";
import { Services } from "../../Services";
import { BiHide } from "react-icons/bi";
import { GrView } from "react-icons/gr";
import { ToastError } from "../../components/utils/ToastMessages";
import { ToastSuccess } from "../../components/utils/ToastMessages";
import { Permissions } from "../../components/utils/Pernissions";
import { isMobile } from "react-device-detect";
import Modal from '../../components/utils/warning';
const Login = () => {
  const [type, setType] = useState("Password");
  const [showOtp, setShowOtp] = useState(false);
  const [Otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const Navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { isLoginMobile, setIsLoginMobile } = useStore();

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        setIsLoginMobile(true);
      }, 1500);
    }
  }, []);


  useEffect(() => {
    const token = localStorage.getItem("token");
    const accountType = localStorage.getItem("AccountType");
    const permissionAccount = localStorage.getItem("permissionAccount");
    if (token) {
      getSubscription(token, accountType);
      getAccountType(token, accountType, permissionAccount);

      // Navigate("/Home");
    }
  }, []);

  const handleLogin = (data) => {
    console.log(type, showOtp);
    if (type === "OTP" && showOtp) {
      verifyOtp(data);
    } else if (type === "OTP") {
      getOtp(data);
    } else {
      verifyPassword(data);
    }
    console.log(data);
  };

  const handleResend = (data) => {
    if (type === "OTP" && showOtp) {
      getOtp(data);
    }
  };

  const getOtp = (data) => {

    setResendDisabled(true);
    const body = JSON.stringify(data);
    Services.loginOtp("POST", body).then((res) => {
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        setShowOtp(true);
        startResendTimer();
        setTimer(30);
      }
    }).catch((err) => {
      console.log(err);
    })
  };

  const startResendTimer = () => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setResendDisabled(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  //-------- //
  const verifyOtp = async (data) => {
    const body = JSON.stringify(data);
    Services.userlogin("POST", body).then((res) => {
      if (res.Status === 1) {
        localStorage.setItem("token", res?.Token);
        localStorage.setItem("AccountType", res?.accountType);
        localStorage.setItem("userId", res?.userId);
        localStorage.setItem("adminId", res?.adminId);
        localStorage.setItem("isNew", res?.isNew);
        localStorage.setItem("isFreeTrailActive", res?.isFreeTrailActive);
        localStorage.setItem("permissionAccount", res?.permissionAccountType);
        getcategories(
          res?.Token,
          res?.accountType,
          res?.adminId,
          res?.permissionAccountType
        );
      }
    })
      .catch((err) => {
        console.log(err);
      })
  };
  //------- //

  const verifyPassword = async (data) => {
    const body = JSON.stringify(data);
    Services.userlogin("POST", body).then((res) => {

      if (res.Status === 1) {
        localStorage.setItem("token", res?.Token);
        localStorage.setItem("AccountType", res?.accountType);
        localStorage.setItem("userId", res?.userId);
        localStorage.setItem("adminId", res?.adminId);
        localStorage.setItem("isNew", res?.isNew);
        localStorage.setItem("isFreeTrailActive", res?.isFreeTrailActive);
        localStorage.setItem("permissionAccount", res?.permissionAccountType);
        ToastSuccess(res.Message);
        // getAccountType(res?.Token);
        getcategories(
          res?.Token,
          res?.accountType,
          res?.adminId,
          res?.permissionAccountType
        );

      }
    }).catch((err) => {
      console.log(err);
    })
  };


  const getSubscription = (tokenkey, accountType) => {
    const token = {
      Authorization: `token ${tokenkey}`,
    };

    Services.SubscriptionStatus("GET", null, token).then((res) => {
      if (res.Status === 1) {
        Navigate(Permissions(accountType)[0].menuLink);
      }
      localStorage.setItem("paymentStatus", res?.paymentStatus);
    }).catch((err) => {
      console.error(err);
    })
  };

  const getcategories = (tokenkey, accountType, adminId, permissionAccount) => {
    const token = {
      Authorization: `token ${tokenkey}`,
    };
    let type = accountType;
    Services.getUser("GET", null, token)
      .then((res) => {
        if (res.Status === 1) {
          localStorage.setItem("userobject", JSON.stringify(res?.user));
          localStorage.setItem("userActive", res.user.isActive);
          localStorage.setItem("BusinessName", res.user.businessName);
          console.log(type);
          if (type == 6) {
            if (permissionAccount == 1) {
              type = 7;
            } else if (permissionAccount == 2) {
              type = 8;
            }
            getAccountType(tokenkey, type, permissionAccount);
          }
          if (type == 4 || type == 6) {
            if (adminId == 0) {
              Navigate("/user");
            } else {
              Navigate(Permissions(type)[0].menuLink);
            }
          } else {
            Navigate(Permissions(type)[0].menuLink);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAccountType = async (tokenkey, accountType) => {
    const token = {
      Authorization: `token ${tokenkey}`,
    };
    let type = accountType;
    Services.AccountTyes("GET", null, token).then((res) => {
      if (res.Status === 1) {
        localStorage.setItem("AdminAccountType", res?.adminAccounType);
        if (type == 6) {
          if (res?.adminAccounType == 0) {
            Navigate("/user");
          } else {
            Navigate(Permissions(type)[0].menuLink);
          }
        }
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const switchLoginType = () => {
    setType(type === "OTP" ? "Password" : "OTP");
    setShowOtp(false);

    // setValue("mobileNumber", "");
    setValue("Otp", "");
    setValue("password", "");
  };

  return (
    <>
      {isLoginMobile && <Modal />}
      <div className={LoginClasses["login"]}>
        <div className={LoginClasses["model_image"]}>
          <img
            className={LoginClasses["Tanyya_image"]}
            src={Tanyya}
            alt="Tanyya image"
          />
          <div className={LoginClasses["hero_mask"]}>
            <div className={LoginClasses["model_content"]}>
              <h1 className={LoginClasses["div-row"]}>Welcome Back!</h1>
              <p className={LoginClasses["div-col"]}>
                Traders Ground is a B2B platform owned by Tanyya Tech Solutions
                LLP. The platform is designed to simplify the entire process for
                manufacturers and distributors.
              </p>
            </div>
          </div>
        </div>
        <div className={LoginClasses["login_maindiv"]}>
          <img
            className={LoginClasses["logoText"]}
            src={Logo}
            alt="Logo Text"
          />
          <div className={LoginClasses["login_div"]}>
            <h1 className={LoginClasses["login_h1"]}>Login</h1>
            <form onSubmit={handleSubmit(handleLogin)}>
              <label>Mobile Number</label>
              <Controller
                name="mobileNumber"
                control={control}
                rules={{
                  required: "Mobile number is required",
                  pattern: {
                    value: !/^[6-9]\d{0,9}$/,
                    message: "Invalid mobile number format",
                  },
                }}
                render={({ field }) => (
                  <input
                    className={LoginClasses["login_input"]}
                    type="text"
                    placeholder="Mobile Number"
                    {...field}
                    disabled={showOtp}
                    maxLength={10}
                  />
                )}
              />
              {errors.mobileNumber && (
                <p className={LoginClasses.error}>
                  {errors?.mobileNumber?.message}
                </p>
              )}

              {type === "OTP" && showOtp && (
                <>
                  <label htmlFor="Otp">OTP</label>
                  <Controller
                    name="Otp"
                    control={control}
                    render={({ field }) => (
                      <OtpInput
                        value={Otp}
                        onChange={(otpValue) => {
                          setOtp(otpValue);
                          field.onChange(otpValue);
                        }}
                        numInputs={4}
                        containerStyle={LoginClasses["otp-input-container"]}
                        inputStyle={LoginClasses["otp-input"]}
                        separator={
                          <span className={LoginClasses["otp-separator"]}>
                            -
                          </span>
                        }
                        shouldAutoFocus={true}
                        renderInput={(props, index) => (
                          <input {...field} {...props} />
                        )}
                      />
                    )}
                  />
                  {errors.Otp && (
                    <p className={LoginClasses.error}>{errors?.Otp?.message}</p>
                  )}
                </>
              )}
              {type === "Password" && (
                <>
                  <label>Password</label>
                  <div className={LoginClasses["password-input-container"]}>
                    <div className={LoginClasses["input-with-icon"]}>
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: "Password is required",
                          minLength: {
                            value: 6,
                            message: "Invalid Password",
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <input
                              className={LoginClasses["login_input"]}
                              {...field}
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter Password"
                              minLength={6}
                              maxLength={15}
                            />
                            {showPassword ? (
                              <GrView onClick={() => setShowPassword(false)} />
                            ) : (
                              <BiHide onClick={() => setShowPassword(true)} />
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  {errors.password && (
                    <p className={LoginClasses.error}>
                      {errors?.password?.message}
                    </p>
                  )}
                  <p className={LoginClasses.resetpassword}>
                    <Link
                      className={LoginClasses.resetbtn}
                      to="/verificationcredentials"
                    >
                      {" "}
                      Forgot Password ?
                    </Link>
                  </p>
                </>
              )}
              <button type="submit" className={LoginClasses["S_btn"]}>
                {type === "OTP" && showOtp
                  ? "Verify OTP"
                  : type === "OTP"
                    ? "Get OTP"
                    : "Login"}
              </button>
              {type === "OTP" && showOtp && (
                <button
                  type="button"
                  className={LoginClasses["type"]}
                  disabled={resendDisabled}
                  onClick={() => handleSubmit(handleResend)()}
                >
                  Resend OTP {resendDisabled && `in (${timer})`}
                </button>
              )}
            </form>
            <div className={LoginClasses["or"]}>
              <span>OR</span>
            </div>
            <button
              className={LoginClasses["type"]}
              type="button"
              onClick={switchLoginType}
            >
              {type === "OTP" ? "Login With Password" : "Login With OTP"}
            </button>
          </div>
          {/* <div className={LoginClasses["signup"]}>
            <span>
              Don't have an account?
              <NavLink to="/signup">SignUp</NavLink>{" "}
            </span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Login;
