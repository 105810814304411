import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import UserNav from "./UserNav";
import UserClasses from "../../pages/Users/user.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import Search from "../../assets/homeimgs/search.png";
import { GoArrowLeft } from "react-icons/go";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";
import Image from '../../assets/noplot.png'

const AddGroupProducts = () => {
  const Navigate = useNavigate();
  const { groupId, groupName } = useParams();
  const [status, setStatus] = useState(1);
  const [productsData, setProductsData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addloading, setAddloading] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  useEffect(() => {
    getCategories(status);
  }, [status]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchItems]);

  const getCategories = async () => {
    let body = {
      isActive: 1,
      groupId: groupId

    }
    try {
      setLoading(false);
      const res = await Services.getManageProducts("POST", JSON.stringify(body), token);
      if (res.Status === 1) {
        setProductsData(res.products);
        setSearchItems(res.products);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Search Functionality
  const searchHandler = (e) => {
    setLoading(false);
    const searchValue = e.target.value.toLowerCase();
    const search = searchItems.filter((item) =>
      item.productName.toLowerCase().includes(searchValue)
    );

    // console.log(search);

    if (searchValue === "") {
      setProductsData(searchItems);
    } else {
      setProductsData(search);
    }
  };

  const handleCheckboxChange = (product) => {
    const updatedProducts = selectedProducts.includes(product)
      ? selectedProducts.filter(
        (selectedProduct) => selectedProduct.productId !== product.productId
      )
      : [...selectedProducts, product];

    setSelectedProducts(updatedProducts);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(productsData);
    }
    setSelectAll(!selectAll);
  };

  const AddProducts = async () => {
    if (selectedProducts.length === 0) {
      alert("Please select the products.");
      return;
    }
    const body = {
      groupId: groupId,
      products: selectedProducts,
    };

    try {
      setAddloading(true);
      const res = await Services.AddGroupProducts(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        Navigate(-1);
        console.log(res.Message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAddloading(false);
    }
  };

  return (
    <Layout Active={"Users & Groups"}>
      <div className={UserClasses["User_wrapper"]}>
        {loading ? (
          <div className={UserClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={UserClasses["groupproduct_container"]}>
            <div className={UserClasses["groupproduct_header"]}>
              <button
                className={UserClasses["groupproduct_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={UserClasses["groupproduct_header_h1"]}>Add Products</h1>
            </div>
            <div className={UserClasses["groupproduct_form_container"]}>
              <div className={UserClasses["groupproduct_form"]}>
                <div className={UserClasses["groupheader_select_container"]}>
                  <div className={UserClasses["groupproduct-serch-usear"]}>
                    <img
                      className={UserClasses["g_product_search"]}
                      src={Search}
                      alt="Search"
                    />
                    <input
                      type={"text"}
                      placeholder="Search using Prodct Name"
                      onChange={searchHandler}
                    />
                  </div>
                  <div className={UserClasses.selectallbtn}>
                    <input type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange} />
                    <span>Select All</span>
                  </div>
                </div>
                <div className={UserClasses.group_Product_container}>
                  {productsData ? (
                    productsData.map((product) => (
                      <div
                        key={product.productId}
                        className={UserClasses["groupproduct_checkbox_container"]}
                      >
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(product)}
                          checked={selectedProducts.some(
                            (selectedProduct) =>
                              selectedProduct.productId === product.productId
                          )}
                        />
                        <div className={UserClasses["groupproduct_info_box"]}>
                          <div
                            className={
                              UserClasses["groupproduct_image_container"]
                            }
                          >

                            {
                              product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                <img
                                  className={UserClasses["groupproduct_image"]}
                                  src={product.productImageList[0]}
                                  alt="image"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = Image;
                                  }}
                                />
                              ) : (
                                <img
                                  className={UserClasses["groupproduct_image"]}
                                  src={Image}
                                  alt="default image"
                                />
                              )
                            }
                            {/* {product.productImage !== "NA" ? (
                              <img
                                className={UserClasses["groupproduct_image"]}
                                src={product.productImage}
                                alt="image"
                              />
                            ) : (
                              <div
                                className={
                                  UserClasses["groupproduct_add_initial"]
                                }
                              >
                                {product.productName
                                  ? product.productName.charAt(0)
                                  : ""}
                              </div>
                            )} */}
                          </div>
                          <div className={UserClasses["groupproduct_info_text"]}>
                            <p id={UserClasses["groupproduct_prod_name"]}>
                              {product.productName}
                            </p>
                            <p id={UserClasses["groupproduct_stock_q"]}>
                              Stock: {product.stockQty}
                              {product.productMeasuringUnit}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p id={UserClasses["groupproduct_loading"]}>Loading...</p>
                  )}
                </div>
                <button
                  className={UserClasses["groupproduct_add_btn"]}
                  onClick={() => AddProducts()}
                  disabled={addloading}
                >
                  {addloading
                    ? "Adding..."
                    : `Add (${selectedProducts.length})`}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AddGroupProducts;
