import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const Content_4 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="contnet_4_container">
      <h1 id="contnet_4_h1">
        Frequently Asked <span id="extra">Questions</span>
      </h1>

      <div className="text_container">
        <div className="text_container_1">
          <div className="question_container">
            <button
              className={`accordion ${activeIndex === 0 ? "active" : ""}`}
              onClick={() => handleClick(0)}
            >
              <p id="content_4_p">
                {activeIndex === 0 ? (
                  <MdKeyboardArrowUp className="Content_4_icon" />
                ) : (
                  <MdKeyboardArrowDown className="Content_4_icon" />
                )}
              </p>
              <p id="content_4_p">
                Does Traders Ground support MSME businesses (Micro, Small &
                Medium Enterprises)?
              </p>
            </button>
            <div className={`panel ${activeIndex === 0 ? "show" : ""}`}>
              <p id="content_4_p2">
                Traders Ground is a B2B software developed by "Tanyya Tech
                Solutions LLP" and is suitable for all types of MSME businesses.
              </p>
            </div>
          </div>

          <div className="question_container">
            <button
              className={`accordion ${activeIndex === 1 ? "active" : ""}`}
              onClick={() => handleClick(1)}
            >
              <p id="content_4_p">
                {activeIndex === 0 ? (
                  <MdKeyboardArrowUp className="Content_4_icon" />
                ) : (
                  <MdKeyboardArrowDown className="Content_4_icon" />
                )}
              </p>
              <p id="content_4_p">
                {" "}
                What features differentiate this B2B app from its competitors?
              </p>
            </button>
            <div className={`panel ${activeIndex === 1 ? "show" : ""}`}>
              <p id="content_4_p2">
                Traders Ground offers distinct features that set it apart from
                competitors. It allows the registration of various profiles,
                including Manufacturers, Distributors, Retailers, Marketers,
                Agents, and Office Staff. Manufacturers (Sellers) can display
                their products and add Distributors/Retailers (Buyers) to their
                groups for order placements. Sellers can provide individual
                pricing to specific buyers, and the platform enables tracking of
                all orders along with updates on partial payments.
              </p>
            </div>
          </div>
        </div>

        <div className="text_container_1">
          <div className="question_container">
            <button
              className={`accordion ${activeIndex === 4 ? "active" : ""}`}
              onClick={() => handleClick(4)}
            >
              <p id="content_4_p">
                {activeIndex === 0 ? (
                  <MdKeyboardArrowUp className="Content_4_icon" />
                ) : (
                  <MdKeyboardArrowDown className="Content_4_icon" />
                )}
              </p>
              <p id="content_4_p">
                How can I create a profile for my business on the TradersGround
                platform, and what is the pricing model?{" "}
              </p>
            </button>
            <div className={`panel ${activeIndex === 4 ? "show" : ""}`}>
              <p id="content_4_p2">
                To create a profile for your business on the TradersGround
                platform, you can download the software from Google Play or the
                Apple Store and register your profile. Additionally, the
                software can be accessed from a desktop post-registration. The
                download and registration process is free for all types of
                profiles. For businesses selling products, a monthly billing
                plan is available, and the billing is determined by the number
                of users added to your business account.
              </p>
            </div>
          </div>
          <div className="question_container">
            <button
              className={`accordion ${activeIndex === 2 ? "active" : ""}`}
              onClick={() => handleClick(2)}
            >
              <p id="content_4_p">
                {activeIndex === 0 ? (
                  <MdKeyboardArrowUp className="Content_4_icon" />
                ) : (
                  <MdKeyboardArrowDown className="Content_4_icon" />
                )}
              </p>
              <p id="content_4_p">
                How does the app ensure data security, privacy, and access
                across various platforms such as Android, iOS, and Desktop?
              </p>
            </button>
            <div className={`panel ${activeIndex === 2 ? "show" : ""}`}>
              <p id="content_4_p2">
                Certainly! The software can be downloaded from Google Play and
                the Apple Store. It is hosted on AWS cloud servers, which boast
                the most up-to-date server infrastructure globally, ensuring the
                safety, security, and continuous availability of client
                websites. AWS data protection services are employed, providing
                encryption capabilities, key management, and sensitive data
                discovery to enhance data and workload security.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content_4;
