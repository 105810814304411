import React, { useEffect, useState } from "react";
import LoginClasses from "../Login/login.module.css";
import Tanyya from "../../assets/login/tanyyalogin.png";
import Logo from "../../assets/login/Logotanyya.png";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { BiRupee } from "react-icons/bi";

const Subscription = () => {
  const [subscriptiondetails, setSubscriptiondetails] = useState({});
  const Navigate = useNavigate();
  const token = {
    Authorization: `token ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    getcategories();
  }, []);

  const getcategories = async () => {

    const res = await Services.Subscription("GET", null, token).then((res) => {
      if (res.Status === 1) {
        setSubscriptiondetails(res.subscriptionTypes);
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const Subscribe = async () => {
    let body = {
      subscriptionId: subscriptiondetails.subscriptionId,
      subscriptionPlan: subscriptiondetails,
      baseUsers: subscriptiondetails.baseUsers,
      pricePerExtraUser: subscriptiondetails.pricePerExtraUser,
      monthlyPrice: subscriptiondetails.monthlyPrice,
    };
    Services.SubcriptionPlan(
      "POST",
      JSON.stringify(body),
      token
    ).then((res) => {
      if (res.Status === 1) {
        Navigate("/Conformpage");
      }
    }).catch((err) => {
      // alert("Somthing went wrong please try again later");
      console.error(err);
    })
  };

  return (
    <div className={LoginClasses["login"]}>
      <div className={LoginClasses["model_image"]}>
        <img
          className={LoginClasses["Tanyya_image"]}
          src={Tanyya}
          alt="Tanyya image"
        />
        <div className={LoginClasses["hero_mask"]}>
          <div className={LoginClasses["model_content"]}>
            <h1 className={LoginClasses["div-row"]}>Create Your Profile</h1>
            <p className={LoginClasses["div-col"]}>
              Traders Ground is a B2B platform owned by Tanyya Tech Solutions
              LLP. The platform is designed to simplify the entire process for
              manufacturers and distributors.
            </p>
          </div>
        </div>
      </div>
      <div className={LoginClasses["login_maindiv"]}>
        <img className={LoginClasses["logoText"]} src={Logo} alt="Logo Text" />
        <div className={LoginClasses["login_box_div"]}>
          <div className={LoginClasses["login_text_container"]}>
            <h2 className={LoginClasses["subscription_text"]}>
              {subscriptiondetails.subscriptionName}
            </h2>
            <h3 className={LoginClasses["subscription_plan"]}>
              {subscriptiondetails.mainTitle}
            </h3>
            <p className={LoginClasses["description"]}>
              {subscriptiondetails.description}
            </p>
          </div>
          <div className={LoginClasses["plans_container"]}>
            <div className={LoginClasses["money_box"]}>
              <div className={LoginClasses["sub_money_box"]}>
                <p className={LoginClasses["money_text"]}>
                  <BiRupee />
                  {subscriptiondetails.monthlyPrice}
                </p>
                <p className={LoginClasses["money_month_text"]}>
                  {subscriptiondetails.planDuration}
                </p>
              </div>
            </div>
            {subscriptiondetails.conditions && (
              <div className={LoginClasses["plan_details"]}>
                <p className={LoginClasses["plan_text1"]}>
                  {subscriptiondetails.conditions.title}
                </p>
                {subscriptiondetails.conditions.terms.map((term, index) => (
                  <li key={index} className={LoginClasses["plan_text2"]}>
                    {term}
                  </li>
                ))}
              </div>
            )}
          </div>
          <div className={LoginClasses["subscribe_plan_btn"]}>
            <button
              type="button"
              className={LoginClasses["sub_ctn"]}
              onClick={() => Navigate("/Home")}
            >
              Back To Home
            </button>
            <button
              type="button"
              className={LoginClasses["sub_btn"]}
              onClick={() => Subscribe()}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
