import React, { useEffect, useState } from "react";
import styles from "../../Login/Subscription/subscription.module.css";
import Tanyya from "../../../assets/login/tanyyalogin.png";
import Logo from "../../../assets/login/Logotanyya.png";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { ToastError } from "../../../components/utils/ToastMessages";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
import { ImCheckmark } from "react-icons/im";

const SubscriptionConfirmation = () => {
  const [billdetails, setBilldetails] = useState({});
  const Navigate = useNavigate();
  const token = {
    Authorization: `token ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    getcategories();
  }, []);

  const fromDate = moment(billdetails.fromDate).format("YYYY-MM-DD");
  const toDate = moment(billdetails.toDate).format("YYYY-MM-DD");

  const getcategories = async () => {
    Services.BillDetails("GET", null, token).then((res) => {
      if (res.Status === 1) {
        setBilldetails(res.BillDetails);
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const handleNavigate = () => {
    Navigate("/Home");
  };

  return (
    <div className={styles["login"]}>
      <div className={styles["model_image"]}>
        <img
          className={styles["Tanyya_image"]}
          src={Tanyya}
          alt="Tanyya image"
        />
        <div className={styles["hero_mask"]}>
          <div className={styles["model_content"]}>
            <h1 className={styles["div-row"]}>Create Your Profile</h1>
            <p className={styles["div-col"]}>
              Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>
        </div>
      </div>
      <div className={styles["login_maindiv"]}>
        <img className={styles["logoText"]} src={Logo} alt="Logo Text" />
        <div className={styles["login_box_div"]}>
          <div className={styles["login_text_container"]}>
            <div className={styles["confirm_check"]}>
              <div className={styles["check_mark"]}>
                <p className={styles["check_container"]}>
                  <ImCheckmark className={styles["tick_mark"]} />
                </p>
              </div>
            </div>
            <h2 className={styles["subscription_text"]}>
              Subscription Confirmed
            </h2>
          </div>
          <div className={styles["bill_container"]}>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>Bill Cycle</p>
              <p className={styles["bill_cycle_text"]}>
                {fromDate}
                {" - "}
                {toDate}
              </p>
            </div>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>Bill Generated Date</p>
              <p className={styles["bill_cycle_text"]}>{toDate}</p>
            </div>

            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>
                Base Users({billdetails.baseUsers})
              </p>
              <p className={styles["bill_cycle_text"]}>
                ₹{billdetails.basePrice}
              </p>
            </div>
          </div>
          <div className={styles["total_bill"]}>
            <p className={styles["total_bill_headers"]}>Estimated Bill</p>
            <p className={styles["total_bill_texts"]}>
              <BiRupee className={styles["ruppee_icon"]} />
              {billdetails.totalAmount || 0}.00
            </p>
          </div>
          <div className={styles["confirm_button"]}>
            <button
              className={styles["sub_btn"]}
              type="button"
              onClick={() => handleNavigate()}
            >
              Go to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionConfirmation;
