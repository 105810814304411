import { ToastError } from "../../components/utils/ToastMessages";

const httpcall = (url, method, body, token) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: method,
    body: body,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      ...token,
    },
  }) .then(res=>res.json()).then(data=>{
    if (data?.Status === 0 && data?.isTokenExpired === 1) {
      ToastError(data?.Message);
      localStorage.clear();
       window.location.href = "/login";
    }
      else if (data?.Status === 0) {
        ToastError(data?.Message);
        if (data?.Message === "Token expired") {
          window.location.href="/";
          localStorage.clear();
        }
      }
        // ToastError("Your  session expired!,  please  login");
        // window.location.href = "/login";
        
        // return {Status:0,Message:''}
        return data 
})
};

// -----  Distributer Routes  Shop----//
const getManufacturers = (method, body, token) => {
  return httpcall("distributor/getShopProducts", method, body, token);
};

const ManufacturerProducts = (method, body, token, userId, groupId) => {
  return httpcall(
    `distributor/getOneManufacturerProducts/${userId}/${groupId}`,
    method,
    body,
    token
  );
};

const AddToCart = (method, body, token) => {
  return httpcall("distributor/addToCart", method, body, token);
};

const getCartItems = (method, body, token) => {
  return httpcall("distributor/getCartItems", method, body, token);
};

const removeCartItems = (method, body, token) => {
  return httpcall("distributor/removeCartItems", method, body, token);
};

const increaseCartItemsQuantity = (method, body, token) => {
  return httpcall("distributor/increaseCartItemsQuantity", method, body, token);
};

//   Email contact
const CustomerSupport = (method, body) => {
  return httpcall("users/RecieveCostomerMail", method, body);
};

const getAddress = (method, body, token) => {
  return httpcall("distributor/getDeliveryAddress", method, body, token);
};

const DeleteAddress = (method, body, token, addressId) => {
  return httpcall(
    `distributor/deleteDeliveryAddress/${addressId}`,
    method,
    body,
    token
  );
};

const AddAddress = (method, body, token) => {
  return httpcall("distributor/addDeliveryAddress", method, body, token);
};

const EditAddress = (method, body, token, addressId) => {
  return httpcall(
    `distributor/editDeliveryAddress/${addressId}`,
    method,
    body,
    token
  );
};

const removeAllcartItems = (method, body, token, cartId) => {
  return httpcall(
    `distributor/removeAllcartItems/${cartId}`,
    method,
    body,
    token
  );
};

const PlaceOrder = (method, body, token) => {
  return httpcall("distributor/placeOrder", method, body, token);
};

// Agent

const AgentAddress = (method, body, token, userId) => {
  return httpcall(`agent/getAddressForAgent/${userId}`, method, body, token);
};
// MOQ  Api

const MOQ = (method, body, token) => {
  return httpcall("manufacturer/getMOQ ", method, body, token);
};
const EditMOQ = (method, body, token) => {
  return httpcall("manufacturer/updateMOQ", method, body, token);
};

export const ShopServices = {
  getManufacturers,
  ManufacturerProducts,
  AddToCart,
  getCartItems,
  removeCartItems,
  CustomerSupport,
  getAddress,
  AddAddress,
  DeleteAddress,
  EditAddress,
  PlaceOrder,
  increaseCartItemsQuantity,
  removeAllcartItems,
  AgentAddress,
  MOQ,
  EditMOQ,
};
