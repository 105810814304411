import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import UserNav from "./UserNav";
import UserClasses from "../../pages/Users/user.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { Services } from "../../Services";
import Search from "../../assets/homeimgs/search.png";
import { GoArrowLeft } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { InfinitySpin } from "react-loader-spinner";
import { override } from "../Home/helper";

const CreateGroup = () => {
  const { groupId, groupName } = useParams();
  const [usersData, setUsersData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [step, setStep] = useState(1);
  const [uid, setUid] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTearm, setSearchTearm] = useState("");
  const [searchType, setSearchType] = useState("UID");
  const [inputGroupName, setInputGroupName] = useState(groupName || "");
  const [disableGroupNameInput, setDisableGroupNameInput] = useState(
    !!groupName
  );
  const Navigate = useNavigate();
  const [addLoading, setAddLoading] = useState(false);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  useEffect(() => {
    getCategories();

    if (groupName) {
      setDisableGroupNameInput(true);
      setInputGroupName(groupName);
    }
  }, [groupName]);

  const getCategories = async () => {
    try {
      setLoading(false);
      const res = await Services.getOwnUsers("GET", null, token);

      if (res.Status === 1) {
        setUsersData(res.ownUsers);
      }
    } catch (err) {

      console.error(err);
    }
  };

  const handleCheckboxChange = (user) => {
    const updatedUsers = selectedUsers.includes(user)
      ? selectedUsers.filter(
        (selectedUser) => selectedUser.userId !== user.userId
      )
      : [...selectedUsers, user];

    setSelectedUsers(updatedUsers);
  };

  const handleRemoveUser = (user) => {
    const updatedUsers = selectedUsers.filter(
      (selectedUser) => selectedUser.userId !== user.userId
    );
    setSelectedUsers(updatedUsers);
  };

  const hasNext = () => {
    if (selectedUsers.length === 0) {
      alert("Please select  the users to create group");
      return;
    } else {
      setStep(step + 1);
    }
  };

  const CreateGroup = async () => {
    if (selectedUsers.length === 0) {
      ToastError("Please select  the users to create group");
      return;
    } else if (inputGroupName === "") {
      ToastError("Please enter a group name");
      return;
    }

    let body;
    if (groupId) {
      body = {
        groupId: groupId,
        groupName: inputGroupName,
        users: selectedUsers,
      };
    } else {
      body = {
        groupName: inputGroupName,
        users: selectedUsers,
      };
    }
    try {
      setAddLoading(true);
      const res = await Services.createGroup(
        "POST",
        JSON.stringify(body),
        token
      );

      if (res.Status === 1) {
        ToastSuccess(res.Message);
        console.log(res.Message);
        Navigate("/Users");
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    } finally {
      setAddLoading(false);
    }
  };

  useEffect(() => {
    let getUID;
    if (uid && uid.length >= 7 || searchType === "NAME") {
      setLoading(true);
      getUID = setTimeout(() => {
        let body = {
          search_term: uid,
          type: searchType,
        };
        setLoading(true);
        Services.searchOwnUers("POST", JSON.stringify(body), token)
          .then((res) => {
            setLoading(false);
            if (res.Status === 1) {
              setUsersData(res.users);
            }
          })
          .catch((err) => {
            alert(
              "Something went wrong while fetching user details. Please try again."
            );
            setLoading(false);
            console.error(err);
          });
      }, 1000);
    } else {
      setLoading(false);
      // setUsersData([]);
      getCategories()
    }
    return () => clearTimeout(getUID);
  }, [uid]);

  const getBackgroundColor = (index) => {
    const colors = ["#D7FFB1", "#FFD7D4", "#C9EFFF", "#FEEBC3"];
    return colors[index % colors.length];
  };
  return (
    <Layout Active={"Users & Groups"}>
      <div className={UserClasses["User_wrapper"]}>
        <div className={UserClasses["Add_user_container"]}>
          <div className={UserClasses["Add__user_header"]}>
            <button
              className={UserClasses["Add_back_btn"]}
              type="button"
              onClick={() => {
                if (step === 1) {
                  Navigate(-1);
                } else {
                  setStep(step - 1);
                }
              }}
            >
              <GoArrowLeft />
            </button>
            <h1 id={UserClasses["Add_user_header_h1"]}>{step === 1 ? "Add Users" : "Create Group"}</h1>
          </div>
          <div className={UserClasses["Add_users_form_container"]}>
            <div className={UserClasses["Add_users_form"]}>
              {step === 1 && (
                <>
                  <div className={UserClasses["Add-serch-usear"]}>
                    <select
                      className={UserClasses["view_search"]}
                      value={searchType}
                      onChange={(event) => setSearchType(event.target.value)}
                    >
                      <option value={"UID"}>UID</option>
                      <option value={"NAME"}> NAME</option>
                      {/* <option value={"COMPANY"}>COMPANY</option> */}
                    </select>
                    <input
                      type={"text"}
                      value={uid}
                      placeholder={`Search by ${searchType}`}
                      onChange={(evenet) => setUid(evenet.target.value)}
                    />
                  </div>
                  <div className={UserClasses["users_select_c"]}>
                    {loading ? (
                      <div className={UserClasses["loader"]}>
                        <InfinitySpin
                          color={"#20a0ff"}
                          loading={true}
                          css={override}
                          width={150}
                          height={150}
                          size={150}
                        />
                      </div>

                    ) : usersData ? (
                      usersData.map((user, index) => (
                        <div className={UserClasses["user_add_container"]}>
                          <div
                            key={user.userId}
                            className={UserClasses["user_checkbox_container"]}
                          >
                            <input
                              type="checkbox"
                              onChange={() => handleCheckboxChange(user)}
                              checked={selectedUsers.some(
                                (selectedUser) =>
                                  selectedUser.userId === user.userId
                              )}
                            />
                            <div className={UserClasses["Add_users_info_box"]}>
                              <div className={UserClasses["Add_image_container"]}>
                                {user.Photo !== "NA" ? (
                                  <img
                                    className={UserClasses["Add_user_image"]}
                                    src={user.Photo}
                                    alt="image"
                                  />
                                ) : (
                                  <div className={UserClasses["Add_user_initial"]} style={{ backgroundColor: getBackgroundColor(index) }}>
                                    {user.name ? user.name.charAt(0) : ""}
                                  </div>
                                )}
                              </div>
                              <div className={UserClasses["Add_users_info_text"]}>
                                <p id={UserClasses["Add_companytype"]}>
                                  {user.name}
                                </p>
                                <p id={UserClasses["Add_companytype"]}>
                                  {(user.accountType !== 4 && user.accountType !== 6) && user.businessName}
                                </p>
                                <p id={UserClasses["Add_number"]}>
                                  {user.mobileNumber}
                                </p>
                                <p id={UserClasses["Add_a_type"]}>
                                  {user.accountType === 1
                                    ? "Manufacturer "
                                    : user.accountType === 2
                                      ? "Distributor "
                                      : user.accountType === 3
                                        ? "Retailer"
                                        : user.accountType === 4
                                          ? "Marketing Specialist"
                                          : user.accountType === 5
                                            ? "Agents"
                                            : user.accountType === 6
                                              ? "Office Team "
                                              : "null"}
                                </p>
                                <p className={UserClasses["user_address"]}>
                                  {user.address}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p id={UserClasses["users_loading"]}>Loading...</p>
                    )}
                  </div>

                  <button
                    className={UserClasses["Add_users_btn"]}
                    onClick={() => hasNext()}
                  >
                    Next
                  </button>
                </>
              )}
              {step === 2 && (
                <div className={UserClasses["group_create"]}>
                  <div className={UserClasses["create_group_submit"]}>
                    <label>Group Name</label>
                    <input
                      type="text"
                      placeholder="Group Name"
                      value={groupName}

                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[a-zA-Z0-9\s]*$/;

                        if (regex.test(inputValue)) {
                          setInputGroupName(inputValue);
                        } else {
                          alert("Special characters are not allowed");
                        }
                      }}
                      disabled={disableGroupNameInput}
                      required
                    />
                    <div className={UserClasses["create_s_users_list"]}>
                      <h4 id={UserClasses["s_count"]}>
                        {" "}
                        Users({selectedUsers.length})
                      </h4>
                      {selectedUsers.map((user) => (
                        <div
                          key={user.userId}
                          className={UserClasses["create_user_info"]}
                        >
                          <div className={UserClasses["create_users_deatails"]}>
                            <p id={UserClasses["create_c_name"]}>{user.name}</p>
                            <p id={UserClasses["create_c_name"]}> {(user.accountType !== 4 && user.accountType !== 6) && user.businessName}</p>
                            <p id={UserClasses["create_a_type"]}>
                              {user.accountType === 1
                                ? "Manufacturer "
                                : user.accountType === 2
                                  ? "Distributor "
                                  : user.accountType === 3
                                    ? "Retailer"
                                    : user.accountType === 4
                                      ? "Marketing Specialist"
                                      : user.accountType === 5
                                        ? "Agents"
                                        : user.accountType === 6
                                          ? "Office Team "
                                          : "null"}
                            </p>
                          </div>
                          <div className={UserClasses["create_remove_btn"]}>
                            <button
                              id={UserClasses["create_remove_user"]}
                              onClick={() => handleRemoveUser(user)}
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <button
                    className={UserClasses["Add_users_btn"]}
                    onClick={() => CreateGroup()}
                    disabled={addLoading}
                  >
                    {addLoading ? "CreatingGroup....." : "Done"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateGroup;
