import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import lodash from "lodash";
import HomeClasses from "../../pages/Home/home.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import dropBox from "../../assets/orderbox.png";
import { IoIosCall } from "react-icons/io";
import { MdBusinessCenter, MdDateRange, MdLocationPin } from "react-icons/md";
import { HiMiniUser } from "react-icons/hi2";
import { BiRupee, BiSolidCreditCardFront } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RotatingLines, Triangle } from "react-loader-spinner";
import { override } from "./helper";
import Moment from "react-moment";
import { AiFillEdit } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { CiDiscount1 } from "react-icons/ci";
import { LuDownload } from "react-icons/lu";
import { BsBorderStyle } from "react-icons/bs";
import { FaRupeeSign } from "react-icons/fa";
import Image from '../../assets/noplot.png'

const Orderdetails = () => {
  const { orderId } = useParams();
  const Navigate = useNavigate();
  const [orderdetails, setOrderdetails] = useState({});
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [paymentUpdateModel, setPaymentUpdateModel] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [receivedPayement, setReceivedPayement] = useState();
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentError, setPaymentError] = useState("");
  const [loading, setLoading] = useState(true);
  const [orderdby, setOrderedby] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [priviousPayments, setPriviousPayments] = useState([]);
  const [productqty, setProductqty] = useState();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 6
  );
  const Permission = addProduct.length > 0 ? addProduct[0].status : 1;
  const paymentPermission = addPermissions.filter(
    (item) => item.permissionId === 14
  );
  const PayPermission =
    paymentPermission.length > 0 ? paymentPermission[0].status : 1;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    OrderDetails(orderId);
  }, [orderId]);

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const res = await Services.OfficePermissions("GET", null, token);
        if (res.Status === 1) {
          localStorage.setItem("permissions", JSON.stringify(res.permissions));
        }
      } catch (err) {
        console.error(err);
      }
    };
    getPermissions();
  }, []);

  const OrderDetails = async (orderId) => {
    try {
      setLoading(false);
      const res = await Services.getOrderdetails("GET", null, token, orderId);
      if (res.Status == 1) {
        setOrderdetails({ ...res.orderDetails });
        setAllProducts(lodash.cloneDeep(res.orderDetails.products));
        setProducts(res.orderDetails.products);
        setOrderedby(res.orderedBy);
        setPriviousPayments(res.previousPendingPayments);
      }
    } catch (err) {
      // alert("Something went wrong please try again later");
      console.error(err);
    }
  };
  const handleDateChange = (date) => {
    setPaymentDate(date);
    // You can add additional validation if needed
  };

  const UpdateStatus = async (event) => {
    const selectedStatus = event.target.value;
    const body = {
      suplierOrdersStatus: selectedStatus,
      orderId: orderId,
    };
    try {
      const res = await Services.updateOrderStatus(
        "PATCH",
        JSON.stringify(body),
        token
      );

      if (res.Status === 1) {
        ToastSuccess(res.Message);
        OrderDetails(orderId);
      }
    } catch (err) {
      // alert("Something went wrong please try again after sometime");
      console.error(err);
    }
  };

  const PaymentUpdate = async () => {
    const body = {
      receivedPayement: receivedPayement,
      paymentDate: paymentDate,
      orderId: orderId,
    };
    try {
      const res = await Services.updatePayment(
        "PATCH",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        OrderDetails(orderId);
        setPaymentUpdateModel(false);
        setPaymentError("");
        setPaymentDate("");
        setReceivedPayement("");
      }
    } catch (err) {
      // alert("Something went wrong please try again after sometime");
      console.error(err);
    }
  };

  const Orderapprove = async (type, reason) => {
    const body = {
      userOrdersStatus: type,
      orderId: orderId,
      rejectReason: reason,
      products: orderdetails.products,
    };

    try {
      const res = await Services.approveAndrejectOrder(
        "PATCH",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        OrderDetails(orderId);
        setShowRejectModel(false);
        Navigate(-1);
      }
    } catch (err) {
      // alert("Something went wrong please try again ofter sometime");
      console.error(err);
    }
  };
  const openPaymentModel = () => {
    setPaymentUpdateModel(true);
  };
  const closePaymentModel = () => {
    setPaymentUpdateModel(false);
  };

  const openRejectModel = () => {
    setShowRejectModel(true);
  };
  const closeRejectModel = () => {
    setShowRejectModel(false);
  };

  // Send Reaminder api

  const Sendreminder = async () => {
    let data = {
      totalPrice: orderdetails.totalPrice,
      orderId: orderdetails.orderId,
      pendingPayment: orderdetails.pendingPayment,
      userId: orderdetails.userId,
      suplierId: orderdetails.suplierId,
    };
    try {
      const response = await Services.SendReminder(
        "POST",
        JSON.stringify(data),
        token
      );
      if (response.Status === 1) {
        ToastSuccess(response.Message);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };

  const UpdateQty = async () => {
    let body = {
      orderId: orderdetails.orderId,
      products: allProducts,
    };

    try {
      const res = await Services.updateQTY("POST", JSON.stringify(body), token);
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        setShowModal(false);
        OrderDetails(orderId);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };
  // add Discount
  const finalAmount = async (data) => {
    try {
      const res = await Services.AddFinalAmount(
        "POST",
        JSON.stringify(data),
        token,
        orderdetails.orderId
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        setDiscountModal(false);
        setValue(" ");
        OrderDetails(orderId);
      }
    } catch (err) {
      // alert("Something went wrong please try again");
      console.error(err);
    }
  };

  const handleQty = (event) => {
    event.stopPropagation();
    const id = event.currentTarget.getAttribute("id");
    let products = [...allProducts];
    let selectedProductIndex = products.findIndex(
      (item) => item.productId == id
    );
    // products[selectedProductIndex].qty = event.target.value;
    // setAllProducts((prev) => [...products]);

    const inputValue = event.target.value;
    const isNumeric = /^[0-9]*$/.test(inputValue);

    if (isNumeric) {
      products[selectedProductIndex].qty = inputValue;
      setAllProducts((prev) => [...products]);
    } else {
      alert("Invalid quantity.");
    }
  };

  // const totalDiscount = products.reduce(
  //   (acc, curr) => curr.price * (curr.discount / 100) + acc,
  //   0
  // );
  // console.log(totalDiscount);
  // const priceafterDiscount = orderdetails.totalPrice - totalDiscount;

  // const totalGst = products.reduce(
  //   (acc, curr) => curr.productDiscountPrice * (curr.gst / 100) + acc,
  //   0
  // );
  // console.log(totalGst);

  const InvoiceBill = () => {
    window.open(orderdetails.invoice, "_blank");
  };

  const Orderdata = (orderId) => {
    Navigate(`/orderdetails/${orderId}`);
  };


  return (
    <Layout Active={"Sales Orders"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["Add_main_container"]}>
            <div className={HomeClasses["Add_header"]}>
              <button
                className={HomeClasses["add_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={HomeClasses["add_header_h2"]}>OrderID#{orderId}</h1>
            </div>
            <div className={HomeClasses["order_d_container"]}>
              <div className={HomeClasses["order_box_container"]}>
                <div className={HomeClasses["order_s_box"]}>
                  <div className={HomeClasses["order_image_container"]}>
                    <img
                      src={dropBox}
                      alt="image"
                      className={HomeClasses["order_image"]}
                    />
                  </div>
                  <p id={HomeClasses["orders_status"]}>
                    <h5 id={HomeClasses["order_d_header"]}>
                      {orderdetails.suplierOrdersStatus === 1
                        ? " New order"
                        : orderdetails.suplierOrdersStatus === 2
                          ? "Order Approved"
                          : orderdetails.suplierOrdersStatus === 3
                            ? "Order Invoice Printed"
                            : orderdetails.suplierOrdersStatus === 4
                              ? "Order Transport"
                              : orderdetails.suplierOrdersStatus === 5
                                ? "Order Delivered"
                                : orderdetails.suplierOrdersStatus === 6
                                  ? "Order Rejected"
                                  : "Order Completed"}
                    </h5>
                    <span id={HomeClasses["order_d_date"]}>
                      <Moment
                        format="DD MMM, YYYY, hh:mm A"
                        subtract={{ hours: 5, minutes: 30 }}
                      >
                        {new Date(orderdetails.createdAt)}
                      </Moment>
                    </span>
                  </p>
                </div>
                <div className={HomeClasses["order_d_btns"]}>
                  {orderdetails.suplierOrdersStatus === 1 && (
                    <>
                      {accountType == 6 && Permission == 0 ? (
                        " "
                      ) : (
                        <>
                          <button
                            type="button"
                            className={HomeClasses["order_approve"]}
                            onClick={() => Orderapprove(2, null)}
                          >
                            Approve
                          </button>
                          <button
                            type="button"
                            className={HomeClasses["order_reject"]}
                            onClick={() => openRejectModel()}
                          >
                            Reject
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {priviousPayments.length === 0 ? (
                <></>
              ) : (
                <>
                  {orderdetails.suplierOrdersStatus === 1 && (
                    <div className={HomeClasses["p_payments"]}>
                      <h4 className={HomeClasses["p_header"]}>
                        Previous Pending Payments
                      </h4>
                      <table className={HomeClasses["P_table"]}>
                        <thead className={HomeClasses["p_payment-header"]}>
                          <tr>
                            <th>OrderId</th>
                            <th>Pending Payments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {priviousPayments.map((payment, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => Orderdata(payment.orderId)}
                              >
                                <td>{payment.orderId}</td>
                                <td>₹{payment.pendingPayment}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}

              <div className={HomeClasses["order_address"]}>
                <h2 id={HomeClasses["order_d_h2"]}>Ordered By</h2>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdby.name}{" "}({orderdby.accountType === 1
                      ? "Manufacturer"
                      : orderdby.accountType === 2
                        ? "Distributor"
                        : orderdby.accountType === 3
                          ? "Retailer"
                          : orderdby.accountType === 4
                            ? "Marketing Specialist"
                            : orderdby.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdby.mobileNumber}
                  </p>
                </p>
                {/* <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdLocationPin className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdby.address}
                  </p>
                </p> */}


              </div>
              <div className={HomeClasses["order_address"]}>
                <h2 id={HomeClasses["order_d_h2"]}>Delivery To</h2>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <HiMiniUser className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverName}{" "}({orderdetails.accountType === 1
                      ? "Manufacturer"
                      : orderdetails.accountType === 2
                        ? "Distributor"
                        : orderdetails.accountType === 3
                          ? "Retailer"
                          : orderdetails.accountType === 4
                            ? "Marketing Specialist"
                            : orderdetails.accountType === 5
                              ? "Agent"
                              : "Office Staff"})
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdBusinessCenter className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverbusinessName}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <MdLocationPin className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.deliveryAddress}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <IoIosCall className={HomeClasses["order_d_icon"]} />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.recieverMobileNumber}
                  </p>
                </p>
                <p id={HomeClasses["order_addres_text"]}>
                  <p className={HomeClasses["location_icon"]}>
                    {" "}
                    <BiSolidCreditCardFront
                      className={HomeClasses["order_d_icon"]}
                    />
                  </p>
                  <p className={HomeClasses["icon_text_order"]}>
                    {" "}
                    {orderdetails.GSTNumber}
                  </p>
                </p>
              </div>
              <div className={HomeClasses["order_products"]}>
                <h3 id={HomeClasses["order_prod_header"]}>
                  Package Items
                  {orderdetails.suplierOrdersStatus === 2 && (
                    <button
                      type="button"
                      className={HomeClasses["qty_edit"]}
                      onClick={() => setShowModal(true)}
                    >
                      Edit Items
                    </button>
                  )}
                </h3>
                {orderdetails.products &&
                  orderdetails.products.map((product, index) => {
                    // let discountPrice =
                    //   product.price * (product.discount / 100);
                    // let priceAfterdiscount = product.price - discountPrice;
                    // let Gst =
                    //   priceAfterdiscount -
                    //   priceAfterdiscount * (100 / (100 + product.gst));
                    // let Gst =
                    //   product.productDiscountPrice * (product.gst / 100);

                    // let price =
                    //   product.packageType === "Bag"
                    //     ? product.bagPrice * product.qty
                    //     : product.price;
                    return (
                      <div
                        className={HomeClasses["order_prod_container"]}
                        key={index}
                      >
                        <div className={HomeClasses["order_prod_box"]}>
                          <div
                            className={HomeClasses["order_prog_img_container"]}
                          >

                            {
                              product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                <img
                                  className={HomeClasses["order_prod_image"]}
                                  src={product.productImageList[0]}
                                  alt="image"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = Image;
                                  }}
                                />
                              ) : (
                                <img
                                  className={HomeClasses["order_prod_image"]}
                                  src={Image}
                                  alt="image"
                                />
                              )
                            }
                            {/* <img
                              src={product.productImage}
                              alt="image"
                              className={HomeClasses["order_prod_image"]}
                            /> */}
                          </div>
                          <div className={HomeClasses["order_prod_info"]}>
                            <h5 id={HomeClasses["order_prod_name"]}>
                              {product.productName}
                            </h5>
                            <p id={HomeClasses["order_prod_Qty"]}>
                              Qty: {product.qty}{" "}{product.productMeasuringUnit}'s
                            </p>
                            <p id={HomeClasses["order_prod_Qty"]}>
                              HSN: {product.HSNCode}
                            </p>

                          </div>
                        </div>
                        <div className={HomeClasses["order_prod_price"]}>
                          <p className={HomeClasses["order_d_price"]}>
                            <span id={HomeClasses["order_price_container"]}>
                              Price
                            </span>
                            <p id={HomeClasses["order_rupee"]}>
                              <BiRupee />
                              {product.price}
                            </p>

                            {/* <p id={HomeClasses["order_gst"]}>
                              GST({product.gst}%) = ₹{Math.round(Gst)}
                            </p>
                            <p id={HomeClasses["order_discount"]}>
                              DISCOUNT({product.discount}%) = ₹
                              {Math.round(
                                product.individualProductDiscount * product.qty
                              )}
                            </p> */}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                <div className={HomeClasses["order_total"]}>
                  <p id={HomeClasses["order_text_"]}>Order Price</p>
                  <p className={HomeClasses["order_discount"]}>
                    <p id={HomeClasses["order_price_rupee"]}>
                      <BiRupee />
                      {Number(orderdetails.totalPrice).toFixed(2)}
                    </p>


                    <p className={HomeClasses["discountprice"]}>

                      Invoice price: ₹{orderdetails.finalAmount}{" "}
                      {
                        orderdetails.suplierOrdersStatus === 2 && (<button
                          type="button"
                          className={HomeClasses["discount_bttton"]}
                          onClick={() => setDiscountModal(true)}
                        ><AiFillEdit />
                        </button>)
                      }

                    </p>
                    <p className={HomeClasses["discountprice"]}>
                      {
                        orderdetails.invoiceNumber !== "NA" && (<>Invoice Number:{" "} {orderdetails.invoiceNumber}</>)
                      }
                    </p>
                  </p>
                </div>
              </div>
              {orderdetails.suplierOrdersStatus === 6 ? (
                <></>
              ) : (
                <div className={HomeClasses["order_d_payments"]}>
                  <h3 id={HomeClasses["order_paments_header"]}>Payments</h3>
                  <div className={HomeClasses["payment_headers"]}>
                    <p id={HomeClasses["header_payments"]}>Payment Date</p>
                    <p id={HomeClasses["header_payments"]}>Recieved Payments</p>
                  </div>
                  {orderdetails.payments &&
                    orderdetails.payments.map((payment, index) => {
                      return (
                        <div
                          className={HomeClasses["order_payment_lists"]}
                          key={index}
                        >
                          <div className={HomeClasses["payment_data"]}>
                            <p id={HomeClasses["payment_item"]}>
                              <Moment
                                format="DD MMM, YYYY, hh:mm A"
                                subtract={{ hours: 5, minutes: 30 }}
                              >
                                {new Date(payment.createdAt)}
                              </Moment>
                            </p>
                            <p id={HomeClasses["payment_item"]}>
                              <BiRupee /> {payment.receivedPayement}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  {accountType == 6 && PayPermission == 0 ? (
                    ""
                  ) : (
                    <div className={HomeClasses["payment_add_btn_c"]}>
                      {orderdetails.paymentStatus === 0 &&
                        orderdetails.suplierOrdersStatus !== 1 &&
                        orderdetails.suplierOrdersStatus !== 2 && (
                          <button
                            className={HomeClasses["payment_add_btn"]}
                            type="button"
                            onClick={() => openPaymentModel()}
                          >
                            Add Payment
                          </button>
                        )}
                    </div>
                  )}
                </div>
              )}
              <div className={HomeClasses["order_update_container"]}>
                {orderdetails.suplierOrdersStatus === 6 ? (
                  <div className={HomeClasses["order_update_s"]}>
                    <h1 id={HomeClasses["order_Status"]}>Reason</h1>
                    <p id={HomeClasses["order_update_status"]}>
                      {orderdetails.rejectReason}
                    </p>
                  </div>
                ) : (
                  <>
                    <div className={HomeClasses["order_update_s"]}>
                      <h1 id={HomeClasses["order_Status"]}>Status</h1>
                      <p id={HomeClasses["order_update_status"]}>
                        {orderdetails.suplierOrdersStatus === 7 ? (
                          <p>
                            {orderdetails.suplierOrdersStatus === 1
                              ? "All new Orders"
                              : orderdetails.suplierOrdersStatus === 2
                                ? "Approved"
                                : orderdetails.suplierOrdersStatus === 3
                                  ? "Invoice Printed"
                                  : orderdetails.suplierOrdersStatus === 4
                                    ? "Transport"
                                    : orderdetails.suplierOrdersStatus === 5
                                      ? "Delivered"
                                      : orderdetails.suplierOrdersStatus === 6
                                        ? "Rejected"
                                        : "Completed"}
                          </p>
                        ) : (
                          <select
                            onChange={UpdateStatus}
                            value={orderdetails.suplierOrdersStatus}
                            disabled={
                              orderdetails.suplierOrdersStatus === 1 ||
                              orderdetails.suplierOrdersStatus === 5
                            }
                          >
                            <option
                              value={1}
                              disabled={orderdetails.suplierOrdersStatus > 1}
                            >
                              All new Orders
                            </option>
                            <option
                              value={2}
                            // disabled={orderdetails.suplierOrdersStatus > 2}
                            >
                              Approved
                            </option>
                            <option
                              value={3}
                            // disabled={orderdetails.suplierOrdersStatus > 3}
                            >
                              Invoice Printed
                            </option>
                            <option
                              value={4}
                            // disabled={orderdetails.suplierOrdersStatus > 4}
                            >
                              Transport
                            </option>
                            <option
                              value={5}
                              disabled={orderdetails.suplierOrdersStatus > 5}
                            >
                              Delivered
                            </option>
                          </select>
                        )}
                      </p>
                    </div>
                    <div className={HomeClasses["order_remainder"]}>
                      {((orderdetails.suplierOrdersStatus === 3 &&
                        orderdetails.invoice !== "NA") ||
                        (orderdetails.suplierOrdersStatus === 5 &&
                          orderdetails.invoice !== "NA")) && (
                          <button
                            type="button"
                            className={HomeClasses["order_remainder_btn"]}
                            onClick={() => InvoiceBill()}
                          >
                            <LuDownload />
                            Order Information
                          </button>
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* payment model */}
      {paymentUpdateModel && (
        <div className={HomeClasses["reject_modal"]}>
          <div className={HomeClasses["update_modal_content"]}>
            <span
              className={HomeClasses["close_modal"]}
              onClick={() => setPaymentUpdateModel(false)}
            >
              &times;
            </span>
            <h2 id={HomeClasses["resct_header"]}>Add Payment</h2>
            <div className={HomeClasses["update_input_model_container"]}>
              <div className={HomeClasses["update_payment_container1"]}>
                <DatePicker
                  className={HomeClasses["update_input_model1"]}
                  selected={paymentDate}
                  onChange={handleDateChange}
                  placeholderText="yyyy-MM-dd"
                  dateFormat="yyyy-MM-dd"
                  // maxDate={new Date()}
                  autoFocus
                />
                <MdDateRange className={HomeClasses["update_d_icon"]} />
              </div>
              <div className={HomeClasses["update_payment_container1"]}>
                <input
                  className={HomeClasses["update_input_model2"]}
                  type="text"
                  value={receivedPayement}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const isValidInput =
                      /^\d*\.?\d{0,2}$/g.test(inputValue)

                    if (isValidInput) {
                      setReceivedPayement(inputValue);
                      setPaymentError("");
                    } else {
                      setPaymentError("Only digits will allowed with two decimals.");
                    }
                  }}
                  placeholder="Enter payment"
                  autoFocus
                />
                <BiRupee className={HomeClasses["update_r_icon"]} />
                {paymentError && (
                  <p className={HomeClasses["error_message"]}>{paymentError}</p>
                )}
              </div>
            </div>
            <button
              type="button"
              className={HomeClasses["Update_P_model_btn"]}
              onClick={() => {
                PaymentUpdate();
              }}
            >
              AddPayment
            </button>
          </div>
        </div>
      )}
      {showRejectModel && (
        <div className={HomeClasses["reject_modal"]}>
          <div className={HomeClasses["reject_modal_content"]}>
            <h2 id={HomeClasses["resct_header"]}>Reject</h2>
            <p id={HomeClasses["reject_text"]}>
              Are you sure you want reject Order {orderId}
            </p>
            <input
              className={HomeClasses["reject_input_modal"]}
              type="text"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              placeholder="Description"
              autoFocus
            />
            <div className={HomeClasses["reject_model_buttons"]}>
              <button
                type="button"
                className={HomeClasses["reject_model_ctn"]}
                onClick={() => closeRejectModel()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={HomeClasses["reject_model_btn"]}
                disabled={!rejectReason}
                onClick={() => {
                  Orderapprove(6, rejectReason);
                  setShowRejectModel(false);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* upadte Qty */}
      {showModal && (
        <div className={HomeClasses["reject_modal"]}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(false);
              // setAllProducts(products);

              setAllProducts(lodash.cloneDeep(products));
              console.log(111);
            }}
            className={HomeClasses["overlaye"]}
          ></div>
          <div className={HomeClasses["update_modal_content"]}>
            <h2 id={HomeClasses["resct_header"]}>Updating Product Quantity</h2>
            <div className={HomeClasses["qty_model_container"]}>
              {allProducts.map((product, index) => {
                return (
                  <div
                    className={HomeClasses["order_prod_container"]}
                    key={index}
                  >
                    <div className={HomeClasses["order_prod_box"]}>
                      <div className={HomeClasses["order_prog_img_container"]}>
                        {
                          product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                            <img
                              className={HomeClasses["order_prod_image"]}
                              src={product.productImageList[0]}
                              alt="image"
                              onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = Image;
                              }}
                            />
                          ) : (
                            <img
                              className={HomeClasses["order_prod_image"]}
                              src={Image}
                              alt="image"
                            />
                          )
                        }

                      </div>
                      <div className={HomeClasses["order_prod_info"]}>
                        <h5 id={HomeClasses["order_prod_name"]}>
                          {product.productName}
                        </h5>
                        <p id={HomeClasses["order_prod_Qty"]}>
                          Qty:{" "}
                          <input
                            className={HomeClasses["qty_input"]}
                            value={product.qty}
                            id={product.productId}
                            type="text"
                            min={1}
                            maxLength={5}
                            onChange={handleQty}
                            placeholder="qty"
                          />
                        </p>
                      </div>
                    </div>
                    <div className={HomeClasses["order_prod_price"]}>
                      <p className={HomeClasses["order_d_price"]}>
                        <span id={HomeClasses["order_price_container"]}>
                          Price
                        </span>
                        <p id={HomeClasses["order_rupee"]}>
                          <BiRupee />
                          {product.price}
                        </p>
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className={HomeClasses["banner_form_btns"]}>
                <button
                  type="submit"
                  className={HomeClasses["banner_e_btn"]}
                  onClick={() => UpdateQty()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add Discount */}
      {discountModal && (
        <div className={HomeClasses["reject_modal"]}>
          <div
            onClick={() => setDiscountModal(false)}
            className={HomeClasses["overlaye"]}
          ></div>
          <div className={HomeClasses["update_modal_content"]}>
            <h2 id={HomeClasses["resct_header"]}>Add Invoice Details</h2>
            <div className={HomeClasses["update_input_model_container"]}>
              <form
                onSubmit={handleSubmit(finalAmount)}
                className={HomeClasses["banner_form"]}
              >
                <div className={HomeClasses["order_total"]}>
                  <p id={HomeClasses["order_text_"]}>Order Price</p>
                  <p className={HomeClasses["order_discount"]}>
                    <p id={HomeClasses["order_price_rupee"]}>
                      <BiRupee />
                      {Number(orderdetails.totalPrice).toFixed(2)}
                    </p>
                  </p>
                </div>
                <div className={HomeClasses["banner_input"]}>
                  <input
                    type="text"
                    placeholder="Invoice Number"
                    {...register("invoiceNumber", {
                      required: 'invoiceNumber is required!',
                    })} />
                  {errors.invoiceNumber && (
                    <p className={HomeClasses["errors"]}>
                      {errors.invoiceNumber.message}
                    </p>
                  )}
                </div>
                <div className={HomeClasses["banner_input"]}>
                  <input
                    type="text"
                    placeholder="Add Invoice price"
                    {...register("finalAmount", {
                      required: 'finalAmount is required!',
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/,
                        message: "Please enter only digits with two decimal only.",
                      },
                      validate: (value) =>
                        parseInt(value) >= 0 ||
                        "Please enter a non-negative value.",
                    })}
                    min={1} />
                  {errors.finalAmount && (
                    <p className={HomeClasses["errors"]}>
                      {errors.finalAmount.message}
                    </p>
                  )}
                </div>

                <div className={HomeClasses["banner_form_btns"]}>
                  <button type="submit" className={HomeClasses["banner_e_btn"]}>
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Orderdetails;
