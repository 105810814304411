import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { GoArrowLeft } from "react-icons/go";
import UserClasses from "../../pages/Users/user.module.css";
import Layout from "../../components/Layout/layout";
import Stocklogo from "../../assets/homeimgs/p1.png";
import weightlogo from "../../assets/homeimgs/p2.png";
import UserNav from "./UserNav";
import { BiRupee } from "react-icons/bi";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";
import Slider from "react-slick";
import Image from '../../assets/noplot.png'

const GroupproductDetails = () => {
  const { groupId, productId } = useParams();

  const [productDetails, setProductDetails] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [priceId, setPriceId] = useState();
  const [showEditModel, setShowEditModel] = useState(false);
  const [productprice, setProductPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} ${UserClasses.customArrow}`} onClick={onClick}>
      </div>
    );
  };

  // Custom arrow component for previous button
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} ${UserClasses.customArrow}`} onClick={onClick}>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };




  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    getProductView(groupId, productId);
  }, [groupId, productId]);

  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const deleteProduct = addPermissions.filter(
    (item) => item.permissionId === 3
  );
  const pricePermission = addPermissions.filter(
    (item) => item.permissionId === 4
  );
  const Permission = deleteProduct.length > 0 ? deleteProduct[0].status : 1;
  const PermissionStatus =
    pricePermission.length > 0 ? pricePermission[0].status : 1;

  const getProductView = async (groupId, productId) => {
    try {
      setLoading(true);
      const res = await Services.getSingleGroupProduct(
        "GET",
        null,
        token,
        groupId,
        productId
      );

      if (res.Status === 1) {
        setProductDetails(res.groupProduct);
        setPriceId(res.groupProduct.productPricesId);
        setProductPrice(res.groupProduct.price);
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const editProduct = async (priceId) => {
    const body = {
      price: productprice,
    };

    try {
      const res = await Services.updatePrice(
        "PATCH",
        JSON.stringify(body),
        token,
        priceId
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        setShowEditModel(false);
        getProductView(groupId, productId);
      }
    } catch (err) {
      // alert("Something went wrong Please try again");
      console.log(err);
    }
  };

  const handleEditClick = () => {
    setShowEditModel(true);
  };

  const handleEditCancel = () => {
    setShowEditModel(false);
  };

  const delete_product = (productId) => {
    const body = { groupId: groupId };
    Services.deleteProduct("DELETE", JSON.stringify(body), token, productId)
      .then((res) => {
        if (res.Status === 1) {
          ToastSuccess(res.Message);
          setShowDeleteModal(false);
          Navigate(-1);
        }
      })
      .catch((err) => {
        // alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelClick = () => {
    setShowDeleteModal(false);
  };

  const handlePriceChange = (event) => {
    const inputVal = event.target.value.replace(/^(\d*\.\d{0,2})\d*$/, '$1');
    setProductPrice(inputVal);
  };

  return (
    <Layout Active={"Home"}>
      <div className={UserClasses["User_wrapper"]}>
        {loading ? (
          <div className={UserClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={UserClasses["user_d_container"]}>
            <div className={UserClasses["user_d_header"]}>
              <button
                className={UserClasses["user_d_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={UserClasses["user_d_header_h1"]}> Product Details</h1>
            </div>
            <div className={UserClasses["product_container"]}>
              <div className={UserClasses["product_container_2"]}>
                <div className={UserClasses["product_container_header"]}>
                  <div className={UserClasses["prod_image_container"]}>
                    <Slider {...settings}>
                      {productDetails.productImageList && productDetails.productImageList.length > 0 ? (
                        productDetails.productImageList.map((item, index) => (
                          <div key={index} className={UserClasses["prod_image"]}>
                            <img
                              key={index}
                              className={UserClasses["prod_img"]}
                              src={item}
                              alt="image"
                              onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = Image;
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <div className={UserClasses["prod_image"]}>
                          <img
                            className={UserClasses["prod_img"]}
                            src={Image}
                            alt="default image"
                          />
                        </div>
                      )}
                    </Slider>
                  </div>
                </div>
                <div className={UserClasses["product_info"]}>
                  <p id={UserClasses["product_text_1"]}>
                    {productDetails.productName}
                  </p>
                  {productDetails.productBagWeightInKgs !== 0 &&
                    (
                      <p className={UserClasses["product_qty"]}>
                        {productDetails.productBagWeightInKgs}
                        KG'S/
                        {productDetails.productMeasuringUnit}
                      </p>
                    )}
                  <p id={UserClasses["product_text_3"]}>
                    {productDetails.status === 1 ? "Instock" : "OutofStock"}
                  </p>
                  <p id={UserClasses["product_price_box"]}>
                    <BiRupee />
                    {productDetails.price}/{productDetails.productPriceUnit}
                  </p>
                  {/* <p id={UserClasses["product_gst"]}>
                    GST({productDetails.gst}%)
                  </p> */}
                  <p id={UserClasses["product_text_4"]}>
                    <img
                      className={UserClasses["prod_details_logo"]}
                      src={weightlogo}
                      alt="Weight Logo"
                    />
                    <span id={UserClasses["prod_details_text"]}>
                      <p id={UserClasses["prod_mesure_p"]}>Measuring Units</p>
                      <p id={UserClasses["prod_units"]}>
                        {productDetails.productPriceUnit}
                      </p>{" "}
                    </span>
                  </p>
                </div>
                <div className={UserClasses["prod_btn_conatiner"]}>
                  {accountType == 6 && PermissionStatus == 0 ? (
                    " "
                  ) : (
                    <button
                      type="button"
                      className={UserClasses["prod_action_edit"]}
                      onClick={handleEditClick}
                    >
                      Edit Price
                    </button>
                  )}
                  {accountType == 6 && Permission == 0 ? (
                    " "
                  ) : (
                    <button
                      type="button"
                      className={UserClasses["prod_action_delete"]}
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showEditModel && (
        <div className={UserClasses["delete-modal"]}>
          <div className={UserClasses["edit-modal-content"]}>
            <p id={UserClasses["edit_header"]}>Edit Price</p>
            <form className={UserClasses["price_form"]}>

              {
                productDetails.productImageList.length > 0 && productDetails.productImageList[0] !== "NA" ? (
                  <img
                    className={UserClasses["edit-modal-img"]}
                    src={productDetails.productImageList[0]}
                    alt="image"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = Image;
                    }}
                  />
                ) : (
                  <img
                    className={UserClasses["edit-modal-img"]}
                    src={Image}
                    alt="image"
                  />
                )
              }
              {/* <img
                className={UserClasses["edit-modal-img"]}
                src={productDetails.productImage}
                alt="Product Image"
              /> */}
              <p id={UserClasses["edit_text1"]}>{productDetails.productName}</p>
              <div className={UserClasses["edit_input_conatiner"]}>
                <input
                  type="text"
                  value={productprice}
                  onChange={handlePriceChange}
                  className={UserClasses["edit_input_box"]}
                  placeholder="Enter price"
                  min={1}
                />
                <BiRupee className={UserClasses["rupee_icon"]} />
                <p className={UserClasses["price_text"]}>
                  /Per {productDetails.productPriceUnit}
                </p>
              </div>
              <div className={UserClasses["edit_btns"]}>
                <button
                  type="button"
                  className={UserClasses["delete_acbtn"]}
                  onClick={handleEditCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={UserClasses["edit_adbtn"]}
                  onClick={() => editProduct(priceId)}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className={UserClasses["delete-modal"]}>
          <div className={UserClasses["delete-modal-content"]}>
            <p id={UserClasses["delete_header"]}>Delete Product</p>

            {
              productDetails.productImageList.length > 0 && productDetails.productImageList[0] !== "NA" ? (
                <img
                  className={UserClasses["delete-modal-img"]}
                  src={productDetails.productImageList[0]}
                  alt="image"
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = Image;
                  }}
                />
              ) : (
                <img
                  className={UserClasses["delete-modal-img"]}
                  src={Image}
                  alt="image"
                />
              )
            }
            {/* <img
              className={UserClasses["delete-modal-img"]}
              src={productDetails.productImage}
              alt="Product Image"
            /> */}
            <p id={UserClasses["delete_text1"]}>{productDetails.productName}</p>
            <p id={UserClasses["delete_conform_text"]}>
              Are you sure to remove this product from group
            </p>
            <div className={UserClasses["delete_btns"]}>
              <button
                type="button"
                className={UserClasses["delete_cbtn"]}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className={UserClasses["delete_dbtn"]}
                onClick={() => delete_product(productDetails.productId)}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default GroupproductDetails;
