import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShopServices } from "./ShopServicess";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import Layout from "../../components/Layout/layout";
import { GoArrowLeft } from "react-icons/go";
import ShopClass from "../Shop/shop.module.css";
import { RiDeleteBinFill, RiEditFill } from "react-icons/ri";
import emptycart from "../../assets/empty-cart.png";
import { constant } from "lodash";
import Image from '../../assets/noplot.png'

const CartPage = () => {
  const [cartDetails, setCartDetails] = useState([]);
  const [priceCartDetails, setPriceCartDetails] = useState([]);
  const [TotalBill, setTotalBill] = useState(0);
  const [BusinessName, setBusinessName] = useState("");
  const [groupname, setGroupname] = useState();
  const [manufacturerName, setManufacturerName] = useState();
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [cartID, setCartID] = useState();
  const [moq, setMoq] = useState();
  const [moqType, setMoqType] = useState();
  const [packageType, setPackageType] = useState("");
  const [manufacturerId, setManufacturerId] = useState();
  const navigate = useNavigate();
  const userId = localStorage.getItem("agentId");

  useEffect(() => {
    // if (priceCartDetails.length > 0) {
    //   console.log(priceCartDetails);
    //   const totaldiscountPrice = priceCartDetails.reduce((acc, curr) => {
    //     if (
    //       String(curr.productPriceUnit) === "Quintal" &&
    //       curr.productMeasuringUnit !== "NA" &&
    //       curr.packageMeasuringValue !== 0 &&
    //       curr.packageMeasuringUnit !== "NA"
    //       // String(curr.packageType) == "Bag"
    //     ) {
    //       return curr.bagPrice * (curr.discount / 100) * curr.qty + acc;
    //     }
    //     return curr.discountPrice * curr.qty + acc;
    //   }, 0);
    //   setTotalDiscount(totaldiscountPrice);
    // }

    if (priceCartDetails.length > 0) {
      console.log(priceCartDetails);
      const totalCartPrice = priceCartDetails.reduce((acc, curr) => {
        if (
          String(curr.productPriceUnit) === "Quintal" &&
          curr.productMeasuringUnit === "Bag"
        ) {
          return curr.bagPrice * curr.qty + acc;
        }
        return curr.price + acc;
      }, 0);
      setTotalBill(totalCartPrice);
    }
  }, [priceCartDetails]);

  useEffect(() => {
    getCartItems();
  }, []);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const accountType = localStorage.getItem("AccountType");

  const getCartItems = async () => {
    try {
      const res = await ShopServices.getCartItems("GET", null, token);
      if (res.Status === 1) {
        setCartDetails(res.cartItems);
        setPriceCartDetails(
          res.cartItems.map((item) => {
            // const Price = item.price - item.price * (item.discount / 100);
            if (
              String(item.productPriceUnit) === "Quintal" &&
              item.productMeasuringUnit === "Bag"
              // item.packageMeasuringValue !== 0 &&
              // item.packageMeasuringUnit !== "NA"
              // String(item.packageType) == "Bag"
            ) {
              const bagPrice = (item.productBagWeightInKgs * item.price) / 100;
              return {
                ...item,
                bagPrice: bagPrice,
                price: bagPrice * item.qty,
                // productDiscountPrice:
                //   (bagPrice - bagPrice * (item.discount / 100)) * item.qty,
                // individualProductDiscount:
                //   bagPrice * (item.discount / 100) * item.qty,
              };
            }
            return {
              ...item,
              price: item.price * item.qty,
              // productDiscountPrice:
              //   (item.price - item.discountPrice) * item.qty,
              // individualProductDiscount: item.discountPrice * item.qty,
            };
          })
        );
        setCartID(res.cartId);
        setMoq(res.MOQ);
        setMoqType(res.moqType);
        setManufacturerName(res.manufacturerName);
        setGroupname(res.groupname);
        setManufacturerId(res.manufacturerId);
        setBusinessName(res.manufacturerName);
      }
    } catch (err) {
      console.error(err);
    }
  };
  // console.log("&&&####0", priceCartDetails);

  // remove all cart Items

  const removeItemsFromCart = async () => {
    try {
      const res = await ShopServices.removeAllcartItems(
        "PATCH",
        null,
        token,
        cartID
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        getCartItems();
      }
    } catch (err) {
      // alert("Something went wrong, please try again");
      console.error(err);
    }
  };

  // remove cart product

  const deleteProduct = async (product) => {
    let body = { cartId: cartID, cartItemsId: product.cartItemsId };
    try {
      const res = await ShopServices.removeCartItems(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        getCartItems();
      }
    } catch (err) {
      // alert("Something went wrong, please try again");
      console.error(err);
    }
  };

  const addToCart = async (product) => {
    try {
      let calculatedQuantity = product.qty;

      if (
        String(product.productPriceUnit).toLowerCase() !==
        String(product.packageType).toLowerCase()
      ) {
        let maxAllowedQuantity =
          product.stockQty;

        maxAllowedQuantity =
          product.productPriceUnit == "Quintal" && maxAllowedQuantity;

        if (calculatedQuantity > maxAllowedQuantity) {
          alert(
            `Max allowed quantity for ${product.productName
            } should be less than are equal  ${Math.floor(maxAllowedQuantity)} stock`
          );
          return;
        }
      } else {
        if (calculatedQuantity >= product.stockQty) {
          alert(
            ` Max allowed quantity for ${product.productName} should be less than are equal  ${product.stockQty} ${product.packageType}`
          );
          return;
        }
      }
      const body = {
        cartItem: {
          manufacturerId: manufacturerId,
          productId: product.productId,
          qty: product.qty + 1,
          groupId: product.groupId,
          price: product.price,
          packageType: product.packageType,
        },
      };
      const res = await ShopServices.AddToCart(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        getCartItems();
        const existingCartItemIndex = cartDetails.findIndex(
          (item) => item.productId === product.productId
        );
        if (existingCartItemIndex !== -1) {
          const updatedCartDetails = [...cartDetails];
          updatedCartDetails[existingCartItemIndex].qty += 1;
          setCartDetails(updatedCartDetails);
        } else {
          setCartDetails((prev) => [...prev, { ...product, qty: 1 }]);
        }
      }
    } catch (err) {
      // alert("Something went wrong, please try again later");
      console.error(err);
    }
  };

  const decrementQty = async (product) => {
    try {
      if (product.qty > 0) {
        const body = {
          cartItem: {
            manufacturerId: manufacturerId,
            productId: product.productId,
            qty: product.qty - 1,
            groupId: product.groupId,
            price: product.price,
            packageType: product.packageType,
          },
        };
        const res = await ShopServices.AddToCart(
          "POST",
          JSON.stringify(body),
          token
        );
        if (res.Status === 1) {
          ToastSuccess("Quantity updated successfully");
          getCartItems();
          const updatedCartDetails = [...cartDetails];
          const existingCartItemIndex = cartDetails.findIndex(
            (item) => item.productId === product.productId
          );
          if (existingCartItemIndex !== -1) {
            updatedCartDetails[existingCartItemIndex].qty -= 1;
            setCartDetails(updatedCartDetails);
          }

          if (product.qty === 0) {
            await deleteProduct(product);
          }
        }
      } else {
        ToastError("Product quantity is already zero");
      }
    } catch (err) {
      // alert("Something went wrong, please try again later");
      console.error(err);
    }
  };

  const placeOrder = async () => {
    // CART WEIGHT IN KGS
    // const totalCartweight = cartDetails.reduce((acc, curr) => {
    //   let productWeight;
    //   productWeight = curr.productBagWeightInKgs * curr.qty;
    //   if (curr.packageType == "Tons") {
    //     productWeight = 1000 * curr.qty;
    //   } else if (curr.packageType == "Quintal") {
    //     productWeight = 100 * curr.qty;
    //   } else if (
    //     curr.packageType == "Gram" ||
    //     curr.packageType == "Milli Liter"
    //   ) {
    //     productWeight = 0.001 * curr.qty;
    //   } else if (curr.packageType == "KG" || curr.packageType == "Liter") {
    //     productWeight = curr.qty;
    //   }

    //   return acc + productWeight;
    // }, 0);

    if (localStorage.getItem("address")) {
      let updatedBussinessName =
        accountType == 4 || accountType == 5
          ? localStorage.getItem("agentName")
          : localStorage.getItem("BusinessName");

      let selectedAccount =
        accountType == 4 || accountType == 5
          ? JSON.parse(localStorage.getItem("MAaccountType"))
          : JSON.parse(localStorage.getItem("AccountType"));



      let body = {
        cartId: cartID,
        deliveryAddress: localStorage.getItem("address"),
        userName: updatedBussinessName,
        manufacturerId: manufacturerId,
        accountType: selectedAccount,
        items: priceCartDetails,
        totalPrice: TotalBill,
        // subTotal: TotalBill,
        // totalDiscount: totalDiscount,
        orderFor: userId,
        // packageType,
      };
      // console.log(totalCartweight);

      if (accountType == 1 || accountType == 2 || accountType == 3) {
        if (moqType == 1) {
          if (TotalBill - totalDiscount < moq) {
            ToastError(`Please maintain this minimum cart price ₹${moq}`);
            return;
          }
        }
        // } else if (moqType === 2) {
        //   if (totalCartweight / 100 >= moq) {
        //     alert(`Please maintain  this cart weight ${moq * 100}Kg`);
        //     return;
        //   }
        // }
      }

      try {
        const res = await ShopServices.PlaceOrder(
          "POST",
          JSON.stringify(body),
          token
        );
        if (res.Status == 1) {
          navigate("/OrderSuccessful");
          localStorage.removeItem("address");
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      ToastError("Please select address");
    }
  };

  const AddressPage = () => {
    navigate("/address");
  };

  const AgentsPage = () => {
    navigate("/agents");
  };

  return (
    <Layout Active={"Shop"}>
      <div className={ShopClass["home_wrapper"]}>
        <div className={ShopClass["Add_main_container"]}>
          <div className={ShopClass["Add_header"]}>
            <button
              className={ShopClass["add_back_btn"]}
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              <GoArrowLeft />
            </button>
            <h1 id={ShopClass["add_header_h2"]}>Order Review</h1>
          </div>
          <div className={ShopClass["cart_container"]}>
            {cartDetails.length === 0 ? (
              <div className={ShopClass["empty_products"]}>
                <div className={ShopClass["empty_image_container"]}>
                  <img
                    className={ShopClass["empty_image"]}
                    src={emptycart}
                    alt="image"
                  />
                </div>
                <p className={ShopClass["empty_portal_1"]}>
                  Your Cart is empty, Please add products and Place the order
                </p>
              </div>
            ) : (
              <>
                <div className={ShopClass["cart_address"]}>
                  <div className={ShopClass["address_boxis"]}>
                    <h4>Delivered Address</h4>
                    {localStorage.getItem("address")}
                  </div>
                  <div className={ShopClass["address_edit"]}>
                    {accountType == 4 || accountType == 5 ? (
                      <button
                        type="button"
                        onClick={() => AgentsPage()}
                        className={ShopClass["manage_address"]}
                      >
                        Add Address
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => AddressPage()}
                        className={ShopClass["manage_address"]}
                      >
                        Add Address
                      </button>
                    )}
                  </div>
                </div>

                <div className={ShopClass["cart_main_box"]}>
                  <div className={ShopClass["m_products_header_cart_items"]}>
                    <p className={ShopClass["moq_rupee"]}>
                      {groupname}-{manufacturerName}
                    </p>
                  </div>
                  {
                    (moqType != 3 && accountType != 4 && accountType != 5) && (<div className={ShopClass["m_products_header_cart_items"]}>
                      <p className={ShopClass["moq_rupee"]}>
                        Minimum Order Value: {moq}.0 INR
                      </p>
                    </div>)
                  }
                  <div className={ShopClass["cart_items_buttons"]}>
                    <h3 className={ShopClass["cart_items_count"]}>
                      Cart Items ({cartDetails.length})
                    </h3>
                    <div>
                      <button
                        type="button"
                        onClick={() => removeItemsFromCart()}
                        className={ShopClass["cart_items_remove_button"]}
                      >
                        Remove
                      </button>
                    </div>
                  </div>

                  {cartDetails.map((product, index) => {
                    let productPrice = product.price;

                    if (
                      String(product.productPriceUnit) == "Quintal" &&
                      product.productMeasuringUnit === "Bag"
                    ) {
                      productPrice =
                        (product.productBagWeightInKgs * product.price) / 100;
                    }
                    return (
                      <div className={ShopClass["cart_p_box"]} key={index}>
                        <div className={ShopClass["cart_product"]}>
                          <div className={ShopClass["cartr_p_img_container"]}>

                            {
                              product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                <img
                                  className={ShopClass["cart_p_image"]}
                                  src={product.productImageList[0]}
                                  alt="image"
                                  onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = Image;
                                  }}
                                />
                              ) : (
                                <img
                                  className={ShopClass["cart_p_image"]}
                                  src={Image}
                                  alt="image"
                                />
                              )
                            }
                            {/* <img
                              src={product.productImageList[0]}
                              alt="image"
                              className={ShopClass["cart_p_image"]}
                            /> */}
                          </div>
                          <div className={ShopClass["cart_p_info"]}>
                            <p className={ShopClass["cart_P_name"]}>
                              {product.productName}
                            </p>
                            {product.productBagWeightInKgs !== 0 &&
                              (
                                <p className={ShopClass["cart_P_qty"]}>
                                  Qty: {product.productBagWeightInKgs}
                                  KG'S /
                                  {product.productMeasuringUnit}
                                </p>
                              )}
                            <p className={ShopClass["cart_P_price"]}>
                              Price:
                              <p className={ShopClass["cart__price_text"]}>
                                ₹ {productPrice.toFixed(2)} / {product.productMeasuringUnit}
                              </p>
                            </p>
                            {/* <p className={ShopClass["cart_P_unit"]}>
                            Units: {product.packageType}
                          </p> */}
                            {/* <p id={ShopClass["order_gst"]}>
                            GST({product.gst}%) = ₹
                            {Math.round((product.price * product.gst) / 100)}
                          </p> */}
                            {/* <p id={ShopClass["order_discount"]}>
                            DISCOUNT({product.discount}%) = ₹
                            {Math.round(
                              product.price * (product.discount / 100)
                            )}
                          </p> */}
                          </div>
                        </div>
                        <div className={ShopClass["cart_btn_conatiner"]}>
                          <div className={ShopClass["cart_delete_btn"]}>
                            <button
                              type="button"
                              onClick={() =>
                                deleteProduct({
                                  cartId: cartID,
                                  cartItemsId: product.cartItemsId,
                                })
                              }
                              className={ShopClass["cart_p_d"]}
                            >
                              <RiDeleteBinFill />
                            </button>
                          </div>

                          <div className={ShopClass["shop_cart_buttons"]}>
                            <button
                              type="button"
                              className={ShopClass["decrement"]}
                              onClick={() => decrementQty(product)}
                            >
                              -
                            </button>
                            <span className={ShopClass["cart_count"]}>
                              {product.qty}
                            </span>
                            <button
                              type="button"
                              className={ShopClass["increment"]}
                              onClick={() => addToCart(product)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className={ShopClass["cart_t_bill"]}>
                    <h4 className={ShopClass["t_bill_text"]}>
                      Order Price
                    </h4>
                    <h4 className={ShopClass["t_bill_price"]}>
                      ₹{TotalBill.toFixed(2) || 0.0}
                    </h4>
                  </div>
                  {/* <div className={ShopClass["cart_t_bill"]}>
                    <h4 className={ShopClass["t_bill_text"]}>Discount Price</h4>
                    <h4 className={ShopClass["t_bill_price"]}>
                      ₹{totalDiscount || 0.0}.00
                    </h4>
                  </div> */}
                  {/* <div className={ShopClass["cart_t_bill"]}>
                    <h4 className={ShopClass["t_bill_text"]}>Order Price</h4>
                    <h4 className={ShopClass["t_bill_price"]}>
                      ₹{TotalBill - totalDiscount}.00
                    </h4>
                  </div> */}
                </div>
                <div className={ShopClass["place_order_btn"]}>
                  <button
                    type="button"
                    className={ShopClass["cart_order_btn"]}
                    onClick={placeOrder}
                  >
                    Place Order
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CartPage;
