import React, { useEffect, useState } from "react";
import styles from "../../Login/Subscription/subscription.module.css";
import Tanyya from "../../../assets/login/tanyyalogin.png";
import Logo from "../../../assets/login/Logotanyya.png";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { ToastError, ToastSuccess } from "../../../components/utils/ToastMessages";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
const ConformPage = () => {
  const [billdetails, setBilldetails] = useState({});
  const Navigate = useNavigate();
  const token = {
    Authorization: `token ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    getcategories();
  }, []);

  const fromDate = moment(billdetails.fromDate).format("YYYY-MM-DD");
  const toDate = moment(billdetails.toDate).format("YYYY-MM-DD");

  const getcategories = async () => {
    Services.BillDetails("GET", null, token).then((res) => {
      if (res.Status === 1) {
        setBilldetails(res.BillDetails);
      }
    }).catch((err) => {
      // alert("Something went wrong please try again");
      console.error(err);
    })
  };

  const calculatedBill = billdetails.extraUsers * billdetails.pricePerExtraUser;


  const subscribeConformation = async () => {
    Services.Conformation("POST", null, token).then((res) => {
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        Navigate("/subscribeConformation");
      }
    }).catch((err) => {
      // alert("Somthing went wrong please try again");
      console.error(err);
    })
  }
  return (
    <div className={styles["login"]}>
      <div className={styles["model_image"]}>
        <img
          className={styles["Tanyya_image"]}
          src={Tanyya}
          alt="Tanyya image"
        />
        <div className={styles["hero_mask"]}>
          <div className={styles["model_content"]}>
            <h1 className={styles["div-row"]}>Create Your Profile</h1>
            <p className={styles["div-col"]}>
              Traders Ground is a B2B platform owned by Tanyya Tech Solutions
              LLP. The platform is designed to simplify the entire process for
              manufacturers and distributors.
            </p>
          </div>
        </div>
      </div>
      <div className={styles["login_maindiv"]}>
        <img className={styles["logoText"]} src={Logo} alt="Logo Text" />
        <div className={styles["login_box_div"]}>
          <div className={styles["login_text_container"]}>
            <h2 className={styles["subscription_text"]}>Estimated bill</h2>
            <h4 className={styles["conform_ammount"]}>
              ₹{Math.round(billdetails.totalAmount)}
            </h4>
            <p className={styles["confirm_todate"]}>Due Date :{toDate}</p>
          </div>
          <div className={styles["bill_container"]}>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>Bill Cycle</p>
              <p className={styles["bill_cycle_text"]}>
                {fromDate}
                {" - "}
                {toDate}
              </p>
            </div>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>Bill Generated Date</p>
              <p className={styles["bill_cycle_text"]}>{toDate}</p>
            </div>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>Total Users</p>
              <p className={styles["bill_cycle_text"]}>
                {billdetails.totalUsers}
              </p>
            </div>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>
                {" . "}Base Users({billdetails.baseUsers})
              </p>
              <p className={styles["bill_cycle_text"]}>
                ₹{billdetails.basePrice}.00
              </p>
            </div>
            <div className={styles["bill_cycle"]}>
              <p className={styles["bill_cycle_header"]}>
                {" . "}Add on Users({billdetails.extraUsers}
                {" x "}₹{billdetails.pricePerExtraUser})
              </p>
              <p className={styles["bill_cycle_text"]}>{calculatedBill}</p>
            </div>
          </div>
          <div className={styles["total_bill"]}>
            <p className={styles["total_bill_header"]}>Total</p>
            <p className={styles["total_bill_text"]}>
              ₹{Math.round(billdetails.totalAmount)}
            </p>
          </div>
          <div className={styles["confirm_button"]}>
            <button
              className={styles["sub_btn"]}
              type="button"
              onClick={() => subscribeConformation()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConformPage;
