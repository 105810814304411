import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import UserNav from "./UserNav";
import UserClasses from "../../pages/Users/user.module.css";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";

const UserPermissions = () => {
  const Navigate = useNavigate();
  const { userId } = useParams();
  const [userpermissions, setUserpermissions] = useState([]);
  const [staffPermissionId, setStaffPermissionId] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  useEffect(() => {
    getUserpermissions(userId);
  }, [userId]);

  const getUserpermissions = async (userId) => {
    try {
      setLoading(false);
      const res = await Services.userPermissions("GET", null, token, userId);
      if (res.Status === 1) {
        setUserpermissions(res.staffPermissions);
        console.log(res.staffPermissions);

        if (
          res.staffPermissions.length > 0 &&
          res.staffPermissions[0].Permissions.length > 0
        ) {
          setStaffPermissionId(
            res.staffPermissions[0].Permissions[0].staffPermissionId
          );
          setStatus(res.staffPermissions[0].Permissions[0].status);
        }
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    }
  };

  const userPermission = async (staffPermissionId, status) => {
    const body = {
      staffPermissionId: staffPermissionId,
      status: status,
    };
    try {
      const res = await Services.updatePermissions(
        "PATCH",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        getUserpermissions(userId);
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    }
  };


  // function addSpacesBeforeCapitalLetters(inputString) {
  //   return inputString.replace(/([A-Z])/g, " $1");
  // }   for   spaces in inside the array


  return (
    <Layout Active={"Users & Groups"}>
      <div className={UserClasses["User_wrapper"]}>
        {loading ? (
          <div className={UserClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={UserClasses["user_d_container"]}>
            <div className={UserClasses["user_d_header"]}>
              <button
                className={UserClasses["user_d_back_btn"]}
                type="button"
                onClick={() => {
                  Navigate(-1);
                }}
              >
                <GoArrowLeft />
              </button>
              <h1 id={UserClasses["user_d_header_h1"]}>
                Office-Team Permissions
              </h1>
            </div>
            <div className={UserClasses["staff_permission_container"]}>
              <div
                className={UserClasses["permission_co"]}
              >
                {userpermissions.map((permissiongroup, index) => {
                  return (
                    <div
                      className={UserClasses["permission_container"]} key={index}
                    >

                      <div className={UserClasses["permission_types"]}>
                        <h1 id={UserClasses["permisssion_type"]}>
                          {permissiongroup.permissionsType}
                        </h1>

                        {permissiongroup.Permissions.map((permission, idx) => {

                          return (
                            <div
                              className={UserClasses["permission_cont"]}
                              key={idx}
                            >
                              <div className={UserClasses["permission_t"]}>
                                <p id={UserClasses["permissionName"]}>
                                  {permission.permissionName}
                                </p>
                              </div>
                              <div className={UserClasses["permission_btn"]}>
                                <label className={UserClasses["switch_btn"]}>
                                  <input
                                    type="checkbox"
                                    checked={permission.status === 1}
                                    onClick={() =>
                                      userPermission(
                                        permission.staffPermissionId,
                                        permission.status === 1 ? 0 : 1
                                      )
                                    }
                                  />
                                  <span
                                    className={`${UserClasses["slider"]} ${UserClasses["round"]}`}
                                  ></span>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout >
  );
};

export default UserPermissions;
