import React from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";
import "./Privacy.css";
import { Link, NavLink } from "react-router-dom";

function Privacy() {
  return (
    <div>
      <Navbar />
      <div className="privacy_container">
        <h1 className="privacy_header">Privacy Policy</h1>
        <div className="privacy_content">
          <ol className="privacy_content_ol">
            <li className="privacy_content_li">Information Collection:</li>
            <p className="privacy_content_p">
              {" "}
              We collect user information during registration and while using our services. This includes, but is not
              limited to, names, email addresses, and account preferences.
            </p>
            <li className="privacy_content_li">Use of Information:</li>
            <p className="privacy_content_p">
              The information collected is utilized to provide and enhance our services. We do not share user
              information with third parties except as required by law.
            </p>
            <li className="privacy_content_li"> Security:</li>
            <p className="privacy_content_p">
              We employ robust security measures to protect user information. However, it&#39;s important to note
              that no online data transmission is entirely secure, and while we strive to safeguard your
              information, we cannot guarantee its absolute security.
            </p>
            <li className="privacy_content_li">Cookies:</li>
            <p className="privacy_content_p">
              We may use cookies to enhance user experience. Users have the ability to manage their cookie
              preferences through their browser settings.
            </p>
            <li className="privacy_content_li">Changes to Privacy Policy:</li>
            <p className="privacy_content_p">
              Tanyya Tech Solutions LLP reserves the right to update the Privacy Policy. Users will be notified of
              any significant changes.
            </p>

            <li className="privacy_content_li">Contact Information:</li>
            <p className="privacy_content_p">
              For any questions or concerns regarding our Privacy Policy, please feel free to contact us at Tanyya
              Tech Solutions.
            </p>

            <div className="privacy_content_contact_details">
              <h4>Mail:{" "} Support@Tanyya.com</h4>
              <h4>Contact Us:{" "}<a href="https://tradersground.com/contact">https://tradersground.com/contact</a></h4>
              <address>
                <h4>Address: {" "}Address:H-No: 7-1/463/MIG, Flat 302, Nallagandla ,Hyderabad,Telangana,500019</h4>
              </address>
            </div>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
