import React from "react";

const Termsconditions = () => {
  return (
    <div className="privacy_container">
      <h1 className="privacy_header">Terms and Conditions</h1>
      <div className="privacy_content">
        <ol className="privacy_content_ol">
          <li className="privacy_content_li">Acceptance of Terms:</li>
          <p className="privacy_content_p">
            {" "}
            By accessing or using our website/Android/IOS ("Traders Ground") and
            the services provided by Tanyya Tech Solutions LLP, you agree to
            comply with and be bound by these Terms and Conditions.
          </p>
          <li className="privacy_content_li">User Registration:</li>
          <p className="privacy_content_p">
            To use certain features of our website, you may be required to
            register an account. You are responsible for maintaining the
            confidentiality of your account information.
          </p>
          <li className="privacy_content_li">Subscription and Payment:</li>
          <p className="privacy_content_p">
            Manufacturers, Distributors, Retailers, Marketers, and Agents can
            subscribe to our services monthly. Payments are to be made as per
            the subscription plan selected.
          </p>
          <li className="privacy_content_li">User Responsibilities:</li>
          <p className="privacy_content_p">
            Buyers and sellers are responsible for their interactions and
            transactions on the platform. Tanyya Tech Solutions LLP is not
            involved in monetary transactions or delivery of products.
          </p>
          <li className="privacy_content_li">Group and Product Visibility:</li>
          <p className="privacy_content_p">
            Buyers can add sellers to their group to view the seller's products
            and prices. Tanyya Tech Solutions LLP does not control or guarantee
            the accuracy of product information.
          </p>

          <li className="privacy_content_li">Order Processing:</li>
          <p className="privacy_content_p">
            We facilitate order requests between buyers and sellers. Sellers are
            responsible for the fulfilment of orders, and buyers make payments
            directly to sellers.
          </p>

          <li className="privacy_content_li">Privacy and Security:</li>
          <p className="privacy_content_p">
            We respect user privacy and do not share buyers' and sellers'
            information with third parties. Refer to our Privacy Policy for more
            details.
          </p>

          <li className="privacy_content_li">Termination:</li>
          <p className="privacy_content_p">
            Tanyya Tech Solutions LLP reserves the right to terminate or suspend
            accounts for violation of these terms or any unlawful activities.
          </p>
        </ol>
      </div>
    </div>
  );
};

export default Termsconditions;
