import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/layout'
import Style from './style.module.css';
import { GoArrowLeft } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { Services } from '../../Services';
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import Search from "../../assets/homeimgs/search.png";
import { RotatingLines } from 'react-loader-spinner';
import { override } from '../Home/helper';


const AllUsersList = () => {
    const Navigate = useNavigate();
    const [status, setStatus] = useState(parseInt(localStorage.getItem("alluserActivestatus")) || 1);
    const [allUsers, setAllUsers] = useState([]);
    const [perPage] = useState(10);
    const [page, setPage] = useState(0);
    const [searchItems, setSearchItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        let timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [searchItems]);

    useEffect(() => {
        getAllUserslist(status, page);
        return () => {
            localStorage.removeItem("alluserActivestatus");
        };
    }, [status, page]);



    const handleStatusButtonClick = (status) => {
        setStatus(status);
        getAllUserslist(status);
        localStorage.setItem("alluserActivestatus", status);
    }

    const isActiveButton = (buttonStatus) => {
        return status === buttonStatus ? Style.active : "";
    };

    const getAllUserslist = (status) => {
        Services.AllUserslist("GET", null, token, status, page).then((res) => {
            if (res.Status === 1) {
                setAllUsers(res.users);
                setSearchItems(res.users);
            }
        }).catch((err) => {
            console.log((err));
        })
    }

    const searchHandler = (e) => {
        setLoading(false);
        const searchValue = e.target.value.toLowerCase();
        const search = searchItems.filter((item) =>
            item.name.toLowerCase().includes(searchValue)
        );
        console.log(search);
        if (searchValue.length === 0) {
            setAllUsers(searchItems);
        } else {
            setAllUsers(search);
        }
    };
    return (
        <Layout Active={"All Users"}>
            <div className={Style['home_wrapper']}>
                {loading ? (
                    <div className={Style["loader"]}>
                        <RotatingLines
                            color={"#4285f4"}
                            loading={true}
                            css={override}
                            size={100}
                            width={100}
                            height={100}
                            strokeWidth={4}
                            animationDuration={0.75}
                        />
                    </div>
                ) : (
                    <div className={Style["Add_main_container"]}>
                        <div className={Style["Add_header"]}>
                            <h1 id={Style["add_header_h2"]}>All Subscribed Users</h1>
                        </div>
                        <div className={Style["Order_container"]}>
                            <div className={Style["Order_statusbtn"]}>
                                <button
                                    className={`${Style["order_statusbuttons"]
                                        } ${isActiveButton(1)}`}
                                    onClick={() => handleStatusButtonClick(1)}
                                    type="button"
                                >
                                    Manufacturers
                                </button>
                                <button
                                    className={`${Style["order_statusbuttons"]
                                        } ${isActiveButton(2)}`}
                                    onClick={() => handleStatusButtonClick(2)}
                                    type="button"
                                >
                                    Distributors
                                </button>
                                <button
                                    className={`${Style["order_statusbuttons"]
                                        } ${isActiveButton(3)}`}
                                    onClick={() => handleStatusButtonClick(3)}
                                    type="button"
                                >
                                    Retailers
                                </button>
                                <button
                                    className={`${Style["order_statusbuttons"]
                                        } ${isActiveButton(4)}`}
                                    onClick={() => handleStatusButtonClick(4)}
                                    type="button"
                                >
                                    Marketing Specialists
                                </button>
                                <button
                                    className={`${Style["order_statusbuttons"]
                                        } ${isActiveButton(5)}`}
                                    onClick={() => handleStatusButtonClick(5)}
                                    type="button"
                                >
                                    Agents
                                </button>
                            </div>
                            <div className={Style["manageprod_search"]}>
                                <img
                                    className={Style["manage_prod_search"]}
                                    src={Search}
                                    alt="Search"
                                />
                                <input
                                    type={"text"}
                                    placeholder="Search using Name"
                                    onChange={searchHandler}
                                />
                            </div>
                            <div className={Style["Order_table_container"]}>
                                <table className={Style["Order_table"]}>
                                    <thead className={Style["sticky-header"]}>
                                        <tr>
                                            <th>S No</th>
                                            <th>Name</th>
                                            <th>Business Name</th>
                                            <th>UID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            allUsers.slice(perPage * page, perPage * page + perPage)
                                                .map((user, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{user.name}</td>
                                                            <td>{user.businessName}</td>
                                                            <td>{user.UID}</td>
                                                        </tr>
                                                    )
                                                })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className={Style['pagination_container']}>
                                <ReactPaginate
                                    containerClassName={Style["pagination"]}
                                    activeClassName={Style["active-page"]}
                                    pageClassName={Style["page-item"]}
                                    onPageChange={(event) => setPage(event.selected)}
                                    breakLabel="..."
                                    pageCount={
                                        Math.ceil(allUsers.length / perPage)

                                    }
                                    previousLabel={
                                        <IconContext.Provider
                                            value={{ color: "#B8C1CC", size: "1.8rem" }}
                                        >
                                            <AiFillLeftCircle />
                                        </IconContext.Provider>
                                    }
                                    nextLabel={
                                        <IconContext.Provider
                                            value={{ color: "#B8C1CC", size: "1.8rem" }}
                                        >
                                            <AiFillRightCircle />
                                        </IconContext.Provider>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout >
    )
}

export default AllUsersList