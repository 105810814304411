import React, { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
  NavLink,
} from "react-router-dom";
import ReactPaginate from "react-paginate";
import Layout from "../../components/Layout/layout";
import { Services } from "../../Services";
import { GoArrowLeft } from "react-icons/go";
import HomeClasses from "../../pages/Home/home.module.css";
import Search from "../../assets/homeimgs/search.png";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { override } from "./helper";
import emptyproducts from "../../assets/emptyproducts.png";
import { RotatingLines } from "react-loader-spinner";
import Image from '../../assets/noplot.png'

const ViewAll = () => {
  const [params] = useSearchParams();
  const { groupID } = useParams();
  const [loading, setLoading] = useState(true);
  const [groupname, setGroupname] = useState();
  const [viewAllproducts, setViewAllproducts] = useState([]);
  const [perPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pageCount, setpageCount] = useState(1);
  const [searchItems, setSearchItems] = useState([]);

  const Navigate = useNavigate();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  let accountType = localStorage.getItem("AccountType");

  useEffect(() => {
    getViewAllproducts(groupID, page);
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
    }
  }, [groupID, page]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchItems]);

  useEffect(() => {
    if (accountType == 6) {
      getPermissions();
    }
  }, []);

  const getPermissions = async () => {
    try {
      const res = await Services.OfficePermissions("GET", null, token);
      if (res.Status === 1) {
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
      }
    } catch (err) {
      // alert("Somthing went wrong please try again");
      console.error(err);
    }
  };

  const getViewAllproducts = (groupID, page) => {
    Services.viewAllProducts("GET", null, token, groupID, page)
      .then((res) => {
        setLoading(false);
        if (res.Status === 1) {
          setViewAllproducts(res.groupProducts);
          setSearchItems(res.groupProducts);
          setGroupname(res.groupName);
        }
      })
      .catch((err) => {
        // alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const searchHandler = (e) => {
    setLoading(false);
    setpageCount(1);
    const searchValue = e.target.value.toLowerCase();
    const search = searchItems.filter((item) =>
      item.productName
        .toLowerCase()
        .includes(e.target.value.toLowerCase(searchValue))
    );

    if (searchValue === "") {
      setViewAllproducts(searchItems);
    } else setViewAllproducts(search);
  };

  const getProduct = (grouId, productId) => {
    Navigate(`/groupproductdetails/${grouId}/${productId}`);
  };
  return (
    <>
      <Layout Active={"Home"}>
        <div className={HomeClasses["home_wrapper"]}>
          {loading ? (
            <div className={HomeClasses["loader"]}>
              <RotatingLines
                color={"#4285f4"}
                loading={true}
                css={override}
                size={100}
                width={100}
                height={100}
                strokeWidth={4}
                animationDuration={0.75}
              />
            </div>
          ) : (
            <div className={HomeClasses["view_container"]}>
              <div className={HomeClasses["view_header"]}>
                <button
                  className={HomeClasses["back_btn"]}
                  type="button"
                  onClick={() => {
                    Navigate(-1);
                  }}
                >
                  <GoArrowLeft />
                </button>
                <h1 id={HomeClasses["view_header_h2"]}>{groupname}</h1>
              </div>
              <div className={HomeClasses["category-form-two"]}>
                <img className="view_search" src={Search} alt="Search" />
                <input
                  type={"text"}
                  placeholder="Search using Product Name"
                  onChange={searchHandler}
                />
              </div>
              <div className={HomeClasses["veiw_prod_container"]}>
                <div className={HomeClasses["view_product_container"]}>
                  {viewAllproducts.length === 0 ? (
                    <div className={HomeClasses["empty_products"]}>
                      <div className={HomeClasses["empty_image_container"]}>
                        <img
                          className={HomeClasses["empty_image"]}
                          src={emptyproducts}
                          alt="image"
                        />
                      </div>
                      <p className={HomeClasses["empty_portal_1"]}>
                        No Products to show, Create your first Group and add
                        products to define Price's
                      </p>
                    </div>
                  ) : (
                    viewAllproducts
                      .slice(perPage * page, perPage * page + perPage)
                      .map((product, index) => {
                        // let discountPrice =
                        //   product.price * (product.discount / 100);
                        // console.log(discountPrice);
                        // let finalPrice = product.price - discountPrice;
                        return (
                          <>
                            <NavLink
                              className={HomeClasses["view_manage_link"]}
                              to={`/groupproductdetails/${groupID}/${product.productId}`}
                              key={index}
                            >
                              <div
                                className={HomeClasses["view_product_box"]}>
                                <div
                                  className={
                                    HomeClasses["view_prod_img_container"]
                                  }
                                >
                                  {
                                    product.productImageList.length > 0 && product.productImageList[0] !== "NA" ? (
                                      <img
                                        className={HomeClasses["manage_prod_imge"]}
                                        src={product.productImageList[0]}
                                        alt="image"
                                        onError={(e) => {
                                          e.currentTarget.onerror = null;
                                          e.currentTarget.src = Image;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className={HomeClasses["manage_prod_imge"]}
                                        src={Image}
                                        alt="default image"
                                      />
                                    )
                                  }
                                </div>
                                <div className={HomeClasses["product_info_c"]}>
                                  <p id={HomeClasses["home_product_text1"]}>
                                    {product.productName}
                                  </p>
                                  {
                                    product.stockQty == 0 && product.status == 2 ? <p className={HomeClasses.outtext}>Out of Stock</p> : <>

                                      {product.productBagWeightInKgs !== 0 &&
                                        (
                                          <p id={HomeClasses["home_product_text3"]}>
                                            {product.productBagWeightInKgs}
                                            KG'S/
                                            {product.productMeasuringUnit}
                                          </p>
                                        )}
                                      <p className={HomeClasses["p_price"]}>
                                        ₹{product.price}/ {product.productPriceUnit}
                                      </p>
                                    </>}
                                </div>
                              </div>
                            </NavLink>
                          </>
                        );
                      })
                  )}
                </div>
                <div className={HomeClasses["view_pagination"]}>
                  <span id={HomeClasses["view_page_count"]}>
                    Showing{" "}
                    <span className={HomeClasses["number"]}>
                      {page * perPage + 1}
                    </span>{" "}
                    -{" "}
                    <span className={HomeClasses["number"]}>
                      {Math.min((page + 1) * perPage, viewAllproducts.length)}
                    </span>{" "}
                    transactions from{" "}
                    <span className={HomeClasses["number"]}>
                      {viewAllproducts.length}
                    </span>{" "}
                    data
                  </span>
                  <ReactPaginate
                    containerClassName={HomeClasses["pagination"]}
                    activeClassName={HomeClasses["active-page"]}
                    pageClassName={HomeClasses["page-item"]}
                    onPageChange={(event) => setPage(event.selected)}
                    breakLabel="..."
                    pageCount={Math.ceil(viewAllproducts.length / perPage)}
                    previousLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "2rem" }}
                      >
                        <AiFillLeftCircle />
                      </IconContext.Provider>
                    }
                    nextLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "2rem" }}
                      >
                        <AiFillRightCircle />
                      </IconContext.Provider>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default ViewAll;
