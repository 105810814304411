import React from "react";
import Feature1 from "../../assets/landingpage/feature1.jpeg";
import Feature2 from "../../assets/landingpage/feature2.jpeg";
import Feature3 from "../../assets/landingpage/feature3.jpeg";
import { GiCheckMark } from "react-icons/gi";

const Content_3 = () => {
  return (
    <div className="content_3_container_main">
      <h1 id="content_3_h1">
        Powerful <span id="extra">Features</span>
      </h1>

      <div className="content_3_card_main">
        <div className="content_3_card">
          <img className="feature_img" src={Feature1} alt="Indivisual Pricing" />
          <div className="content_3_para">
            <h3 style={{ textAlign: 'left' }}>Individual Pricing</h3>
            <ul style={{ listStyleType: 'none', textAlign: 'left', padding: '0', marginBottom: '20px' }}>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Create Group
              </li>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Add Buyers to Group
              </li>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Individual Price to individual Buyer
              </li>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Price only displayed to Group Members
              </li>
            </ul>
          </div>
        </div>

        <div className="content_3_card">
          <img className="feature_img" src={Feature2} alt="Orders & Payments" />
          <div className="content_3_para">
            <h3 style={{ textAlign: 'left' }}>Orders & Payments</h3>
            <ul style={{ listStyleType: 'none', textAlign: 'left', padding: '0', marginBottom: '20px' }}>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Approve & Reject
              </li>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Order Management
              </li>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Partial payment Entries
              </li>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Buyer pending payment on next order
              </li>
            </ul>
          </div>
        </div>

        <div className="content_3_card">
          <img className="feature_img" src={Feature3} alt="Sales Reports" />
          <div className="content_3_para">
            <h3 style={{ textAlign: 'left' }}>Sales Reports</h3>
            <ul style={{ listStyleType: 'none', textAlign: 'left', padding: '0', marginBottom: '20px' }}>
              <li className="content_3_card_checks" >
                <GiCheckMark className="Home_check_icon" />
                Area sales reports
              </li>
              <li className="content_3_card_checks">
                <GiCheckMark className="Home_check_icon" />
                Individual buyer sales Reports
              </li>
              <li className="content_3_card_checks" >
                <GiCheckMark className="Home_check_icon" />
                Monthly Sales reports
              </li>
              <li className="content_3_card_checks" >
                <GiCheckMark className="Home_check_icon" />
                Download Sales Reports
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content_3;
