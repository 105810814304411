import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Content_1 from "./Content_1";
import Content_2 from "./Content_2";
import Content_3 from "./Content_3";
import Content_4 from "./Content_4";
import Footer from "./Footer";
import Content_5 from "./Content_5";
import Content_7 from "./Content_7";
import Content_8 from "./Content_8";
import { isMobile } from "react-device-detect";

const TanyyaHomepage = () => {

  return (
    <div>
      <Navbar />
      <Content_1 />
      <Content_2 />
      <Content_3 />
      <Content_7 />
      <Content_4 />
      <Content_5 />
      {/* <Content_6 /> */}
      <Content_8 />
      <Footer />
    </div>
  );
};

export default TanyyaHomepage;
