import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import UserNav from "./UserNav";
import UserClasses from "../../pages/Users/user.module.css";
import { Services } from "../../Services";
import { ToastError } from "../../components/utils/ToastMessages";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import Search from "../../assets/homeimgs/search.png";
import emptygroups from "../../assets/emptygroups.png";
import { RotatingLines } from "react-loader-spinner";
import { override } from "../Home/helper";

const Ownusers = () => {
  const [users, setUsers] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState();
  const [searchTearm, setSearchTearm] = useState("");
  const [searchType, setSearchType] = useState("UID");
  const Navigate = useNavigate();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 8
  );
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchItems]);

  const getCategories = async () => {
    try {
      const res = await Services.getOwnUsers("GET", null, token);
      setLoading(false);
      if (res.Status === 1) {
        setUsers(res.ownUsers);
        setSearchItems(res.ownUsers);
      }
    } catch (err) {
      // alert("Something went wrong. Please try again.");
      console.error(err);
    }
  };

  const handleAddUers = () => {
    Navigate("/addownusers");
  };
  const handleClick = (user) => {
    localStorage.setItem('selectedUser', JSON.stringify(user));
    Navigate(`/ownuserdetails/${user.userId}`);
  };

  useEffect(() => {
    let getUID;
    if (uid && uid.length >= 7 || searchType === "NAME") {
      getUID = setTimeout(() => {
        let body = {
          search_term: uid,
          type: searchType,
        };
        Services.searchOwnUers("POST", JSON.stringify(body), token)
          .then((res) => {
            if (res.Status === 1) {
              setUsers(res.users);
            }
          })
          .catch((err) => {
            alert(
              "Something went wrong while fetching user details. Please try again."
            );

            console.error(err);
          });
      }, 2000);
    } else {
      setLoading(false);

      getCategories()
    }
    return () => clearTimeout(getUID);
  }, [uid]);

  const getBackgroundColor = (index) => {
    const colors = ["#D7FFB1", "#FFD7D4", "#C9EFFF", "#FEEBC3"];
    return colors[index % colors.length];
  };

  return (
    <div className={UserClasses["Users_container"]}>
      <p id={UserClasses["user_groups"]}>
        <p className={UserClasses.UGname}>Own Users</p>
        <p className={UserClasses.UGbtn}>
          {accountType == 6 && PermissionStatus == 0 ? (
            ""
          ) : (
            <button
              type="button"
              onClick={() => handleAddUers()}
              className={UserClasses["add_product"]}
              disabled={accountType == 6 && PermissionStatus == 0}
            >
              <FaPlus />
            </button>
          )} </p>
      </p>
      <div className={UserClasses["Add-serch-usear"]}>
        <select
          className={UserClasses["view_search"]}
          value={searchType}
          onChange={(event) => setSearchType(event.target.value)}
        >
          <option value={"UID"}>UID</option>
          <option value={"NAME"}> NAME</option>
          {/* <option value={"COMPANY"}>COMPANY</option> */}
        </select>
        <input
          type={"text"}
          value={uid}
          placeholder={`Search by ${searchType}`}
          onChange={(evenet) => setUid(evenet.target.value)}
        />
      </div>
      <h3 id={UserClasses["users_h2text"]}>
        Total Users({users.length})
      </h3>
      <div className={UserClasses["users_userdata_container"]}>
        {users.length === 0 ? (
          <div className={UserClasses["empty_products"]}>
            <div className={UserClasses["empty_image_container"]}>
              <img
                className={UserClasses["empty_image"]}
                src={emptygroups}
                alt="image"
              />
            </div>
            <p className={UserClasses["empty_portal_1"]}>
              add your buyers and team members to begin transacting!
              Use the " + " button below
            </p>
          </div>
        ) : (
          users.map((user, index) => {
            return (
              <div
                className={UserClasses["group_user_container"]}
                key={index}
                onClick={() => handleClick(user)}
              >
                <div className={UserClasses["user_image_container"]}>
                  {user.Photo !== "NA" ? (
                    <img
                      className={UserClasses["group_user_image"]}
                      src={user.Photo}
                      alt="image"
                    />
                  ) : (
                    <div
                      className={UserClasses["group_user_initial"]}
                      style={{ backgroundColor: getBackgroundColor(index) }}
                    >
                      {user.name ? user.name.charAt(0) : ""}
                    </div>
                  )}
                </div>
                <div className={UserClasses["group_user_info"]}>
                  <p className={UserClasses["group_user_name"]}>
                    {user.name}
                  </p>
                  <p className={UserClasses["group_user_name"]}>{(user.accountType !== 4 && user.accountType !== 6) && user.businessName}</p>
                  <p id={UserClasses["user_UID"]}>UID: {user.UID}</p>
                  <p id={UserClasses["group_user_number"]}>
                    {user.mobileNumber}
                  </p>
                  <p id={UserClasses["group_user_type"]}>
                    {user.accountType === 1
                      ? "Manufacturer "
                      : user.accountType === 2
                        ? "Distributor "
                        : user.accountType === 3
                          ? "Retailer"
                          : user.accountType === 4
                            ? "Marketing Specialist"
                            : user.accountType === 5
                              ? "Agents"
                              : user.accountType === 6
                                ? "Office Team "
                                : "null"}
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Ownusers;
