import React, { useEffect, useState } from "react";
import UserNav from "./UserNav";
import UserClasses from "../../pages/Users/user.module.css";
import Layout from "../../components/Layout/layout";
import { useParams, useNavigate } from "react-router-dom";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { Services } from "../../Services";
import { GoArrowLeft } from "react-icons/go";
import { IoMdSettings } from "react-icons/io";
import { IoIosCall } from "react-icons/io";
import { override } from "../Home/helper";
import { RotatingLines } from "react-loader-spinner";

const OwnUserdetails = () => {
  const { userId } = useParams();
  const [userdetails, setUserdetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const Navigate = useNavigate();
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 8
  );
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;

  // useEffect(() => {
  //   getUserDetails(userId);
  // }, [userId]);

  // const getUserDetails = async (userId) => {
  //   try {
  //     const res = await Services.ownUserDetails("GET", null, token, userId);
  //     setLoading(false);
  //     if (res.Status === 1) {
  //       setUserdetails(res.user);
  //     }
  //   } catch (err) {

  //     console.error(err);
  //   }
  // };

  useEffect(() => {

    setTimeout(() => {
      setUserdetails(JSON.parse(localStorage.getItem("selectedUser")));
      setLoading(false);
    }, 1500);

  }, []);

  const userPermissions = (userId) => {
    Navigate(`/userpermissions/${userId}`);
  };

  const deleteUser = async (userId) => {
    if (userId) {
      const body = {
        userId: userId,
        accountType: userdetails.accountType,
      };
      try {
        const res = await Services.deleteGroupUser(
          "DELETE",
          JSON.stringify(body),
          token
        );
        if (res.Status === 1) {
          ToastSuccess(res.Message);
          Navigate("/Users");
          setShowDeleteModal(false);
        }
      } catch (err) {
        // alert("Something went wrong Please try again");
        console.error(err);
      }
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelClick = () => {
    setShowDeleteModal(false);
  };

  return (
    <Layout Active={"Users & Groups"}>
      {accountType == 5 || accountType == 4 ? (
        <div className={UserClasses["Buyer_wrapper"]}>
          {loading ? (
            <div className={UserClasses["loader"]}>
              <RotatingLines
                color={"#4285f4"}
                loading={true}
                css={override}
                size={100}
                width={100}
                height={100}
                strokeWidth={4}
                animationDuration={0.75}
              />
            </div>
          ) : (
            <div className={UserClasses["user_d_container"]}>
              <div className={UserClasses["user_d_header"]}>
                <button
                  className={UserClasses["user_d_back_btn"]}
                  type="button"
                  onClick={() => {
                    Navigate(-1);
                  }}
                >
                  <GoArrowLeft />
                </button>
                <h1 id={UserClasses["user_d_header_h1"]}> User Details</h1>
              </div>
              <div className={UserClasses["user_d_main_container"]}>
                <div className={UserClasses["user_d_sub_container"]}>
                  <div className={UserClasses["user_d_header_container"]}>
                    <div className={UserClasses["user_d_image"]}>
                      {userdetails.Photo !== "NA" ? (
                        <img
                          className={UserClasses["user_d_imd"]}
                          src={userdetails.Photo}
                          alt="image"
                        />
                      ) : (
                        <div className={UserClasses["user_d_initial"]}>
                          {userdetails.name ? userdetails.name.charAt(0) : ""}
                        </div>
                      )}
                    </div>
                    <div className={UserClasses["user_d_header_text"]}>
                      <p id={UserClasses["user_d_username"]}>
                        {userdetails.name}
                      </p>
                      <p id={UserClasses["user_d_uid"]}>
                        UID {userdetails.UID}
                      </p>
                    </div>
                    {(localStorage.getItem("userId") == userdetails.userId) ? "" : (
                      <div className={UserClasses["user_d_access_btn"]}>
                        {(userdetails.accountType === 6) && (
                          <button
                            type="button"
                            className={UserClasses["user_d_settings_btn"]}
                            onClick={() => userPermissions(userdetails.userId)}
                          // disabled={accountType === 6}
                          >
                            <IoMdSettings />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className={UserClasses["user_d_i_container"]}>
                    <div className={UserClasses["user_d_info_container"]}>
                      {
                        (userdetails.accountType == 4 || userdetails.accountType == 6) ? "" :
                          <p className={UserClasses["user_d_text1"]}>
                            <span id={UserClasses["user_d_n"]}> Business Name</span>
                            {userdetails.businessName}
                          </p>
                      }

                      {
                        (userdetails.adminAccountType === 4 || userdetails.adminAccountType === 6) &&
                        <p className={UserClasses["user_d_text1"]}>
                          <span id={UserClasses["user_d_n"]}> Admin</span>
                          {userdetails.adminName}{" "}({userdetails.adminAccountType === 1 ? "Manufacturer" :
                            userdetails.adminAccountType === 2 ? "Distributor" :
                              userdetails.adminAccountType === 3 ? "Retailer" :
                                userdetails.adminAccountType === 4 ? "Marketing Specialist" :
                                  userdetails.adminAccountType === 5 ? "Agents" :
                                    userdetails.adminAccountType === 6 ? "Office Team " : null})
                        </p>
                      }

                      <p className={UserClasses["user_d_text1"]}>
                        <span id={UserClasses["user_d_n"]}>Mobile Number</span>
                        {userdetails.mobileNumber}
                        <IoIosCall className={UserClasses["user_d_call"]} />
                      </p>
                      <p className={UserClasses["user_d_text2"]}>
                        <span id={UserClasses["user_d_n"]}>Email</span>{" "}
                        {userdetails.email}
                      </p>
                      <p className={UserClasses["user_d_text3"]}>
                        <span id={UserClasses["user_d_n"]}>Address</span>
                        {userdetails.address}
                      </p>
                      {
                        (userdetails.accountType == 4 || userdetails.accountType == 6) ? "" :

                          <p className={UserClasses["user_d_text3"]}>
                            <span id={UserClasses["user_d_n"]}>GST Number</span>
                            {userdetails.GSTNumber}
                          </p>}
                      <div className={UserClasses["user_d_delete_btn"]}>
                        {accountType == 6 && PermissionStatus == 0 ? (
                          " "
                        ) : (
                          <button
                            type="button"
                            className={UserClasses["user_d_d_btn"]}
                            onClick={() => handleDeleteClick()}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={UserClasses["User_wrapper"]}>
            {loading ? (
              <div className={UserClasses["loader"]}>
                <RotatingLines
                  color={"#4285f4"}
                  loading={true}
                  css={override}
                  size={100}
                  width={100}
                  height={100}
                  strokeWidth={4}
                  animationDuration={0.75}
                />
              </div>
            ) : (
              <div className={UserClasses["user_d_container"]}>
                <div className={UserClasses["user_d_header"]}>
                  <button
                    className={UserClasses["user_d_back_btn"]}
                    type="button"
                    onClick={() => {
                      Navigate(-1);
                    }}
                  >
                    <GoArrowLeft />
                  </button>
                  <h1 id={UserClasses["user_d_header_h1"]}> User Details</h1>
                </div>
                <div className={UserClasses["user_d_main_container"]}>
                  <div className={UserClasses["user_d_sub_container"]}>
                    <div className={UserClasses["user_d_header_container"]}>
                      <div className={UserClasses["user_d_image"]}>
                        {userdetails.Photo !== "NA" ? (
                          <img
                            className={UserClasses["user_d_imd"]}
                            src={userdetails.Photo}
                            alt="image"
                          />
                        ) : (
                          <div className={UserClasses["user_d_initial"]}>
                            {userdetails.name ? userdetails.name.charAt(0) : ""}
                          </div>
                        )}
                      </div>
                      <div className={UserClasses["user_d_header_text"]}>
                        <p id={UserClasses["user_d_username"]}>
                          {userdetails.name}
                        </p>
                        <p id={UserClasses["user_d_uid"]}>
                          UID {userdetails.UID}
                        </p>
                      </div>
                      {(localStorage.getItem("userId") !== userdetails.userId && accountType == 6) ? "" : (
                        <div className={UserClasses["user_d_access_btn"]}>
                          {(userdetails.accountType === 6) && (
                            <button
                              type="button"
                              className={UserClasses["user_d_settings_btn"]}
                              onClick={() => userPermissions(userdetails.userId)}
                            // disabled={accountType === 6}
                            >
                              <IoMdSettings />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={UserClasses["user_d_info_container"]}>
                      {
                        (userdetails.accountType == 4 || userdetails.accountType == 6) ? "" :
                          <p className={UserClasses["user_d_text1"]}>
                            <span id={UserClasses["user_d_n"]}> Business Name</span>
                            {userdetails.businessName}
                          </p>
                      }
                      {
                        (userdetails.adminAccountType === 4 || userdetails.adminAccountType === 6) &&
                        <p className={UserClasses["user_d_text1"]}>
                          <span id={UserClasses["user_d_n"]}> Admin</span>
                          {userdetails.adminName}{" "}({userdetails.adminAccountType === 1 ? "Manufacturer" :
                            userdetails.adminAccountType === 2 ? "Distributor" :
                              userdetails.adminAccountType === 3 ? "Retailer" :
                                userdetails.adminAccountType === 4 ? "Marketing Specialist" :
                                  userdetails.adminAccountType === 5 ? "Agents" :
                                    userdetails.adminAccountType === 6 ? "Office Team " : null})
                        </p>
                      }

                      <p className={UserClasses["user_d_text1"]}>
                        <span id={UserClasses["user_d_n"]}>Mobile Number</span>
                        {userdetails.mobileNumber}
                        <IoIosCall className={UserClasses["user_d_call"]} />
                      </p>
                      <p className={UserClasses["user_d_text2"]}>
                        <span id={UserClasses["user_d_n"]}>Email</span>{" "}
                        {userdetails.email}
                      </p>
                      <p className={UserClasses["user_d_text3"]}>
                        <span id={UserClasses["user_d_n"]}>Address</span>
                        {userdetails.address}
                      </p>
                      {
                        (userdetails.accountType == 4 || userdetails.accountType == 6) ? "" :

                          <p className={UserClasses["user_d_text3"]}>
                            <span id={UserClasses["user_d_n"]}>GST Number</span>
                            {userdetails.GSTNumber}
                          </p>}
                    </div>
                    <div className={UserClasses["user_d_delete_btn"]}>
                      {accountType == 6 && PermissionStatus == 0 ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          className={UserClasses["user_d_d_btn"]}
                          onClick={() => handleDeleteClick()}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {showDeleteModal && (
        <div className={UserClasses["delete-modal"]}>
          <div className={UserClasses["user-modal-content"]}>
            <p id={UserClasses["delete_header"]}>Delete User</p>

            <p id={UserClasses["delete_text1"]}>{userdetails.name}</p>
            <p id={UserClasses["delete_conform_text"]}>
              Are you sure to remove this user from this group
            </p>
            <div className={UserClasses["delete_btns"]}>
              <button
                type="button"
                className={UserClasses["delete_cbtn"]}
                onClick={() => handleCancelClick()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={UserClasses["delete_dbtn"]}
                onClick={() => deleteUser(userId)}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default OwnUserdetails;
