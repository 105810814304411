import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import topnavclasses from "./topNav.module.css";
import Logo from '../../assets/tannya_new.png';
import swal from "sweetalert";
import useStore from "../../store";
import { Services } from "../../Services";
import { ToastSuccess } from "../utils/ToastMessages";
import { Permissions } from "../utils/Pernissions";
import { HiOutlineLogout } from 'react-icons/hi'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { IoIosCall } from "react-icons/io";
import { MdAccessTime } from "react-icons/md";
import { FaSmile } from "react-icons/fa";

const TopNav = ({ Active }) => {
    const { notificationCount, setNotificationCount, setNavItems, NavItems, user, setUser, userActive, setUserActive } = useStore();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [currentTime, setCurrentTime] = useState(new Date());
    let accountType = localStorage.getItem("AccountType");
    const Navigate = useNavigate();

    const [adminAccounType, setAdminAccounType] = useState();
    useEffect(() => {
        Services.AccountTyes("GET", null, token).then((res) => {
            if (res.Status === 1) {
                setAdminAccounType(res?.adminAccounType);

                const permissionAccount = localStorage.getItem("permissionAccount");
                if (accountType == 6) {
                    if (res?.adminAccounType == 0) {
                        accountType = 6;
                    } else if (res?.adminAccounType != 0) {
                        if (permissionAccount == 1) {
                            accountType = 7;
                        } else if (permissionAccount == 2) {
                            accountType = 8;
                        }
                    }
                }
                if (accountType == 4 || accountType == 6) {
                    let adminId = localStorage.getItem("adminId");
                    if (adminId == 0) {
                        setNavItems([]);
                        return;
                    }
                }
                // navitens!=
                setNavItems(Permissions(accountType));
                setUser(JSON.parse(localStorage.getItem("userobject")));
            }
        }).catch((err) => {
            console.error(err);
        })
    }, []);


    useEffect(() => {
        Services.UnreadNotifications("GET", null, token)
            .then((res) => {
                if (res.Status === 1) {
                    setNotificationCount(res.unreadNotifications);
                }
            })
            .catch((err) => {
                alert("Somthing went wrong please try again");
                console.error(err);
            });
    }, []);

    useEffect(() => {
        Services.getUser("GET", null, token).then((response) => {
            if (response.Status === 1) {
                setUserActive(response.user.isActive);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const UserActive = async (data) => {
        console.log(data)
        Services.UserActiveInActive(
            "PATCH",
            null,
            token,
            data
        ).then((res) => {
            if (res.Status === 1) {
                setUserActive(data)
                ToastSuccess(res.Message);
            }
        }).catch((err) => {
            console.error(err);
        })
    };

    const logoutHandler = () => {
        swal("Are You sure you want to Sign out?", {
            icon: "warning",
            className: topnavclasses["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Logout",
                    value: "Logout",
                    className: topnavclasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    Navigate("/");
                    localStorage.clear();
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    }, []);

    const tick = () => {
        setCurrentTime(new Date());
    };

    const formatTime = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
    };


    const NotificationHandler = () => {
        Navigate("/Notifications");
    };
    return (
        <div className={topnavclasses["TopNav"]}>
            <div className={topnavclasses.headercontainer}>
                <div className={topnavclasses.userinter}>
                    <p className={topnavclasses.hitext}>Hi</p>
                    <p className={topnavclasses.nametext}>{user.name}</p>
                    <p className={topnavclasses.smileicon}><FaSmile size={20} /></p>
                </div>
                <div className={topnavclasses.container}>
                    <div className={topnavclasses.usermobilecontainer}>
                        <p className={topnavclasses.mobiletext}>UID :</p>
                        <p className={topnavclasses.mnumer}>{user.UID}</p>
                    </div>
                    <p id={topnavclasses.line}>|</p>
                    <div className={topnavclasses.usermobilecontainer}>
                        <p className={topnavclasses.mobiletext}>Mobile :</p>
                        <p className={topnavclasses.mnumer}>{user.mobileNumber}</p>
                    </div>
                    <p id={topnavclasses.line}>|</p>
                    <div className={topnavclasses.timecontainer}>
                        <p className={topnavclasses.mobiletext}> Time :</p>
                        <p className={topnavclasses.time}>{formatTime(currentTime)} </p>
                    </div>
                </div>
            </div>
            <div className={topnavclasses.maincontainer}>
                <div className={topnavclasses.logo_container}>
                    <img src={Logo} alt="tannya" className={topnavclasses.logo_img} />
                </div>
                <div className={topnavclasses.usercontainer}>
                    <h3 className={topnavclasses.userBname}>{(accountType != 6 && accountType != 4) ? user.businessName : localStorage.getItem("officeBName")}</h3>
                    <h5 className={topnavclasses.userName}>{user.name}</h5>
                </div>
                <div className={topnavclasses.buttoncontainer}>
                    <div className={topnavclasses["notification-btn"]}>
                        <button onClick={NotificationHandler}>
                            <IoMdNotificationsOutline size={30} />
                        </button>
                        <p className={topnavclasses["count"]}>
                            {notificationCount}
                        </p>
                    </div>
                    <div className={topnavclasses.user_active_btn}>{
                        (accountType == 1 || accountType == 2) && (<div className={topnavclasses.user_active_btn}>
                            <label className={topnavclasses["switch"]}>
                                <input type="checkbox"
                                    id={topnavclasses["togBtn"]}
                                    checked={userActive === 1}
                                    onClick={(e) => { console.log(e.target.checked); UserActive(e.target.checked ? 1 : 0) }} />
                                <div className={`${topnavclasses["slider"]} ${topnavclasses["round"]}`}></div>
                            </label>
                        </div>)
                    }
                    </div>
                    <div className={topnavclasses.logoutbtn}>
                        <button className={topnavclasses["logout"]} onClick={logoutHandler}>
                            <HiOutlineLogout
                                size={32}
                                title="LagOut"
                                className={topnavclasses["logout_icon"]}
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div className={topnavclasses.bottomcontainer}>
                <div className={topnavclasses.navlinks}>
                    {NavItems.map((item) => (
                        <div key={item.id} className={topnavclasses.items}>
                            <NavLink
                                to={item.menuLink}
                                data-tooltip-id={item.id}
                                data-tooltip-content={item.name}
                                data-tooltip-position-strategy={"fixed"}
                                className={
                                    Active === item.active ? topnavclasses.navActive : topnavclasses.navInactive
                                }
                            >
                                {/* <div className={topnavclasses["icon_wrapper"]}>
                                    <item.icon size={22} className={topnavclasses["nav-icon"]} />
                                    {item.name == "Notifications" && (
                                        <span className={topnavclasses["icon_count"]}>
                                            {notificationCount}
                                        </span>
                                    )}
                                </div> */}
                                <span className={topnavclasses["icon_text"]}>{item.name}</span>
                            </NavLink>
                        </div>
                    ))}

                </div>
            </div>
        </div >
    );
}

export default TopNav;
