import React, { useEffect, useState } from "react";
import { ToastError, ToastSuccess } from "../../components/utils/ToastMessages";
import { Services } from "../../Services";
import Layout from "../../components/Layout/layout";
import { GoArrowLeft } from "react-icons/go";
import HomeClasses from "../../pages/Home/home.module.css";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { BsFillHandbagFill } from "react-icons/bs";
import { MdEditDocument } from "react-icons/md";
import { FaWeightHanging, FaBoxes } from "react-icons/fa";
import { BiSolidBox } from "react-icons/bi";
import { LuUpload } from "react-icons/lu";
import { BsBox2Fill } from "react-icons/bs";
import { SiPkgsrc } from "react-icons/si";
import config from "./config";
import S3FileUpload from "react-s3";
import { FileUploader } from "react-drag-drop-files";
import { FaBullseye } from "react-icons/fa6";

window.Buffer = window.Buffer || require("buffer").Buffer;

const AddProduct = () => {
  const [imageUrl, setImageUrl] = useState([]);
  const [images, setImages] = useState([]);
  const Navigate = useNavigate();
  const [productCategories, setProductCategories] = useState([]);
  const [priceUnits, setPriceUnits] = useState([]);
  const types = ["PNG", "JPG", "JPEG"];
  const [selectedPriceUnit, setSelectedPriceUnit] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
    reset,
  } = useForm();
  // { defaultValues: { packageMeasuringUnit: 'KG', productMeasuringUnit: 'Bag' } }
  useEffect(() => {
    getPriceUnits();
    getProductCategories();
  }, []);

  const getPriceUnits = async () => {
    try {
      const res = await Services.priceUnits("GET", null, token);
      if (res.Status === 1) {
        setPriceUnits(res.productPriceUnits);
      }
    } catch (err) {
      // alert("Something went wrong please try again later");
      console.error(err);
    }
  }

  const getProductCategories = async () => {
    try {
      const res = await Services.productCategories("GET", null, token);
      if (res.Status === 1) {
        setProductCategories(res.productCategories);

      }
    } catch (err) {
      console.error(err);
    }
  }

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const formData = watch();
  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionName === "addProducts"
  );

  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;

  const onSubmit = async (data) => {

    let body = { ...data, productImageList: imageUrl, productMeasuringUnit: String(data.productPriceUnit).toLowerCase() == 'quintal' ? 'Bag' : data.productPriceUnit };

    if (body.productPriceUnit != "Quintal") {
      delete body.productBagWeightInKgs;
    }
    if (imageUrl.length === 0) {
      ToastError("Image is required");
      return;
    }

    if (data.stockQty <= 0) {
      setError("stockQty", {
        type: "custom",
        message: "Stock quantity cannot be zero or negative",
      });
      return;
    }
    if (data.productPriceUnit === "Quintal" && data.productBagWeightInKgs <= 0) {
      setError("productBagWeightInKgs", {
        type: "custom",
        message: "productBagWeightInKgs cannot be zero or negative",
      });
      return;
    }
    try {
      const res = await Services.AddProduct(
        "POST",
        JSON.stringify(body),
        token
      );
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        // console.log(res.data);
        reset(data);
        Navigate(-1);
      }
    } catch (err) {
      // alert("something went wrong please try again");
      console.log(err);
    }
  };

  const fileUploadHandler = (files) => {
    // if (!isImageUploaded) {
    let uploadedImages = [];
    // return;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const type = file.type;

      if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
        const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
        console.log(newFileName);
        // return;
        const newFile = new File([file], newFileName);
        console.log(newFile);
        S3FileUpload.uploadFile(newFile, config)
          .then((data) => {
            console.log(data.location);
            uploadedImages.push(data.location);
            setImages([...images, data.location]);
            setImageUrl([...imageUrl, newFileName]);

            // setValue("productImage", newFileName);
            // setIsImageUploaded(true);
          })
          .catch((err) => console.error(err));
      }
    };
  }

  const fileUploader = (
    <div className={HomeClasses["add_image_uploader"]}>
      <LuUpload className={HomeClasses["upload_icon"]} />
      <h3 id={HomeClasses["add_uploader_h3"]}>Add Image Here</h3>
    </div>
  );

  return (
    <Layout Active={"Manageproducts"}>
      <div className={HomeClasses["home_wrapper"]}>
        <div className={HomeClasses["Add_main_container"]}>
          <div className={HomeClasses["Add_header"]}>
            <button
              className={HomeClasses["add_back_btn"]}
              type="button"
              onClick={() => {
                Navigate(-1);
              }}
            >
              <GoArrowLeft />
            </button>
            <h1 id={HomeClasses["add_header_h2"]}>Add Product</h1>
          </div>

          <div className={HomeClasses["Addproduct_container"]}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={HomeClasses["add_register"]}
            >
              <div>
                <Controller
                  name="productCategory"
                  control={control}
                  rules={{
                    required: "ProductCategory is required",
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <select defaultValue="" {...field}>
                        <option value="" disabled>
                          Product Category
                        </option>
                        {
                          productCategories.map((item, index) => (
                            <option
                              key={item.productCategoryId}
                              value={item.categoryName}
                            >
                              {item.categoryName}
                            </option>
                          ))
                        }

                      </select>
                      <BsBox2Fill />
                    </div>
                  )}
                />
                {errors.productCategory && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productCategory.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="productName"
                  control={control}
                  rules={{
                    required: "Product Name is required",
                    // pattern: {
                    //   value: /^[a-zA-Z0-9\s]+$/,
                    //   message: "Invalid Product Name",
                    // },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input {...field} placeholder="Product Name*" />
                      <BsFillHandbagFill />
                    </div>
                  )}
                />
                {errors.productName && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productName.message}
                  </p>
                )}
              </div>

              <div>
                <Controller
                  name="productPriceUnit"
                  control={control}
                  rules={{
                    required: "Product PriceUnit is required",
                    onChange: (e) => {
                      // console.log(String(e.target.value).toLowerCase() == 'quintal' ? 'Bag' : e.target.value)
                      setValue("productMeasuringUnit", String(e.target.value).toLowerCase() == 'quintal' ? 'Bag' : e.target.value);
                      setSelectedPriceUnit(e.target.value);
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <select defaultValue="" {...field}>
                        <option value="" disabled>
                          Product PriceUnit
                        </option>
                        {
                          priceUnits.map((unit, index) => (
                            <option
                              key={unit.productPriceId}
                              value={unit.productPriceUnit}
                            >
                              {unit.productPriceUnit}
                            </option>
                          ))
                        }
                      </select>
                      <BsBox2Fill />
                    </div>
                  )}
                />
                {errors.productPriceUnit && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productPriceUnit.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="stockQty"
                  control={control}
                  rules={{
                    required: "Stock Qty is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Stock Qty should only contain digits",
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input
                        type="text"
                        {...field}
                        placeholder="Stock Quantity*"
                      />
                      <FaBoxes />
                      <div className={HomeClasses["a_mesuring_unit"]}>
                        <Controller
                          name="productMeasuringUnit"
                          control={control}
                          disabled


                          //   defaultValue={formData.productPriceUnit}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <div className={HomeClasses["add_icon"]}>
                              <select defaultValue="" {...field} >
                                <option value="" disabled></option>
                                {
                                  priceUnits.map((unit, index) => (
                                    <option
                                      key={unit.productPriceId}
                                      value={unit.productPriceUnit}
                                    >
                                      {unit.productPriceUnit}
                                    </option>
                                  ))
                                }
                              </select>
                              {/* <BsBox2Fill /> */}
                            </div>
                          )}
                        />
                        {errors.productMeasuringUnit && (
                          <p className={HomeClasses["errors"]}>
                            {errors.productMeasuringUnit.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                {errors.stockQty && (
                  <p className={HomeClasses["errors"]}>
                    {errors.stockQty.message}
                  </p>
                )}
              </div>
              {selectedPriceUnit === "Quintal" && (

                <div>
                  <Controller
                    name="productBagWeightInKgs"
                    control={control}
                    rules={{
                      required: "Product Bag WeightInKgs required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Product BagWeight In Kgs should be greater than 0",
                      },
                    }}
                    render={({ field }) => (
                      <div className={HomeClasses["add_icon"]}>
                        <input
                          type="text"
                          {...field}
                          placeholder="Product BagWeight In Kgs"
                          min={1} />
                        <FaBoxes />
                      </div>
                    )}
                  />
                  {errors.productBagWeightInKgs && (
                    <p className={HomeClasses["errors"]}>
                      {errors.productBagWeightInKgs.message}
                    </p>
                  )}
                </div>

              )}

              {/* <div>
                <Controller
                  name="gst"
                  control={control}
                  rules={{
                    // required: "Gst is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: " GST should only contain digits",
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input {...field} placeholder="GST In Percentage" />
                      <MdEditDocument />
                    </div>
                  )}
                />
                {errors.gst && (
                  <p className={HomeClasses["errors"]}>{errors.gst.message}</p>
                )}
              </div> */}
              {/* <div>
                <Controller
                  name="discount"
                  control={control}
                  rules={{
                    // required: "Discount is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: " Discount should only contain digits",
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input
                        {...field}
                        placeholder="Discount  In  Percentage"
                      />
                      <MdEditDocument />
                    </div>
                  )}
                />
                {errors.discount && (
                  <p className={HomeClasses["errors"]}>
                    {errors.discount.message}
                  </p>
                )}
              </div> */}

              <div>
                <Controller
                  name="HSNCode"
                  control={control}
                  rules={{
                    required: "HSNCode is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Invalid HSNCode ",
                    },
                  }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <input
                        {...field}
                        placeholder="HSNCode "
                      // maxLength={8}
                      // minLength={8} 
                      />
                      <MdEditDocument />
                    </div>
                  )}
                />
                {errors.HSNCode && (
                  <p className={HomeClasses["errors"]}>
                    {errors.HSNCode.message}
                  </p>
                )}
              </div>

              <div>
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: "Status is required" }}
                  render={({ field }) => (
                    <div className={HomeClasses["add_icon"]}>
                      <select defaultValue="" {...field} placeholder="Status">
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value={1}>InStock</option>
                        <option value={2}>OutOfStock</option>
                      </select>
                      <BiSolidBox />
                    </div>
                  )}
                />
                {errors.status && (
                  <p className={HomeClasses["errors"]}>
                    {errors.status.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="productImageList"
                  control={control}
                  // rules={{ required: "Image is required" }}
                  render={({ field }) => (
                    <div className={HomeClasses["image_preview"]}>

                      <FileUploader
                        handleChange={fileUploadHandler}
                        children={fileUploader}
                        multiple={true}
                        types={types}
                        className={HomeClasses["add_image_uploader"]}
                      />
                      <div className={HomeClasses.image_array}>
                        {images.map((item, index) => (

                          <img
                            key={index}
                            src={item}
                            alt="Product Preview"
                            className={HomeClasses["image_uploader"]}
                          />

                        ))}
                      </div>
                    </div>
                  )}
                />
                {errors.productImageList && (
                  <p className={HomeClasses["errors"]}>
                    {errors.productImageList.message}
                  </p>
                )}
              </div>
              {accountType == 6 && PermissionStatus == 0 ? (
                " "
              ) : (
                <button type="submit" className={HomeClasses["add_submit"]}>
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddProduct;
