import React from "react";
import LayoutClasses from "./layout.module.css";
import useStore from "../../store";
import TopNav from "../Topnav/TopNav";


function Layout({ children, Active }) {
  const showNav = useStore((state) => state.showNavbar);
  const setShowNav = useStore((State) => State.setshowNavbar);
  return (
    <div className={LayoutClasses["Wrapper"]}>
      <div className={LayoutClasses.mainheader}>
        <TopNav Active={Active} setShow={setShowNav} show={showNav} />
      </div>
      <div>{children}</div>
    </div>
  );
}

export default Layout;
