import React from "react";
import CountUp from "react-countup";
import { FaPlus } from "react-icons/fa6";
import Shop from "../../assets/landingpage/shopping-cart.png";
import Team from "../../assets/landingpage/factory.png";
import Products from "../../assets/landingpage/logistics.png";
import Download from "../../assets/landingpage/download.png";



const Content_8 = () => {
    return (
        <div id="count-up-section">
            <div className="counterbox-container-last">
                <div className="Counterbox-img">
                    <img src={Download} alt="Cilent"></img>
                </div>
                <div className="counterbox-number">
                    <div className="number">
                        <CountUp start={0} end={2000} delay={0} /><sup><FaPlus className="Counter-icon"/></sup>
                        <p className="headline">Downloads</p>
                    </div>
                   
                </div>
            </div>
            <div className="counterbox-container-last">
            <div className="Counterbox-img">
                     <img src={Team} alt="Cilent"></img>
                </div>
                <div className="counterbox-number">
                <div className="number">
                    <CountUp end={200} /><sup><FaPlus className="Counter-icon"/></sup>
                    <p className="headline">Manufacturers</p>
                </div>
              
                </div>
            </div>

            <div className="counterbox-container-last">
                <div className="Counterbox-img">
                <img src={Products} alt="Cilent"></img>
                </div>
                <div  className="counterbox-number">
                <div className="number">
                    <CountUp end={500} /><sup><FaPlus className="Counter-icon"/></sup>
                    <p className="headline"> Distributors </p>
                </div>
               
                </div>
            </div>

            <div className="counterbox-container-last">
            <div className="Counterbox-img">
                <img src={Shop} alt="Cilent"></img>
            </div>
                <div  className="counterbox-number">
                <div className="number">
                    <CountUp end={1000} /><sup><FaPlus className="Counter-icon"/></sup>
                    <p className="headline">Retailers</p>
                </div>
                </div>
                </div>
            </div>
    );
};

export default Content_8;
