import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import HomeClasses from "../../pages/Home/home.module.css";
import { Services } from "../../Services";
import { ToastSuccess } from "../../components/utils/ToastMessages";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { override } from "./helper";
import emptyorders from "../../assets/emptyorders.png";
import { RotatingLines } from "react-loader-spinner";
import Permissionimg from "../../assets/NoPermissions.png";
import Moment from "react-moment";

const OrderStatuses = () => {
  const [orderstatusses, setOrderstatuses] = useState([]);
  const [status, setStatus] = useState(
    parseInt(localStorage.getItem("activeStatus")) || 1
  );
  const [loading, setLoading] = useState(true);
  const [perPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pendingpayments, setPendingpayments] = useState([]);
  const Navigate = useNavigate();

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const handleStatusButtonClick = (status) => {
    if (status === 8) {
      setStatus(status);
      localStorage.setItem("activeStatus", status);
    } else {
      setStatus(status);
      getOrderStatus(status);
      localStorage.setItem("activeStatus", status);
    }
  };

  // for Permissions

  const accountType = localStorage.getItem("AccountType");
  const addPermissionsdata = JSON.parse(localStorage.getItem("permissions"));
  const addPermissions = addPermissionsdata ? addPermissionsdata : [];
  const addProduct = addPermissions.filter(
    (item) => item.permissionId === 5
  );
  const PermissionStatus = addProduct.length > 0 ? addProduct[0].status : 1;

  const isActiveButton = (buttonStatus) => {
    return status === buttonStatus ? HomeClasses.active : "";
  };
  useEffect(() => {
    if (accountType == 6) {
      getPermissions();
    }
  }, []);

  const getPermissions = async () => {
    try {
      const res = await Services.OfficePermissions("GET", null, token);
      if (res.Status === 1) {
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
      }
    } catch (err) {

      console.error(err);
    }
  };

  useEffect(() => {
    if (status === 8) {
      getPendingPayments(status, page);
    } else {
      getOrderStatus(status, page);
    }
  }, [status, page]);

  const getOrderStatus = (status, page) => {
    Services.orderStatus("GET", null, token, status, page)
      .then((res) => {
        setLoading(false);
        if (res.Status === 1) {
          setOrderstatuses(res.orderDetails);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPendingPayments = (status, page) => {
    Services.pendingPayments("GET", null, token, status, page)
      .then((res) => {
        setLoading(false);
        if (res.Status === 1) {
          setPendingpayments(res.payments);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const orderdetails = (orderId) => {
    Navigate(`/orderdetails/${orderId}`);
  };

  // Send Reaminder api

  const Sendreminder = async (payment) => {
    let data = {
      totalPrice: payment.totalPrice,
      orderId: payment.orderId,
      pendingPayment: payment.pendingPayment,
      userId: payment.userId,
      suplierId: payment.suplierId,
      orderFor: payment.orderFor,
    };
    try {
      const response = await Services.SendReminder(
        "POST",
        JSON.stringify(data),
        token
      );
      if (response.Status === 1) {
        ToastSuccess(response.Message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Layout Active={"Sales Orders"}>
      <div className={HomeClasses["home_wrapper"]}>
        {loading ? (
          <div className={HomeClasses["loader"]}>
            <RotatingLines
              color={"#4285f4"}
              loading={true}
              css={override}
              size={100}
              width={100}
              height={100}
              strokeWidth={4}
              animationDuration={0.75}
            />
          </div>
        ) : (
          <div className={HomeClasses["Order_container"]}>
            {accountType == 6 && PermissionStatus == 0 ? (
              <div className={HomeClasses["permission_container"]}>
                <img src={Permissionimg} alt="image" />
                <h2>
                  {" "}
                  we,re sorry, But you dont have required permissions to access
                  this page.Please contact the site administrator.
                </h2>
              </div>
            ) : (
              <>
                <select
                  className={HomeClasses["order_status_select"]}
                  name="suplierOrdersStatus"
                  id={1}
                  onChange={(e) =>
                    handleStatusButtonClick(parseInt(e.target.value))
                  }
                >
                  <option value={1}> All new Orders</option>
                  <option value={2}>Approved</option>
                  <option value={3}>Invoice Printed</option>
                  <option value={4}>Transport</option>
                  <option value={5}>Delivered</option>
                  <option value={6}>Rejected</option>
                  {/* <option value={7}>Completed</option> */}
                  <option value={8}>Pending Payments</option>
                </select>
                <div className={HomeClasses["Order_statusbtn"]}>
                  <button
                    className={`${HomeClasses["order_statusbuttons"]
                      } ${isActiveButton(1)}`}
                    onClick={() => handleStatusButtonClick(1)}
                    type="button"
                  >
                    All new Orders
                  </button>
                  <button
                    className={`${HomeClasses["order_statusbuttons"]
                      } ${isActiveButton(2)}`}
                    onClick={() => handleStatusButtonClick(2)}
                    type="button"
                  >
                    Approved
                  </button>
                  <button
                    className={`${HomeClasses["order_statusbuttons"]
                      } ${isActiveButton(3)}`}
                    onClick={() => handleStatusButtonClick(3)}
                    type="button"
                  >
                    Invoice Printed
                  </button>
                  <button
                    className={`${HomeClasses["order_statusbuttons"]
                      } ${isActiveButton(4)}`}
                    onClick={() => handleStatusButtonClick(4)}
                    type="button"
                  >
                    Transport
                  </button>
                  <button
                    className={`${HomeClasses["order_statusbuttons"]
                      } ${isActiveButton(5)}`}
                    onClick={() => handleStatusButtonClick(5)}
                    type="button"
                  >
                    Delivered
                  </button>
                  <button
                    className={`${HomeClasses["order_statusbuttons"]
                      } ${isActiveButton(6)}`}
                    onClick={() => handleStatusButtonClick(6)}
                    type="button"
                  >
                    Rejected
                  </button>
                  {/* <button
                    className={`${
                      HomeClasses["order_statusbuttons"]
                    } ${isActiveButton(7)}`}
                    onClick={() => handleStatusButtonClick(7)}
                    type="button"
                  >
                    Completed
                  </button> */}
                  <button
                    className={`${HomeClasses["order_statusbuttons"]
                      } ${isActiveButton(8)}`}
                    onClick={() => handleStatusButtonClick(8)}
                    type="button"
                  >
                    Pending Payments
                  </button>
                </div>
                <div className={HomeClasses["Order_table_container"]}>
                  <table className={HomeClasses["Order_table"]}>
                    <thead className={HomeClasses["sticky-header"]}>
                      {status === 8 ? (
                        <tr>
                          <th>Agency Name</th>
                          <th>Order ID</th>
                          <th>Ordered Date</th>
                          <th>Order Value</th>
                          <th>Payment Received</th>
                          <th>Pending Payment</th>
                          <th>Action</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>Order ID</th>
                          <th>Ordered By</th>
                          <th>Buyers Business Name</th>
                          <th>Delivery Address</th>
                          <th>Price</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {status === 8 ? (
                        pendingpayments.length === 0 ? (
                          <tr>
                            <td colSpan={6}>
                              <div className={HomeClasses["empty_cell"]}>
                                <div
                                  className={
                                    HomeClasses["empty_image_container"]
                                  }
                                >
                                  <img
                                    className={HomeClasses["empty_image"]}
                                    src={emptyorders}
                                    alt="image"
                                  />
                                </div>
                                <p className={HomeClasses["empty_portal_1"]}>
                                  No orders to show, Make your first Order
                                </p>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          pendingpayments
                            .slice(perPage * page, perPage * page + perPage)
                            .map((payment, index) => (
                              <tr key={index}>
                                <td>{payment.userName}</td>
                                <td>
                                  <button
                                    type="button"
                                    className={HomeClasses["orderbyId_btn"]}
                                    onClick={() =>
                                      orderdetails(payment.orderId)
                                    }
                                  >
                                    {payment.orderId}
                                  </button>
                                </td>
                                <td>
                                  <Moment
                                    format="DD MMM, YYYY">
                                    {new Date(payment.createdAt)}
                                  </Moment>
                                </td>
                                <td>₹{payment.finalAmount}</td>
                                <td>₹{payment.receivedPayement}</td>
                                <td>₹{payment.pendingPayment}</td>
                                <td>
                                  <button
                                    type="button"
                                    className={HomeClasses["send_remainder"]}
                                    onClick={() => Sendreminder(payment)}
                                  >
                                    Send Reminder
                                  </button>
                                </td>
                              </tr>
                            ))
                        )
                      ) : orderstatusses.length === 0 ? (
                        <tr>
                          <td colSpan={5}>
                            <div className={HomeClasses["empty_cell"]}>
                              <div
                                className={HomeClasses["empty_image_container"]}
                              >
                                <img
                                  className={HomeClasses["empty_image"]}
                                  src={emptyorders}
                                  alt="image"
                                />
                              </div>
                              <p className={HomeClasses["empty_portal_1"]}>
                                No orders to show, Make your first Order
                              </p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        orderstatusses
                          .slice(perPage * page, perPage * page + perPage)
                          .map((order, index) => (
                            <tr
                              key={index}
                              onClick={() => orderdetails(order.orderId)}
                            >
                              <td>{order.orderId}</td>
                              <td>
                                {order.orderedBy.name} (
                                {order.orderedBy.accountType === 1
                                  ? "Manufacturer "
                                  : order.orderedBy.accountType === 2
                                    ? "Distributor "
                                    : order.orderedBy.accountType === 3
                                      ? "Retailer"
                                      : order.orderedBy.accountType === 4
                                        ? "Marketing Specialist "
                                        : order.orderedBy.accountType === 5
                                          ? "Agents"
                                          : "Office Team"}
                                )
                              </td>
                              <td>{order.userName}</td>
                              <td>{order.deliveredTo.name} (
                                {order.deliveredTo.accountType === 1
                                  ? "Manufacturer "
                                  : order.deliveredTo.accountType === 2
                                    ? "Distributor "
                                    : order.deliveredTo.accountType === 3
                                      ? "Retailer"
                                      : order.deliveredTo.accountType === 4
                                        ? "Marketing Specialist "
                                        : order.deliveredTo.accountType === 5
                                          ? "Agents"
                                          : "Office Team"}
                                )</td>
                              <td>₹{order.finalAmount}</td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={HomeClasses["order_pagination"]}>
                  <ReactPaginate
                    containerClassName={HomeClasses["pagination"]}
                    activeClassName={HomeClasses["active-page"]}
                    pageClassName={HomeClasses["page-item"]}
                    onPageChange={(event) => setPage(event.selected)}
                    breakLabel="..."
                    pageCount={
                      Math.ceil(orderstatusses.length / perPage) ||
                      Math.ceil(pendingpayments.length / perPage)
                    }
                    previousLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "1.8rem" }}
                      >
                        <AiFillLeftCircle />
                      </IconContext.Provider>
                    }
                    nextLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "1.8rem" }}
                      >
                        <AiFillRightCircle />
                      </IconContext.Provider>
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OrderStatuses;
