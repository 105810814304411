import React from "react";
import ContentImage from "../../assets/landingpage/slideworld.jpeg";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import googlePlay from "../../assets/landingpage/google-play.png";


const Content_1 = () => {

  const appele = () => {
    window.open("https://apps.apple.com/in/app/traders-ground/id6479200330", "_blank");
  };
  const playstore = () => {
    window.open("https://play.google.com/store/apps/details?id=com.tanyya.tradersground", "_blank");
  };

  return (
    <div>
      <div className="content-1-container">
        <div className="Content-text">
          <h5 id="text-1">Innovative & Smart</h5>
          <h2 id="text-2">CRM Platform to</h2>
          <h1 id="text-3">Connect Buyers with Suppliers</h1>
          <div className="checks">
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Multi Vendor</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Individual Pricing</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Groups Creation</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Group Pricing</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Orders Management</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Payments Management</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Inventory managent</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Sales Report</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Download Reports</li>
            <li className="check_list"><GiCheckMark className="Home_check_icon" />Free Support</li>
          </div>
          <div className="content_btns">

            <button className="google-play-button" type="button" onClick={() => playstore()}>
              <img src={googlePlay} alt="googlePlay" width={34} height={34} className="google-play-icon" style={{ paddingLeft: "8px" }}></img>
              <span className="google-play-text">
                <span className="google-play-text-top">GET IT ON</span>
                <br />
                <span className="google-play-text-down">Google Play</span>
              </span>
            </button>

            <button className="google-play-button" type="button" onClick={() => appele()}>
              <FaApple className="google-play-icon2" />
              <span className="google-play-text">
                <span className="google-play-text-top">Download on the</span>
                <br />
                <span className="google-play-text-down">App Store</span>
              </span>
            </button>
          </div>
        </div>
        <div className="Content-img">
          <img className="content_img" src={ContentImage} alt="Bussines_image" />
        </div>
      </div>
    </div>

  );
};

export default Content_1;
