import React from "react";
import Logo from "../../../assets/login/Logotanyya.png";
import about from "./about.module.css";
const AboutUs = () => {
  return (
    <div className={about["about_container"]}>
      <div className={about["about_image"]}>
        <img src={Logo} alt="Traders Ground" className={about["about_img"]} />
      </div>
      <div className={about["about_content"]}>
        <p className={about["about_text"]}>
          Traders Ground is a B2B platform owned and operated by Tanyya Tech Solutions LLP. Our platform is
          meticulously designed to streamline the processes for manufacturers and distributors, providing
          them with a seamless experience to connect, collaborate, and conduct business efficiently.
        </p>
        <p className={about["about_text"]}>
          Alignment with Terms and Conditions:<br />
          As the owner and operator of Traders Ground, Tanyya Tech Solutions LLP is committed to upholding
          the Terms and Conditions outlined for user registration, subscription, privacy, security, and
          termination. We ensure that our platform operates in accordance with these terms to provide a fair
          and secure environment for all users.
        </p>
        <p className={about["about_text"]}>
          Alignment with Privacy Policy:<br />
          At Traders Ground, we prioritize the privacy and security of our users&#39; information. We collect user
          data as outlined in our Privacy Policy, utilizing it solely to improve our services and ensure a
          personalized experience for each user. Our commitment to data security is reflected in the measures
          we employ to safeguard user information.
        </p>
        <p className={about["about_contact"]}>
          <li>Mail: Support@Tanyya.com</li>
          <li>Contact Us:{" "}<a href="https://tradersground.com/contact">https://tradersground.com/contact</a> </li>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
